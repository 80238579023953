import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Input from "./Input";

class BenefitCodeInput extends Component {
  render() {
    const {t, ssoAccountId} = this.props;
    const enterCoupon = t('TV_SID_BILLING_ENTER_COUPON_CODE');
    const redeemedBenefitWillBeApplied = t('TV_SID_MIX_BJDEC_PAYMENT_REDEEMED_COUPON_APPLIED_ON', {account: ssoAccountId});

    return (
        <div className={`form-group`}>
          <h3 className={`s-form-title`}>
            {enterCoupon}<br/>
            {redeemedBenefitWillBeApplied}
          </h3>
          <span className={`s-input`}>
              <Input
                  type={`text`}
                  name={this.props.name}
                  title={enterCoupon}
                  maxLength={16}
                  value={this.props.value}
                  onChange={this.props.onChange}/>
            </span>
        </div>
    );
  }
}

export default withTranslation()(BenefitCodeInput);
