export const URL_INDEX_PAGE = "/portal/index.do";

export const URL_AUTH_SIGN_IN_PAGE = "/portal/auth/login.do";
export const URL_AUTH_LOGOUT = "/portal/auth/logout.do";
export const URL_AUTH_REGISTER_SSO = "/portal/auth/registSSO.do";
export const URL_AUTH_CHECK_CODE_API = "/portal/auth/checkCode.api";

export const URL_PAYON_CARD_REG_PAGE = "/portal/payon/registerGcc.do";
export const URL_PAYON_RESULT_SUCCESS_PAGE = "/portal/payon/resultSuccess.do";
export const URL_PAYON_ZIP_CODE_CHECK_API = "/portal/payon/usZipCodeStateCity.api";
export const URL_PAYON_CARD_REGISTER_API = "/portal/payon/registerGcc.api";
export const URL_PAYON_LIST_VAULT_CARD_PAGE = "/portal/payon/vault.do"
export const URL_PAYON_LIST_VAULT_CARD_API = "/portal/payon/vault.api"
export const URL_PAYON_CHANGE_STATE_API = "/portal/payon/state.api";

export const URL_SIGNON_ADD_GCC_CARD_PAGE = "/portal/signon/addGccCard.do";
export const URL_SIGNON_ADD_KCC_CARD_PAGE = "/portal/signon/addKccCard.do";
export const URL_SIGNON_LIST_VAULT_CARD_PAGE = "/portal/signon/vault.do";
export const URL_SIGNON_LIST_VAULT_CARD_API = "/portal/signon/vault.api";
export const URL_SIGNON_RESULT_SUCCESS_PAGE = "/portal/signon/resultSuccess.do";
export const URL_SIGNON_GCC_REGISTER_API = "/portal/signon/registerGcc.api";
export const URL_SIGNON_KCC_REGISTER_API = "/portal/signon/registerKcc.api";
export const URL_SIGNON_KCC_CHECK_API = "/portal/signon/checkKcc.api";
export const URL_SIGNON_ZIP_CODE_CHECK_API = "/portal/signon/usZipCodeStateCity.api";
export const URL_SIGNON_CHANGE_STATE_API = "/portal/signon/state.api";
export const URL_SIGNON_WELCOME_WILL_YOU_ADD_CARD_PAGE = "/portal/signon/welcome.do";

export const URL_PAYMETHODS_PAGE = "/portal/paymethods/index.do";
export const URL_PAYMETHODS_REGISTER_GCC_PAGE = "/portal/paymethods/registerGcc.do";
export const URL_PAYMETHODS_REGISTER_KCC_PAGE = "/portal/paymethods/registerKcc.do";
export const URL_PAYMETHODS_REGISTER_METHOD_PAGE = "/portal/paymethods/registerMethod.do";
export const URL_PAYMETHODS_AUTHCODE_PAGE = "/portal/paymethods/authCheck.do";
export const URL_PAYMETHODS_GET_PAYMETHODS_API = "/portal/paymethods/get.api";
export const URL_PAYMETHODS_GCC_REGISTER_API = "/portal/paymethods/registerGcc.api";
export const URL_PAYMETHODS_KCC_REGISTER_API = "/portal/paymethods/registerKcc.api";
export const URL_PAYMETHODS_KCC_CHECK_API = "/portal/paymethods/checkKcc.api";
export const URL_PAYMETHODS_DEL_PAYMETHODS_API = "/portal/paymethods/del.api";
export const URL_PAYMETHODS_SUCCESS_PAGE = "/portal/paymethods/resultSuccess.do";
export const URL_PAYMETHODS_AUTH_CODE_CHECK_API = "/portal/paymethods/checkCode.api";
export const URL_PAYMETHODS_CHANGE_ZIP_CODE_PAGE = "/portal/paymethods/zipcode.do";
export const URL_PAYMETHODS_CHANGE_ZIP_CODE_API = "/portal/paymethods/zipcode.api";
export const URL_PAYMETHODS_SUBSCRIPTION_UPDATE_PAGE = "/portal/paymethods/subscriptions/:id/index.do";
export const URL_PAYMETHODS_SUBSCRIPTION_UPDATE_API = "/portal/paymethods/subscriptions/:id/update.api";
export const URL_PAYMETHODS_ZIP_CODE_CHECK_API = "/portal/paymethods/usZipCodeStateCity.api";
export const URL_PAYMETHODS_CHANGE_STATE_PAGE = "/portal/paymethods/state.do";
export const URL_PAYMETHODS_CHANGE_STATE_API = "/portal/paymethods/state.api";
export const URL_PAYMETHODS_LIST_VAULT_CARD_PAGE = "/portal/paymethods/vault.do"
export const URL_PAYMETHODS_LIST_VAULT_CARD_API = "/portal/paymethods/vault.api"
export const URL_PAYMETHODS_DEFAULT_CARD_API = "/portal/paymethods/default.api"

export const URL_SUBSCRIPTION_GET_API = "/portal/subscriptions/get.api";

export const URL_ORDERS_PAGE = "/portal/orders/index.do";
export const URL_ORDERS_ORDER_DETAILS_PAGE = "/portal/orders/details.do";
export const URL_ORDERS_GET_INVOICES_API = "/portal/orders/invoices.api";
export const URL_ORDERS_CANCEL_API = "/portal/orders/cancel.api";

export const URL_BENEFITS_PAGE = "/portal/benefits/index.do";
export const URL_BENEFITS_GET_BENEFITS_API = "/portal/benefits/getBenefits.api";
export const URL_BENEFITS_GET_COUPON_APPLICABLE_API = "/portal/benefits/getCouponApplicable.api";
export const URL_BENEFITS_REGISTER_BENEFIT = "/portal/benefits/registerBenefit.api";
export const URL_BENEFITS_APPLY_COUPON = "/portal/benefits/applyCoupon.api";

export const URL_CUSTOMERS_PAGE = "/portal/customers/index.do";
export const URL_CUSTOMERS_GET_BUYER_EMAIL_API = "/portal/customers/getBuyerEmail.api";
export const URL_CUSTOMERS_CHANGE_BUYER_EMAIL_API = "/portal/customers/changeBuyerEmail.api";

export const URL_SUPPORT_HELP_EMAIL_PAGE = "/portal/support/helpEmail.do";
export const URL_SUPPORT_SUBSCRIPTION_CANCEL_PAGE = "/portal/support/cancellation.do";
export const URL_SUPPORT_SEND_EMAIL_API = "/portal/support/sendEmail.api";

export const URL_CASHON_PAY_PAGE = "/portal/cashon/pay.do";
export const URL_CASHON_PAY_API = "/portal/cashon/pay.api";
export const URL_CASHON_SUCCESS_PAGE = "/portal/cashon/success.do";

export const URL_PAYPALON_RESULT_SUCCESS_PAGE = "/portal/paypalon/resultSuccess.do";