import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Input from "./Input";
import {autobind} from 'core-decorators';

@autobind
class EmailAddressInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarning: false
    };
  }

  setWarningOff(e) {
    e.preventDefault();
    this.toggleWarningStatus(false);
  }

  toggleWarningStatus(value) {
    this.setState({isWarning: value});
  }

  setIsValidToWarningStatus(e) {
    e.preventDefault();
    this.toggleWarningStatus(!this.props.isValid);
  }

  render() {
    return (
        <div className={`form-group`}>
          <h3 className={`s-form-title`}>
            {this.props.t('COM_EMAIL_ADDRESS')}
          </h3>
          <span className={`s-input s-dimcase-input`}>
            <Input
                warning={this.state.isWarning}
                type={`email`}
                name={this.props.name}
                title={`e-mail address`}
                maxLength={200}
                value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.setIsValidToWarningStatus}
                onFocus={this.setWarningOff}/>
            {this.props.disabled &&
            <span className={`s-dimcase`}>{this.props.value}</span>
            }
          </span>
          <span className={`s-form-notice`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_87')}</span>
        </div>
    );
  }
}

export default withTranslation()(EmailAddressInput);