import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Fade} from 'react-bootstrap';
import {deactivateDim} from '../../../ducks/dimAction';
import {autobind} from 'core-decorators';

/**
 * Attach DimLayer.
 *
 * Note that this component should exist at most one for any moment.
 */
@autobind
class DimLayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDimTrigger: false
    };
  }

  hideDim() {
    if (!this.props.isTouchDisable) {
      this.props.deactivateDim();
    }
  }

  triggerHideDim() {
    this.setState({showDimTrigger: false});
  }

  triggerShowDim() {
    this.setState({showDimTrigger: true});
  }

  render() {
    let dimStyle = this.state.showDimTrigger === true
        ? {display: "inline"}
        : {display: "none"};

    return (
        <Fade in={this.props.isDim}
              onEnter={this.triggerShowDim}
              onExited={this.triggerHideDim}>
          <div>
            <span className={`s-dim`}
                  style={dimStyle}
                  onClick={this.hideDim}
                  onScroll={this.hideDim}
                  onTouchMove={this.hideDim}/>
          </div>
        </Fade>
    );
  }
}

function mapStoreStateToProps(store) {
  return {
    isDim: store.dim.isDim,
    isTouchDisable: store.dim.isTouchDisable
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deactivateDim: () => {
      dispatch(deactivateDim());
    }
  };
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(DimLayer);
