import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DimDropdown from './core/DimDropdown';
import {URL_AUTH_LOGOUT} from '../../utils/urlConstant';
import Form from "../Form";
import {BUYER_PORTAL_SERVICE_ID} from "../../utils/globalConstants";
import {getProperty} from '../../utils/propertiesByDomain';

/**
 * Show User Id and Logout button
 */
class UserMenu extends Component {
  render() {
    if (!this.props.authenticated) {
      return (<div className={`dropdown s-log`}/>);
    }

    let actionUrl = getProperty("samsungAccountSignOutGateUrl");
    let clientId = BUYER_PORTAL_SERVICE_ID;
    let logoutUrl = getProperty("urlHome") + URL_AUTH_LOGOUT;
    let nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // Does not use in Service

    return (
        <DimDropdown
            id={`dropdownLog`}
            alt={`USER MENU`}
            className={`s-log`}
            toggleClassName={`s-btn s-btn-log dropdown-toggle`}>
          <div className={`dropdown-menu dropdown-menu-right`}>
            <span className={`s-log-account`}>{this.props.ssoAccountId}</span>
            <Form method={`get`} action={actionUrl}>
              <input type={`hidden`} name={`client_id`} value={clientId}/>
              <input type={`hidden`} name={`signOutURL`} value={logoutUrl}/>
              <input type={`submit`} className={`s-btn s-btn-default`} value={this.props.t('COM_IDWS_MOIP_POPUP_EXIT_SIGN_OUT')}/>
              <input type={`hidden`} name={`state`} value={nonce}/>
            </Form>
          </div>
        </DimDropdown>
    );
  }
}

export default withTranslation()(UserMenu);
