import React, {Component} from 'react';
import Header from './Header';

/**
 * PageContainer.
 *
 *  <pre>
 *  - hidden: If true, hide MainHeader & NavHeader only on mobile view. MainHeader & NavHeader still shows on PC view. Value must be same as showSubHeader (Ex. true true or false false).
 *  - showNav :
 *      If true, it acts by hidden option :
 *       . hidden true : Show NaviHeader only on PC view
 *       . hidden false : Show NaviHeader on mobile view and PC view
 *  - showSubHeader : If true, show SubHeader only on mobile view. Value must be same as hidden (Ex. true true or false false).
 *  - ssoAccountId & authenticated : If provided, it will show user status and logout button on right-upper corner. Both should exist at same time.
 *  - url : Bright the current page menu in NaviHeader. Optional value when showNav is true.
 *  - subHeaderText : Text that shows on SubHeader on mobile view. Must be provided when showSubHeader is true.
 *  - isSubHeaderBackConfirm : Will provide confirm popup when user tries to go back on mobile view. Optional value when showSubHeader is true.
 *  </pre>
 */
class PageContainer extends Component {
  render() {
    return (
        <div className={this.props.className || `s-wrap`}>
          <Header
              hidden={this.props.hidden}
              showNav={this.props.showNav}
              showSubHeader={this.props.showSubHeader}
              ssoAccountId={this.props.ssoAccountId}
              authenticated={this.props.authenticated}
              url={this.props.url}
              subHeaderText={this.props.subHeaderText}
              isSubHeaderBackConfirm={this.props.isSubHeaderBackConfirm}
              dimAllNavMenusButBenefit={this.props.dimAllNavMenusButBenefit}/>
          {this.props.children}
        </div>
    );
  }
}

export default PageContainer;