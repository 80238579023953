import AdyenCheckout from '@adyen/adyen-web'
import httpClient, {defaultErrorHandler} from './httpClient';
import {URL_ADYEN_CARD_3DS, URL_ADYEN_CONFIG, URL_ADYEN_PAY_3DS} from './urlConstant';
import {ERR_3DS_FAILED, ERR_CODE_UNABLE_TO_COMPLETE_TRANSACTION} from './errorConstant';

export const getBrowserInfo = () => {
  return {
    language: navigator.language,
    userAgentHeader: navigator.userAgent,
    colorDepth: window.screen.colorDepth.toString(),
    javaEnabled: "false",
    screenHeight: window.screen.height.toString(),
    screenWidth: window.screen.width.toString(),
    timeZoneOffset: new Date().getTimezoneOffset().toString()
  };
}

export const scaAdyenRequest = async (props, scaAdyen, cardId, successPageCallback, failCallback, cashonData) => {
  let adyenConfig = await getAdyenConfig(props)

  const adyenComponentConfig = {
    challengeWindowSize: '05', //adyen component fullsize iframe size
  };

  const action = scaAdyen ? JSON.parse(atob(scaAdyen)) : "";

  const configuration = {
    locale: window.navigator.language,
    environment: adyenConfig.env,
    clientKey: adyenConfig.clientKey,

    analytics: { enabled: true },
    onAdditionalDetails:  async (state, component, actions) => {
      const action = await doAdyen3ds(props, state, component, cardId, successPageCallback, failCallback, cashonData)
    },
    onError: (error) => {
      console.error("Error in SCA Adyen request. Please try again later.", error)
      window.location.href = "/portal/auth/login.do"; //redirect to home page when error occurs
    },
  };

  const checkout = await AdyenCheckout(configuration);

  checkout.createFromAction(action, adyenComponentConfig).mount('#scaAdyen');
  return checkout;
}

const getAdyenConfig = async (props) => {
  return  httpClient
      .get(URL_ADYEN_CONFIG)
      .then((response) => {
        return({
          env: response.data.environment,
          clientKey: response.data.clientKey,
        })
      }).catch((error) => {
        defaultErrorHandler(props, error)
      })
}

export const doAdyen3ds = async (props, state, component, cardId, successPageCallback, failCallback, cashonData) => {
  const result = btoa(JSON.stringify(state.data.details));
  const encoded3dsInfo = btoa(JSON.stringify({"paymentDetails": result})); //it's the requirement, encoded value needed to be placed on "paymentDetails" field

  const requestBody = {
    cardId: cardId,
    encoded3dsInfo: encoded3dsInfo,
    guid: cashonData?.guid,
    countryCd: cashonData?.countryCd,
    authCode: cashonData?.authCode,
    paymentId: cashonData?.paymentId
  }

  //if the cashon data is null, then do the 3ds for card registration
  const URL_3DS = cashonData ? URL_ADYEN_PAY_3DS : URL_ADYEN_CARD_3DS;

  return httpClient
      .post(URL_3DS, requestBody)
      .then(response => {

        //Handel failed 3ds
        const status = response.data.status

        //redirect to paymenthod page
        if(status === ERR_CODE_UNABLE_TO_COMPLETE_TRANSACTION || status === ERR_3DS_FAILED ){
          props.showPopup2Line("3DS Authentication failed", "", failCallback)
          return "";
        } else{
          successPageCallback(); //redirect to success page after successful 3ds
          return response.data;
        }

      }).catch((error) => {
        defaultErrorHandler(props, error)
      }).finally(()=>{
        component.unmount();
      });

}
