import React, {Component} from 'react';

class SContainer extends Component {
  render() {
    let divClass = this.props.login === true ? "s-container s-sign-container" : "s-container";

    return (
        <div className={divClass}>
          {this.props.children}
        </div>
    );
  }
}

export default SContainer;
