import React, {Component} from 'react';
import {autobind} from 'core-decorators';
import {isVaultAvailableCountry, valueByCardRegisterScenarioType} from '../../utils/cardUtils';
import {URL_PAYMETHODS_LIST_VAULT_CARD_API, URL_PAYMETHODS_REGISTER_GCC_PAGE, URL_PAYON_CARD_REG_PAGE, URL_PAYON_LIST_VAULT_CARD_API, URL_SIGNON_ADD_GCC_CARD_PAGE, URL_SIGNON_LIST_VAULT_CARD_API} from '../../utils/urlConstant';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import {errorCode2Msg} from '../../translations/i18n-helper';
import PageContainer from '../PageContainer';
import {QS_AUTH_CODE, QS_VAULT_CARD_ID, SAMSUNG_CHECKOUT, TYPE_PAYMETHODS, TYPE_PAYON} from '../../utils/globalConstants';
import SContainer from '../SContainer';
import payment_bg_card from '../../images/payment_bg_card.png';
import withPopupComponents from '../hoc/withPopupComponents';
import {withTranslation} from 'react-i18next';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {getParams} from '../../utils/queryUtil';

@autobind
class ListVaultCardsPage extends Component {

  constructor(props) {
    super(props);

    const params = getParams(window.location.search);

    if (this.props.type === TYPE_PAYON && !params.authCode) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    this.addGccRegPage = valueByCardRegisterScenarioType(this.props.type,
        params.authCode ? URL_PAYMETHODS_REGISTER_GCC_PAGE + `?${QS_AUTH_CODE}=${params.authCode}` : URL_PAYMETHODS_REGISTER_GCC_PAGE + "?",
        URL_PAYON_CARD_REG_PAGE + `?${QS_AUTH_CODE}=${params.authCode}`,
        URL_SIGNON_ADD_GCC_CARD_PAGE + "?");

    this.fetchVaultCardUrl = valueByCardRegisterScenarioType(this.props.type,
        URL_PAYMETHODS_LIST_VAULT_CARD_API,
        URL_PAYON_LIST_VAULT_CARD_API + `?${QS_AUTH_CODE}=${params.authCode}`,
        URL_SIGNON_LIST_VAULT_CARD_API);

    this.state = {vaultCardList: []}
  }

  fetchVaultCardList(successCallback) {
    this.props.showProgressBar();

    httpClient
        .get(this.fetchVaultCardUrl)
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={this.props.type === TYPE_PAYMETHODS}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.type === TYPE_PAYMETHODS ? this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_16') : SAMSUNG_CHECKOUT}>
          <SContainer>
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3`}>
                  <div className="q-title-text">
                    <p>{this.props.t("TV_SID_BILLING_CBAUG_MSG_INFORM_REGISTER_CARDS")}</p>
                  </div>
                  <div className="s-container-titlewrap q-txt-left q-pl0">
                    <h1 className="s-container-title">{this.props.t("TV_SID_BILLING_CBAUG_REGISTERABLE_CARDS")}</h1>
                  </div>
                  <div className="s-des-linklist q-regs-card ">
                    <div className="s-des-linkitem">
                      {this.state.vaultCardList.map(vaultCard => (
                          <a key={vaultCard.vaultCardId} href={this.addGccRegPage + `&${QS_VAULT_CARD_ID}=${vaultCard.vaultCardId}`}>
                            <div className="s-des-overview">
                              <img src={payment_bg_card} alt={`card`} className="s-vaultcard-thumbnail"/>
                              <div className="s-display-inline">
                                <p className="s-item-title">{vaultCard.cardInfo.brand}-{vaultCard.cardInfo.last4}</p>
                                <p className="s-item-text">{this.props.t("TV_SID_BILLING_MIX_CBAUG_SAVED_IN", {A: vaultCard.serviceName})}</p>
                              </div>
                              <a className="s-des-arrow"><span>view</span></a>
                            </div>
                          </a>
                      ))}
                    </div>
                    <div className="q-divider q-div-transparent"/>
                    <div className="s-des-linkitem">
                      <a href={this.addGccRegPage}>
                        <div className="s-des-overview">
                          <h4 className="s-item-title">{this.props.t("TV_SID_BILLING_CBOCT_ADD_NEW_CARD")}</h4>
                          <p className="s-item-text">{this.props.t("TV_SID_PYMENT_WEBPORTAL_42704_STRING_19")}</p>
                          <a className="s-des-arrow"><span>view</span></a>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
        </PageContainer>
    );
  }

  componentDidMount() {
    if (!isVaultAvailableCountry(this.props.userCountry)) {
      this.props.showProgressBar();
      window.location.assign(this.addGccRegPage);
      return;
    }

    this.fetchVaultCardList((responseData) => {
      if (responseData.vaultCardList && responseData.vaultCardList.length > 0) {
        this.setState({vaultCardList: responseData.vaultCardList}, () => this.props.hideAnyPopup());
      } else {
        this.props.showProgressBar();
        window.location.assign(this.addGccRegPage);
      }
    });
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(ListVaultCardsPage)));