export const TYPE_ACTIVATE_DIM = "TYPE_ACTIVATE_DIM";
export const TYPE_DEACTIVATE_DIM = "TYPE_DEACTIVATE_DIM";

export const activateDim = (obj, disableTouch) => {
  return {
    type: TYPE_ACTIVATE_DIM,
    payload: {
      excludeDimObj: obj,
      isTouchDisable: disableTouch
    }
  };
};

export const deactivateDim = () => {
  return {
    type: TYPE_DEACTIVATE_DIM
  };
};
