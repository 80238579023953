import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Input from './Input';
import ErrorMsg from './ErrorMsg';
import {autobind} from 'core-decorators';

@autobind
class CardExpirationDateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarning: false
    };
  }

  setWarningOff(e) {
    e.preventDefault();
    this.setState({isWarning: false});
  }

  toggleWarningStatus(value) {
    this.setState({isWarning: value});
  }

  setIsValidToWarningStatus(e) {
    e.preventDefault();
    this.toggleWarningStatus(!this.props.isValid);
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.setWarningOff(e);
  }

  render() {
    return (
        <div className={`form-group s-is-date`}>
          <h3 className={`s-form-title`}>
            {this.props.t('COM_EXPIRATION_DATE_KR_DATE')}
          </h3>
          <div className={`s-input-inlinegroup`}>
            <Input
                warning={this.state.isWarning}
                tabIndex={this.props.tabIndex[0]}
                type={`tel`}
                name={this.props.name[0]}
                maxLength={2}
                value={this.props.monthValue}
                placeholder={`MM`}
                onChange={this.props.onChange}
                onBlur={this.setIsValidToWarningStatus}
                onFocus={this.handleFocus}/>
            <Input
                warning={this.state.isWarning}
                tabIndex={this.props.tabIndex[1]}
                type={`tel`}
                className={`s-cleanable`}
                name={this.props.name[1]}
                required
                maxLength={4}
                value={this.props.yearValue}
                placeholder={`YYYY`}
                onChange={this.props.onChange}
                onBlur={this.setIsValidToWarningStatus}
                onFocus={this.handleFocus}/>
          </div>
          <ErrorMsg
              warning={this.state.isWarning}
              message={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_34')}/>
        </div>
    );
  }
}

export default withTranslation()(CardExpirationDateInput);
