import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {URL_PAYMETHODS_AUTHCODE_PAGE, URL_PAYMETHODS_LIST_VAULT_CARD_PAGE, URL_PAYMETHODS_REGISTER_KCC_PAGE} from '../../utils/urlConstant';
import SContainer from '../SContainer';
import {AUTH, PAYMETHOD_GCC, PAYMETHOD_KCC, PAYMETHOD_PP} from '../../utils/globalConstants';
import {MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import Footer from '../Footer';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {autobind} from 'core-decorators';
import PageContainer from '../PageContainer';

@autobind
class RegisterPaymethodSelectPage extends Component {

  renderRegisterButton(method) {
    let icon;
    let itemTitle;
    let itemText;
    let handleClick;
    if (method === PAYMETHOD_PP) {
      handleClick = () => this.props.showPopup4Line(this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_17'));
      icon = <span className={`s-item-icon s-icon-paypal`}/>;
      itemTitle = <h4 className={`s-item-title`}>{this.props.t('TV_SID_WEBSITE_PAYPAL')}</h4>;
      itemText = <p className={`s-item-text`}>{this.props.t('TV_SID_PAYMENT_SIGN_IN_PAYPAL_ACCOUNT')}</p>;
    } else if (method === AUTH) {
      handleClick = () => window.location.assign(URL_PAYMETHODS_AUTHCODE_PAGE);
      icon = <span className={`s-item-icon s-icon-code`}/>;
      itemTitle = <h4 className={`s-item-title`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_20')}</h4>;
      itemText = <p className={`s-item-text`}>{this.props.t('TV_SID_BILIING_CONTINUE_DEBIT_CREDIT_CODE_TV')}</p>;
    } else if (method === PAYMETHOD_KCC) {
      handleClick = () => window.location.assign(URL_PAYMETHODS_REGISTER_KCC_PAGE);
      icon = <span className={`s-item-icon s-icon-card`}/>;
      itemTitle = <h4 className={`s-item-title`}>{this.props.t('COM_SID_CREDIT_DEBIT_CARD')}</h4>;
      itemText = <p className={`s-item-text`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_19')}</p>;
    } else if (method === PAYMETHOD_GCC) {
      handleClick = () => window.location.assign(URL_PAYMETHODS_LIST_VAULT_CARD_PAGE);
      icon = <span className={`s-item-icon s-icon-card`}/>;
      itemTitle = <h4 className={`s-item-title`}>{this.props.t('COM_SID_CREDIT_DEBIT_CARD')}</h4>;
      itemText = <p className={`s-item-text`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_19')}</p>;
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    return (
        <div className={`s-des-linkitem`}>
          <a className={`s-des-overview`} onClick={handleClick}>
            {icon}
            {itemTitle}
            {itemText}
            <span className={`s-des-arrow`}><span>view</span></span>
          </a>
        </div>
    );
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={true}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_16')}>
          <SContainer>
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3`}>
                  <div className={`s-container-titlewrap`}>
                    <h3 className={`s-container-title`}>
                      {this.props.t('COM_SID_CHOOSE_YOUR_FAVORITE_PAYMENT_METHOD')}
                    </h3>
                  </div>
                  <div className={`s-des-linklist s-is-centeradjust`}>
                    {this.props.addablePaymethods.indexOf(PAYMETHOD_PP) >= 0 &&
                    this.renderRegisterButton(PAYMETHOD_PP)
                    }
                    {this.props.addablePaymethods.indexOf(PAYMETHOD_KCC) >= 0 &&
                    this.renderRegisterButton(PAYMETHOD_GCC)
                    }
                    {this.props.addablePaymethods.indexOf(PAYMETHOD_GCC) >= 0 &&
                    this.renderRegisterButton(PAYMETHOD_GCC)
                    }
                    {this.props.addablePaymethods.indexOf(AUTH) >= 0 &&
                    this.renderRegisterButton(AUTH)
                    }
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(RegisterPaymethodSelectPage)));
