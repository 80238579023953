import {TYPE_ACTIVATE_DIM, TYPE_DEACTIVATE_DIM} from './dimAction';

const dimDefaultState = {
  isDim: false,
  excludeDimObj: {},
  isTouchDisable: false
};

export const dimReducer = (state = dimDefaultState, action) => {
  switch (action.type) {
    case TYPE_ACTIVATE_DIM:
      return {
        ...state,
        isDim: true,
        ...action.payload
      };
    case TYPE_DEACTIVATE_DIM:
      return dimDefaultState;
    default:
      return state;
  }
};
