import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {ERR_CODE_SUCCESS} from '../utils/errorConstant';
import {URL_AUTH_CHECK_CODE_API} from '../utils/urlConstant';
import AuthCode from './AuthCode';
import Form from './Form';
import withPopupDispatch from './hoc/withPopupDispatch';
import {errorCode2Msg} from '../translations/i18n-helper';
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../utils/httpClient';

@autobind
class AuthCodeWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      actionUrl: ""
    };

    this.formRef = React.createRef();
  }

  navToCardRegisterPage(e) {
    e.preventDefault();

    this.props.showProgressBar();

    httpClient
        .post(URL_AUTH_CHECK_CODE_API, {
          authCode: this.state.inputValue
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.setState({actionUrl: "/portal" + response.data.actionUrl}, () => {
            this.props.showProgressBar(); // Nobody will hide this because page will be jumped
            this.formRef.current.submit()
          });
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  filterNumber(e) {
    e.preventDefault();
    let newValue = e.target.value.replace(/\D/g, "");
    this.setState({inputValue: newValue});
  }

  showPopupLongLine(e) {
    e.preventDefault();
    let popupContent = this.props.t('TV_SID_PAYMENT_CONTINUE_DEBIT_CREDIT_REG_SHOWN');
    this.props.showPopupLongLine(popupContent)
  }

  render() {
    return (
        <div className={`container s-scform-inner`}>
          <div className={`row welcome_tv`}>
            <div className={`col-md-6 col-md-offset-3`}>
              <div className={`s-tvcode-section`}>
                <Form method={`GET`}
                      ref={this.formRef}
                      action={this.state.actionUrl}>
                  <div className={`s-tvcode-head`}>
                    <strong className={`text-center`}>
                      {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_2')}
                    </strong>
                    <a className={`s-link-help`} onClick={this.showPopupLongLine}>
                      <span className={`s-icon-help`}>HELP</span>
                    </a>
                  </div>
                  <AuthCode
                      inputValue={this.state.inputValue}
                      buttonClassName={`one_btn_wrapping formM`}
                      onInputChange={this.filterNumber}
                      onButtonClick={this.navToCardRegisterPage}/>
                </Form>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withTranslation()(withPopupDispatch(AuthCodeWrapper));
