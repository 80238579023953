import React from 'react';
import Select from './Select';

export default function StateList({stateList, title, defaultLabel, value, onChange}) {
  stateList.sort((a, b) => a.name.localeCompare(b.name));

  return (
      <div className={`form-group`}>
        <h3 className={`s-form-title`}>{title}</h3>
        <Select
            name={`selectState`}
            value={value}
            defaultLabel={defaultLabel}
            onChange={onChange}>
          {stateList.map(state => <option value={state.code} key={state.code}>{state.name}</option>)}
        </Select>
      </div>
  );
}