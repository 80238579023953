export const MSG_WILL_NEVER_HAPPEN = "Will Never Happen";
export const MSG_SHOULD_NEVER_HAPPEN = "Should Never Happen";
export const ERR_CODE_SUCCESS = "100000";
export const ERR_CODE_GENERAL = "130998";
export const ERR_CODE_AUTH_CODE_EXPIRED = "140101";
export const ERR_CODE_NOT_SAME_GUID_CNTR = "140301";
export const ERR_CODE_UNABLE_TO_COMPLETE_TRANSACTION = "140109";

export const ERR_CODE_CARD_USED_FOR_SUBS = "SB_0010";
export const ERR_CODE_PP_USED_FOR_SUBS = "SB_0026";
export const ERR_CODE_DO_SCA = "PBP_DO_SCA";
export const ERR_CODE_DO_SCA_ADYEN = "PBP_DO_SCA_ADYEN";

export const ERR_3DS_FAILED = "ERR_3DS_FAILED";

