import React, {Component} from 'react';
import withPopupComponents from "../hoc/withPopupComponents";
import {withTranslation} from "react-i18next";
import withPopupDispatch from "../hoc/withPopupDispatch";
import SContainer from "../SContainer";
import Footer from "../Footer";
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from "../../utils/errorConstant";
import {URL_PAYMETHODS_CHANGE_STATE_API} from "../../utils/urlConstant";
import {errorCode2Msg} from "../../translations/i18n-helper";
import Button from "../Button";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';
import {QS_COUNTRY} from '../../utils/globalConstants';
import StateList from '../StateList';

@autobind
class StateChangePage extends Component {
  constructor(props) {
    super(props);

    if (!this.props.isCountryAdditionalTaxState) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    this.state = {
      state: "",
      stateList: []
    }
  }

  fetchStateList() {
    httpClient
        .get(URL_PAYMETHODS_CHANGE_STATE_API + `?${QS_COUNTRY}=${this.props.userCountry}`)
        .then(response => {
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.setState({stateList: response.data.stateList});
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  onButtonClick(e) {
    e.preventDefault();

    this.props.showProgressBar();

    httpClient
        .post(URL_PAYMETHODS_CHANGE_STATE_API, {
          cardId: this.props.cardId,
          state: this.state.state
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          window.history.back(); // Go back if success
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={true}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('TV_SID_BILLING_CBAUG_EDIT_PROVINCE_TERRITORY')}>
          <SContainer>
            <div className={`container`}>
              <div className={'row'}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s-addmethod-section`}>
                    <StateList
                        value={this.state.state}
                        onChange={(state) => this.setState({state: state})}
                        stateList={this.state.stateList}
                        title={this.props.t('TV_SID_BILLING_CBAUG_SELELCT_PROVICE_TERRITORY_CARD')}
                        defaultLabel={this.props.t('TV_SID_BILLING_CBAUG_PROVICE_TERRITORY')}/>
                    <Button
                        disabled={!this.state.state}
                        className={`one_btn_wrapping`}
                        onClick={this.onButtonClick}
                        value={this.props.t('COM_SID_DONE')}/>
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.fetchStateList();
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(StateChangePage)));
