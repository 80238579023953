import React, {Component} from 'react';
import {autobind} from 'core-decorators';

@autobind
class Form extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleKeyPress(e) {
    // ignore enter event
    if (e.which === 13) {
      e.preventDefault();
    }
  }

  submit() {
    this.formRef.current.submit();
  }

  render() {
    return (
        <form {...this.props} onKeyPress={this.handleKeyPress} ref={this.formRef}>
          {this.props.children}
        </form>
    );
  }
}

export default Form;
