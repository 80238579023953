import React, {Component} from 'react';
import DimDropdown from './core/DimDropdown';
import {URL_SUPPORT_HELP_EMAIL_PAGE} from '../../utils/urlConstant';
import {withTranslation} from 'react-i18next';
import {MenuItem} from 'react-bootstrap';

/**
 * Show link to help page
 */
class QuickMenu extends Component {
  render() {
    return (
        <DimDropdown
            id={`dropdownQuick`}
            alt={`QUICK MENU`}
            className={`s-quickmenu`}
            toggleClassName={`s-btn s-btn-quickmenu dropdown-toggle`}>
          <ul className={`dropdown-menu dropdown-menu-right`}>
            <MenuItem href={URL_SUPPORT_HELP_EMAIL_PAGE}>
              {this.props.t('COM_SID_HELP')}
            </MenuItem>
          </ul>
        </DimDropdown>
    );
  }
}

export default withTranslation()(QuickMenu);
