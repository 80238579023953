import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";
import {initReactI18next} from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      // Set to false when going to production
      debug: false,

      // Use en if detected lng is not available
      fallbackLng: 'en',

      // React already safes from xss
      interpolation: {escapeValue: false},

      // Message files is only 1 depth, so this is not used.
      keySeparator: '::',

      // Have a common namespace used around the full app
      ns: ['message'],
      defaultNS: 'message',

      // i18next-browser-languagedetector options
      detection: {
        // because server module always give the client language by cookie, cookie is the first
        order: ['cookie', 'querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      },

      // i18next-http-backend options
      backend: {
        // i18next-http-backend honors i18next-browser-languagedetector 'order' option, it will always use cookie
        loadPath: process.env.PUBLIC_URL + '/static/{{ns}}_{{lng}}.json'
      }
    }, (error, t) => {
      if (error) {
        console.error(error);
      }
    });

export default i18n;