import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import SContainer from '../SContainer';
import Form from '../Form';
import OrderStatusFilterMenu from '../dimdropdown/OrderStatusFilterMenu';
import Footer from '../Footer';
import PaymethodFilterMenu from '../dimdropdown/PaymethodFilterMenu';
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import {URL_ORDERS_GET_INVOICES_API} from '../../utils/urlConstant';
import {
  PAYMETHOD_GCC,
  PAYMETHOD_IWP,
  PAYMETHOD_KCC,
  PAYMETHOD_KSP,
  PAYMETHOD_PP, PAYMETHOD_TOSS,
  PAYMETHOD_WSP
} from '../../utils/globalConstants';
import {formatDpiDate} from '../../utils/simpleDateUtils';
import SubscriptionStatusFilterMenu from '../dimdropdown/SubscriptionStatusFilterMenu';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {getCookie, setCookie} from '../../utils/simpleCookieUtils';
import {getInvoiceDetailPage} from "./OrderDetailsPage";
import {getParams} from "../../utils/queryUtil";
import {errorCode2Msg, payType2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

const EVENT_TABCHANGE = "EVENT_TABCHANGE";
const EVENT_ORDERSTATUS_CHANGE = "EVENT_ORDERSTATUS_CHANGE";
const EVENT_PAYMETHOD_CHANGE = "EVENT_PAYMETHOD_CHANGE";
const EVENT_FIRST_FETCH = "EVENT_FIRST_FETCH";
const EVENT_SCROLL_BOTTOM = "EVENT_SCROLL_BOTTOM";
const EVENT_SUBSCRIPTION_CHANGE = "EVENT_SUBSCRIPTION_CHANGE";

const MENU_ORDERS = "MENU_ORDERS";
const MENU_SUBSCRIPTIONS = "MENU_SUBSCRIPTIONS";

export const SUBSCRIPTION_STATUS_ACTIVE = "SUBSCRIPTION_STATUS_ACTIVE";
export const SUBSCRIPTION_STATUS_CANCELED = "SUBSCRIPTION_STATUS_CANCELED";

// See viewType in web/user/invoice DPI_API doc
export const VIEW_TYPE_ORDERS = "1";
export const VIEW_TYPE_ACTIVE_SUBSCRIPTION = "2";
export const VIEW_TYPE_CANCELED_SUBSCRIPTION = "3";

// See reqType in web/user/invoice DPI_API doc
export const ORDER_STATUS_ALL = "0";
export const ORDER_STATUS_PAID = "4";
export const ORDER_STATUS_REFUNDED = "5";

// See Request parameter payType in web/user/invoice DPI_API doc
export const PAYTYPE_STATUS_ALL = "ALL";
export const PAYTYPE_STATUS_PP = "PP";
export const PAYTYPE_STATUS_CC = "CC";
export const PAYTYPE_STATUS_SP = "SP";
export const PAYTYPE_STATUS_CP = "COUPN";
export const PAYTYPE_STATUS_FT = "FT";
export const PAYTYPE_STATUS_ETC = "ETC";
export const PAYTYPE_STATUS_TOSS = "KVSPT";
export const PAYTYPE_STATUS_IWP = "IWP";

const COOKIE_MENU = "menu";
const COOKIE_SUBSCRIPTION_STATUS = "subscriptionStatus";
const COOKIE_ORDER_STATUS = "orderStatus";
const COOKIE_PAYMETHOD_STATUS = "paymethodStatus";

const ENABLE_BOTTOM_SCROLL_EVENT = 10;
const PREVENT_BOTTOM_SCROLL_EVENT = -1000000000;

let BOTTOM_OFFSET = ENABLE_BOTTOM_SCROLL_EVENT;

export const paymethodToPayType = function (paymethod) {
  switch (paymethod) {
    case PAYMETHOD_PP:
      return PAYTYPE_STATUS_PP;
    case PAYMETHOD_GCC:
    case PAYMETHOD_KCC:
    case PAYMETHOD_TOSS:
      return PAYTYPE_STATUS_CC;
    case PAYMETHOD_KSP:
    case PAYMETHOD_WSP:
      return PAYTYPE_STATUS_SP;
    case PAYMETHOD_IWP:
      return PAYTYPE_STATUS_IWP
    default:
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
  }
};

@autobind
class OrdersHistoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMenu: window.location.hash.indexOf("#subscriptions") >= 0 ? MENU_SUBSCRIPTIONS : getCookie(COOKIE_MENU) || MENU_ORDERS,
      currentSubscriptionStatus: getCookie(COOKIE_SUBSCRIPTION_STATUS) || SUBSCRIPTION_STATUS_ACTIVE,
      currentOrderStatus: getCookie(COOKIE_ORDER_STATUS) || ORDER_STATUS_ALL,
      currentPaymethodStatus: getCookie(COOKIE_PAYMETHOD_STATUS) || getParams(window.location.search).payType || PAYTYPE_STATUS_ALL,
      isDataLoading: false,
      isLoadingBar: false,
      data: null,
    };
  }

  setNullSafeData(data) {
    if (!data) { // do nothing if data itself is falsy value
      return;
    }

    // if data is not falsy value, set properties as null safe value
    if (!data.subscribeCount) {
      data.subscribeCount = 0;
    }
    if (!data.unSubscribeCount) {
      data.unSubscribeCount = 0;
    }
    if (!data.yearCount) {
      data.yearCount = [];
    }
    if (!data.invoiceDetails) {
      data.invoiceDetails = [];
    }
    if (!data.subscriptionInfo) {
      data.subscriptionInfo = [];
    }
  }

  setStateWithCookie(state) {
    this.setNullSafeData(state.data);

    this.setState(state);
    if (state.currentMenu) {
      setCookie(COOKIE_MENU, state.currentMenu);
    }
    if (state.currentSubscriptionStatus) {
      setCookie(COOKIE_SUBSCRIPTION_STATUS, state.currentSubscriptionStatus);
    }
    if (state.currentOrderStatus) {
      setCookie(COOKIE_ORDER_STATUS, state.currentOrderStatus);
    }
    if (state.currentPaymethodStatus) {
      setCookie(COOKIE_PAYMETHOD_STATUS, state.currentPaymethodStatus);
    }
  }

  handleScrollToBottom(e) {
    e.preventDefault();
    if (document.documentElement.scrollHeight - window.innerHeight - window.pageYOffset <= BOTTOM_OFFSET) {
      BOTTOM_OFFSET = PREVENT_BOTTOM_SCROLL_EVENT;

      // no data, do nothing
      if (!this.state.data) {
        return;
      }

      // have data but if empty invoices, do nothing
      if (this.state.currentMenu === MENU_ORDERS) {
        if (this.state.data.invoiceDetails.length <= 0) {
          return;
        }
      } else if (this.state.currentMenu === MENU_SUBSCRIPTIONS) {
        if (this.state.data.subscriptionInfo.length <= 0) {
          return;
        }
      }

      // load data
      this.triggerLoadData(EVENT_SCROLL_BOTTOM, null, () => BOTTOM_OFFSET = ENABLE_BOTTOM_SCROLL_EVENT);
    }
  }

  renderYearInvoices(idx, lists) {
    return <ul key={idx} className={`s-img-panel`}>{lists}</ul>;
  }

  renderYearBar(idx, year, yearCount) {
    return <h3 key={idx} className={`s-panel-title`}>{year + " "}<span>{"(" + yearCount + ")"}</span></h3>;
  }

  renderList(info, canceled, cancelTextId, dateFormat, handleListClick) {
    let infoTag;
    if (canceled) {
      infoTag = <span>{this.props.t(cancelTextId, {date: formatDpiDate(info.cancelTime, "/", dateFormat, true)})}</span>;
    } else {
      let displayPayMethod = payType2Msg(this.props.t, paymethodToPayType(info.payMethod));

      // 무료체험이 아니고, 결제수단이 두개 이상인 상황은 실결제 + 쿠폰만 존재
      if (parseInt(info.freeTrialDayCount) === 0
          && parseFloat(info.paymentPrice) > 0
          && parseFloat(info.couponAmt) > 0) {
        displayPayMethod += ", " + this.props.t("TV_SID_PYMENT_WEBPORTAL_42704_STRING_55");
      }

      infoTag =
          <Fragment>
            <span>{formatDpiDate(info.orderTime, "/", "MMDD", true)}</span>
            <span> | {info.currSmbl + info.v_paymentPrice}</span>
            <span> | {displayPayMethod}</span>
          </Fragment>
    }

    return (
        <li key={info.invoiceId}>
          <a className={`s-panel-overview`} onClick={handleListClick}>
            <h3 className={`s-item-title`}>{info.productTitle}</h3>
            <div className={`s-info`}>{infoTag}</div>
            <img src={info.appIconUrl} alt={info.appName}/>
            <span className={`s-panel-arrow`}><span>VIEW</span></span>
          </a>
        </li>
    );
  }

  renderLists() {
    let idx = 0;
    let returnList = [];
    const data = this.state.data;

    if (!data) {
      return <></>;
    }

    if (this.state.currentMenu === MENU_ORDERS) {
      if (data.invoiceDetails.length <= 0) {
        return (
            <div className={`s-none-panel`}>
              <span className={`s-icon-caution`}/><p>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_48')}</p>
            </div>
        );
      }

      // render list by year (2018, 2017, and so on)
      const yearInfoList = data.yearCount;
      yearInfoList.forEach(yearInfo => {
        let isYearInvoiceExist = false;
        let yearInvoices = data.invoiceDetails.map((invoiceDetail) => {
          let invoiceYear = invoiceDetail.orderTime.substring(0, 4);
          if (yearInfo.year !== invoiceYear) {
            return <></>;
          }

          isYearInvoiceExist = true;
          return this.renderList(invoiceDetail,
              invoiceDetail.cancelStatus === "true",
              'TV_SID_BILLING_MIX_REFUNDED',
              "MMDD",
              () => window.location.assign(getInvoiceDetailPage(VIEW_TYPE_ORDERS, invoiceDetail.invoiceId)));
        });

        if (isYearInvoiceExist) {
          returnList.push(this.renderYearInvoices(idx++, yearInvoices));
          returnList.push(this.renderYearBar(idx++, yearInfo.year, yearInfo.count));
        }
      });

      return returnList.reverse();
    } else if (this.state.currentMenu === MENU_SUBSCRIPTIONS) {
      if (data.subscriptionInfo.length <= 0) {
        return (
            <div className={`s-none-panel`}>
              <span className={`s-icon-caution`}/><p>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_48')}</p>
            </div>
        );
      }

      const subscriptionInfos = data.subscriptionInfo;
      let subscriptionLists = subscriptionInfos.map(subscriptionInfo => {
        let viewType = subscriptionInfo.cancelStatus !== "true"
            ? VIEW_TYPE_ACTIVE_SUBSCRIPTION
            : VIEW_TYPE_CANCELED_SUBSCRIPTION;

        return this.renderList(subscriptionInfo,
            this.state.currentSubscriptionStatus === SUBSCRIPTION_STATUS_CANCELED,
            'TV_SID_PAYMENT_MIX_UNSUBSCRIBED_KR_WEBPORTAL',
            "MMDDYY",
            () => window.location.assign(getInvoiceDetailPage(viewType, subscriptionInfo.invoiceId)));
      });

      returnList.push(subscriptionLists);
      return returnList;
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
  }

  fetchData(payload, mergeData, isLoadingBar, successCallback) {
    this.setStateWithCookie({isDataLoading: true});

    if (!isLoadingBar) {
      this.props.showProgressBar();
    } else {
      this.setStateWithCookie({isLoadingBar: true});
    }

    httpClient
        .post(URL_ORDERS_GET_INVOICES_API, payload)
        .then(response => {
          if (!isLoadingBar) {
            this.props.hideAnyPopup();
          } else {
            this.setStateWithCookie({isLoadingBar: false});
          }

          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          let newData = response.data;
          if (mergeData) {
            if (this.state.data) {
              if (this.state.data.invoiceDetails) {
                newData.invoiceDetails = this.state.data.invoiceDetails.concat(newData.invoiceDetails);
              }

              if (this.state.data.subscriptionInfo) {
                newData.subscriptionInfo = this.state.data.subscriptionInfo.concat(newData.subscriptionInfo);
              }
            }
          }

          this.setStateWithCookie({
            data: newData,
            isDataLoading: false
          });

          if (successCallback) {
            successCallback();
          }
        })
        .catch(error => {
          this.setStateWithCookie({
            data: null,
            isDataLoading: false
          });

          defaultErrorHandler(this.props, error);
        });
  }

  getNewViewType(currentMenu, subscriptionStatus) {
    if (currentMenu === MENU_ORDERS) {
      return VIEW_TYPE_ORDERS;
    } else if (currentMenu === MENU_SUBSCRIPTIONS && subscriptionStatus === SUBSCRIPTION_STATUS_ACTIVE) {
      return VIEW_TYPE_ACTIVE_SUBSCRIPTION;
    } else if (currentMenu === MENU_SUBSCRIPTIONS && subscriptionStatus === SUBSCRIPTION_STATUS_CANCELED) {
      return VIEW_TYPE_CANCELED_SUBSCRIPTION;
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
  }

  newPayload(json) {
    let defaultPayload = {
      pageKey: "",
      pageDirection: "",
      pageSort: "D",
      pageSize: "10",
      reqType: this.state.currentOrderStatus,
      payType: this.state.currentPaymethodStatus,
      viewType: this.getNewViewType(this.state.currentMenu, this.state.currentSubscriptionStatus),
    };

    return Object.assign(defaultPayload, json);
  }

  triggerLoadData(eventType, clickedMenu, afterTrigger) {
    switch (eventType) {
      case EVENT_FIRST_FETCH:
        this.fetchData(this.newPayload());
        break;
      case EVENT_TABCHANGE:
        if (clickedMenu === this.state.currentMenu) {
          return;
        }

        this.setStateWithCookie({currentMenu: clickedMenu});
        this.fetchData(this.newPayload({viewType: this.getNewViewType(clickedMenu, this.state.currentSubscriptionStatus)}));
        break;
      case EVENT_ORDERSTATUS_CHANGE:
        if (clickedMenu === this.state.currentOrderStatus) {
          return;
        }

        this.setStateWithCookie({currentOrderStatus: clickedMenu});
        this.fetchData(this.newPayload({reqType: clickedMenu}));
        break;
      case EVENT_PAYMETHOD_CHANGE:
        if (clickedMenu === this.state.currentPaymethodStatus) {
          return;
        }

        this.setStateWithCookie({currentPaymethodStatus: clickedMenu});
        this.fetchData(this.newPayload({payType: clickedMenu}));
        break;
      case EVENT_SUBSCRIPTION_CHANGE:
        if (clickedMenu === this.state.currentSubscriptionStatus) {
          return;
        }

        this.setStateWithCookie({currentSubscriptionStatus: clickedMenu});
        this.fetchData(this.newPayload({viewType: this.getNewViewType(this.state.currentMenu, clickedMenu)}));
        break;
      case EVENT_SCROLL_BOTTOM:
        let pageKey;
        if (this.state.currentMenu === MENU_ORDERS) {
          const invoiceDetails = this.state.data.invoiceDetails;
          pageKey = invoiceDetails[invoiceDetails.length - 1].invoiceId;
        } else if (this.state.currentMenu === MENU_SUBSCRIPTIONS) {
          const subscriptionInfo = this.state.data.subscriptionInfo;
          pageKey = subscriptionInfo[subscriptionInfo.length - 1].invoiceId;
        } else {
          throw new Error(MSG_SHOULD_NEVER_HAPPEN);
        }

        this.fetchData(this.newPayload({
          pageDirection: "B",
          pageKey: pageKey
        }), true, true, afterTrigger);
        break;
      default:
        throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
  }

  handleSubscriptionStatusMenuClick(e, clickedMenu) {
    e.preventDefault();
    this.triggerLoadData(EVENT_SUBSCRIPTION_CHANGE, clickedMenu);
  }

  handlePaymethodMenuClick(e, clickedMenu) {
    e.preventDefault();
    this.triggerLoadData(EVENT_PAYMETHOD_CHANGE, clickedMenu);
  }

  handleOrderStatusMenuClick(e, clickedMenu) {
    e.preventDefault();
    this.triggerLoadData(EVENT_ORDERSTATUS_CHANGE, clickedMenu);
  }

  handleTabClick(e, clickedMenu) {
    e.preventDefault();
    this.triggerLoadData(EVENT_TABCHANGE, clickedMenu);
  }

  renderSubscriptionList() {
    return (
        <Fragment>
          <div className={`s-sorting-wrap clearfix`}>
            <SubscriptionStatusFilterMenu
                onClick={this.handleSubscriptionStatusMenuClick}
                value={this.state.currentSubscriptionStatus}
                menus={[{
                  id: SUBSCRIPTION_STATUS_ACTIVE,
                  text: this.props.t('TV_SID_PAYMENT_MIX_ACTIVE_SUBSCRIPTIONS', {count: this.state.data ? this.state.data.subscribeCount : 'x'})
                }, {
                  id: SUBSCRIPTION_STATUS_CANCELED,
                  text: this.props.t('TV_SID_PAYMENT_MIX_CANCLED_SUBSCRIPTIONS', {count: this.state.data ? this.state.data.unSubscribeCount : 'x'})
                }]}/>
          </div>
          <div className={`s-pullpush-list`}>
            <div className={`s-lazy-list s-img-panel`}>{this.renderLists()}</div>
            {this.state.isLoadingBar && <div className={`s-lazy-loading`}/>}
          </div>
        </Fragment>
    );
  }

  renderOrderHistory() {
    return (
        <Fragment>
          <div className={`s-sorting-wrap clearfix`}>
            <OrderStatusFilterMenu
                onClick={this.handleOrderStatusMenuClick}
                value={this.state.currentOrderStatus}
                menus={[
                  {id: ORDER_STATUS_ALL, text: this.props.t('TV_SID_BILIING_ALL_STATUS')},
                  {id: ORDER_STATUS_PAID, text: this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_61')},
                  {id: ORDER_STATUS_REFUNDED, text: this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_62')},
                ]}/>
            <PaymethodFilterMenu
                onClick={this.handlePaymethodMenuClick}
                value={this.state.currentPaymethodStatus}
                menus={[
                  {id: PAYTYPE_STATUS_ALL, text: payType2Msg(this.props.t, PAYTYPE_STATUS_ALL)},
                  {id: PAYTYPE_STATUS_PP, text: payType2Msg(this.props.t, PAYTYPE_STATUS_PP)},
                  {id: PAYTYPE_STATUS_CC, text: payType2Msg(this.props.t, PAYTYPE_STATUS_CC)},
                  {id: PAYTYPE_STATUS_SP, text: payType2Msg(this.props.t, PAYTYPE_STATUS_SP)},
                  {id: PAYTYPE_STATUS_CP, text: payType2Msg(this.props.t, PAYTYPE_STATUS_CP)},
                  {id: PAYTYPE_STATUS_FT, text: payType2Msg(this.props.t, PAYTYPE_STATUS_FT)},
                  {id: PAYTYPE_STATUS_ETC, text: payType2Msg(this.props.t, PAYTYPE_STATUS_ETC)},
                  {id: PAYTYPE_STATUS_IWP, text: payType2Msg(this.props.t, PAYTYPE_STATUS_IWP)},
                ]}/>
          </div>
          <div className={`s-pullpush-list`}>
            <div className={`s-lazy-list`}>{this.renderLists()}</div>
            {this.state.isLoadingBar && <div className={`s-lazy-loading`}/>}
          </div>
        </Fragment>
    );
  }

  render() {
    return (
        <PageContainer
            className={`s-wrap s-is-navi`}
            showNav={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}>
          <SContainer>
            <Form>
              <div className={`container`}>
                <div className={`row`}>
                  <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 s-xs-offset0`}>
                    <ul className={`s-tab`}>
                      <li className={this.state.currentMenu === MENU_ORDERS ? "s-current" : null}>
                        <a onClick={(e) => this.handleTabClick(e, MENU_ORDERS)}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_46')}</a>
                      </li>
                      <li className={this.state.currentMenu === MENU_SUBSCRIPTIONS ? "s-current" : null}>
                        <a onClick={(e) => this.handleTabClick(e, MENU_SUBSCRIPTIONS)}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_47')}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`s-tabcontent`}>
                  <div className={`row`}>
                    <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 s-xs-offset0`}>
                      {this.state.currentMenu === MENU_ORDERS &&
                      this.renderOrderHistory()
                      }
                      {this.state.currentMenu === MENU_SUBSCRIPTIONS &&
                      this.renderSubscriptionList()
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </SContainer>
          {this.state.isDataLoading === false &&
          <Footer/>
          }
        </PageContainer>
    );
  }

  componentDidMount() {
    this.triggerLoadData(EVENT_FIRST_FETCH);
    window.addEventListener('scroll', this.handleScrollToBottom);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollToBottom);
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(OrdersHistoryPage)));
