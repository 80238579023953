import {getProperty} from './propertiesByDomain';
import {URL_AUTH_LOGOUT} from './urlConstant';

export const logout = (stateData = "") => {
    let logoutUrl = getProperty("urlHome") + URL_AUTH_LOGOUT;
    if(stateData){
        logoutUrl += "?state=" + encodeURIComponent(stateData);
    }

    window.location.href = logoutUrl;
}

