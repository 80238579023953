import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DimMenuDropdown from './core/DimMenuDropdown';

class OrderStatusFilterMenu extends Component {
  render() {
    return (
        <DimMenuDropdown
            id={`dropdownOrderStatusFilter`}
            className={`s-sorting pull-left s-sorting-purchase`}
            toggleClassName={`btn btn-default dropdown-toggle`}
            onClick={this.props.onClick}
            value={this.props.value}
            isCaret={true}
            menus={this.props.menus}/>
    );
  }
}

export default withTranslation()(OrderStatusFilterMenu);
