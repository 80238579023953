import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import i18n from '../translations/i18n';
import {URL_SUPPORT_HELP_EMAIL_PAGE} from '../utils/urlConstant';
import {EMAIL_CHECKOUT_CS} from "../utils/globalConstants"
import {getParams, removeParam} from "../utils/queryUtil";
import {autobind} from 'core-decorators';

// See README.md
const languageList = {
  "ko": "한국어",
  "en": "English",
  "fr-US": "Français(Canada)",
  "es-US": "Español(México)",
  "pt-US": "Português(Brasil)",
  "sq": "Shqip",
  "az": "Azrbaycan",
  "bg": "Български",
  "hr": "Hrvatski",
  "cs": "Čeština",
  "da": "Dansk",
  "nl": "Nederlands",
  "et": "Eesti",
  "fi": "suomi",
  "fr": "Français",
  "de": "Deutsch",
  "el": "Ελληνικά",
  "hu": "Magyar",
  "it": "Italiano",
  "kk": "азаша",
  "lv": "Latviešu",
  "mk": "Македонски",
  "nn": "Norsk",
  "en-GB": "English(UK)",
  "pl": "Polski",
  "pt": "Português",
  "ro": "Română",
  "ru": "Русский",
  "sr": "Srpski",
  "sk": "Slovenčina",
  "sl": "Slovenščina",
  "es": "Español",
  "sv": "Svenska",
  "tr": "Türkçe",
  "uk": "Українська",
  "uz": "Oʻzbek",
  "zh": "简体中文",
  "zh-HK": "中文(香港)",
  "zh-TW": "中文(台灣)",
  "th": "ภาษาไทย",
  "ar": "العربية",
  "he": "עברית",
  "id": "Indonesia",
  "ms": "Melayu",
  "vi": "TingVit",
  "as": "অসমীয়া",
  "bn": "বাংলা",
  "gu": "ગુજરાતી",
  "kn": "ಕನ್ನಡ",
  "ml": "മലയാളം",
  "mr": "मराठी",
  "ne": "नेपाली",
  "or": "ଓଡ଼ିଆ",
  "pa": "ਪੰਜਾਬੀ",
  "ta": "தமிழ்",
  "te": "తెలుగు",
  "ur": "اردو",
  "mn": "Монгол",
  "ja": "日本語",
  "hy": "Հայերեն",
  "ka": "ქართული",
  "my": "ဗမာဘာသာ",
  "km": "ខ្មែរ",
  "tl": "Tagalog",
};

@autobind
class Footer extends Component {

  triggerRequest(e) {
    e.preventDefault();

    const selectTag = e.target;
    const lng = selectTag.options[selectTag.selectedIndex].value;
    const search = window.location.search;
    if (getParams(search).lng != null) {
      window.location.search = removeParam(search, "lng") + "&lng=" + lng;
    } else {
      window.location.search += "&lng=" + lng;
    }
  }

  render() {
    return (
        <footer className={`footer`}>
          <div className={`t_link2`}>
            <a href={`https://account.samsung.com/membership/terms`} target={`_blank`} rel={`noopener noreferrer`}>
              {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_12')}
            </a>
            <span className={`bar`}>|</span>
            <a href={`https://account.samsung.com/membership/pp`} target={`_blank`} rel={`noopener noreferrer`}>
              {this.props.t('COM_TV_SID_PRIVACY_POLICY_KR_BLANK')}
            </a>
            <span className={`bar`}>|</span>
            <div className={`selectLanguage`}>
              <select value={i18n.language} onChange={this.triggerRequest}>
                {Object.keys(languageList).map(keyName => <option key={keyName} value={keyName}>{languageList[keyName]}</option>)}
              </select>
            </div>
          </div>
          <p className={`t_email`}>Samsung Checkout, <a href={URL_SUPPORT_HELP_EMAIL_PAGE}>{EMAIL_CHECKOUT_CS}</a></p>
          <p className={`t_copyright`}>Copyrightⓒ Samsung Electronics Co., Ltd. All Rights Reserved.</p>
        </footer>
    );
  }
}

export default withTranslation()(Footer);
