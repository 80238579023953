import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import SContainer from '../SContainer';
import Footer from '../Footer';
import {URL_PAYMETHODS_CHANGE_STATE_API, URL_PAYMETHODS_DEFAULT_CARD_API, URL_PAYMETHODS_DEL_PAYMETHODS_API, URL_PAYMETHODS_GET_PAYMETHODS_API, URL_PAYMETHODS_PAGE, URL_PAYMETHODS_REGISTER_KCC_PAGE, URL_PAYMETHODS_REGISTER_METHOD_PAGE, URL_SUBSCRIPTION_GET_API} from '../../utils/urlConstant';
import {ERR_CODE_CARD_USED_FOR_SUBS, ERR_CODE_PP_USED_FOR_SUBS, ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN, MSG_WILL_NEVER_HAPPEN} from '../../utils/errorConstant';
import PaymethodsSlider from '../PaymethodsSlider';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {
    KR_VALUE,
    PAYMETHOD_GCC,
    PAYMETHOD_KCC,
    PAYMETHOD_PP,
    PAYMETHOD_TOSS,
    QS_COUNTRY,
    US_VALUE
} from '../../utils/globalConstants';
import {errorCode2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

/**
 * Page where user can manage payment methods.
 * User can add/delete GCC, PP, KCC.
 *
 * For PP :
 *  - Only delete is possible. Adding PP is not possible.
 *
 * For GCC :
 *  - If userCountry is US, then user can edit zipcode for that GCC.
 *  - If isCountryAdditionalTaxState, then user can edit state for that GCC.
 *
 * Note that US should never be isCountryAdditionalTaxState === true
 */
@autobind
class PaymethodsPage extends Component {
  constructor(props) {
    super(props);

    if (props.userCountry === US_VALUE && props.isCountryAdditionalTaxState === true) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    this.state = {
      showRefreshCard: false,
      paymethodsInfo: [],
      addablePaymethods: [],
      stateList: []
    };
  }

  refreshCards(newPaymethodsInfo, newAddablePaymethods) {
    // Put all info's in this state, let PaymethodsSlider do the rest
    this.setState({
      paymethodsInfo: newPaymethodsInfo,
      addablePaymethods: newAddablePaymethods
    });
  }

  handleDelete(paymethod, cardId) {
    if (!(Object.is(paymethod, PAYMETHOD_GCC) || Object.is(paymethod, PAYMETHOD_TOSS)) && cardId) {
      throw new Error(MSG_WILL_NEVER_HAPPEN);
    }

    this.props.showProgressBar();

    httpClient
        .post(URL_PAYMETHODS_DEL_PAYMETHODS_API, {paymethod: paymethod, cardId: cardId})
        .then(response => {
          this.props.hideAnyPopup();

          if (response.data.status.indexOf(ERR_CODE_CARD_USED_FOR_SUBS) >= 0) {
            this.props.showPopupWithListBox(this.props.t("TV_SID_BILLING_NOT_DLELTE_USED_SUBSCRIPTION_AGAIN"), response.data.subscribedProductList);
            return;
          } else if (response.data.status.indexOf(ERR_CODE_PP_USED_FOR_SUBS) >= 0) {
            this.props.showPopupWithListBox(this.props.t("TV_SID_BILLING_CBMAR_NOT_SIGN_PAYPAL_MSG"), response.data.subscribedProductList);
            return;
          } else if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.getPaymethods(() => this.props.showToastPopup(this.props.t('COM_TV_SID_DELETED_SUCCESSFULLY')))
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  checkDeletable(successCallback, paymethod, cardId) {
    this.props.showProgressBar();

    if (cardId === null || cardId === undefined) {
      cardId = '';
    }

    httpClient
        .get(URL_SUBSCRIPTION_GET_API + "?paymethod=" + paymethod + "&cardId=" + cardId)
        .then(response => {
          this.props.hideAnyPopup();

          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          const data = response.data;
          if (data.subscribedProductList.length > 0) {
            const sid = paymethod === PAYMETHOD_GCC ? "TV_SID_BILLING_NOT_DLELTE_USED_SUBSCRIPTION_AGAIN" : "TV_SID_BILLING_CBMAR_NOT_SIGN_PAYPAL_MSG";
            this.props.showPopupWithListBox(this.props.t(sid), data.subscribedProductList);
            return;
          }

          successCallback();
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  onDeleteClick(paymethod, cardId) {
    if (paymethod === PAYMETHOD_GCC) {
      this.checkDeletable(
          () => this.props.showPopupConfirm(this.props.t('TV_SID_BILLING_DELETE_THIS_CARD'), () => this.handleDelete(paymethod, cardId)),
          paymethod,
          cardId);
    } else if (paymethod === PAYMETHOD_KCC) {
      this.props.showPopupConfirm(this.props.t('TV_SID_BILLING_DELETE_THIS_CARD'), () => this.handleDelete(paymethod));
    } else if (paymethod === PAYMETHOD_TOSS) {
      this.props.showPopupConfirm(this.props.t('TV_SID_BILLING_DELETE_THIS_CARD'), () => this.handleDelete(paymethod, cardId));
    } else if (paymethod === PAYMETHOD_PP) {
      this.checkDeletable(
          () => this.props.showPopupConfirm(this.props.t('TV_SID_DO_YOU_WANT_TO_SIGN_OUT_KR_DO'), () => this.handleDelete(paymethod)),
          paymethod);
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
  }

  getPaymethods(successCallback) {
    this.setState({showRefreshCard: false});
    this.props.showProgressBar();

    httpClient
        .get(URL_PAYMETHODS_GET_PAYMETHODS_API)
        .then(response => {
          this.props.hideAnyPopup();

          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.setState({showRefreshCard: true});
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          let resData = response.data;
          this.refreshCards(resData.paymethodResVOList, resData.addablePaymethodList);

          if (successCallback) {
            successCallback();
          }
        })
        .catch(error => {
          this.setState({showRefreshCard: true});
          defaultErrorHandler(this.props, error);
        });
  }

  handleRefresh(e) {
    e.preventDefault();
    this.getPaymethods();
  }

  fetchStateList(successCallback) {
    httpClient
        .get(URL_PAYMETHODS_CHANGE_STATE_API + `?${QS_COUNTRY}=${this.props.userCountry}`)
        .then(response => {
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.setState({stateList: response.data.stateList}, () => successCallback());
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  onDefaultClick(cardId) {
    this.props.showProgressBar();
    httpClient
        .post(URL_PAYMETHODS_DEFAULT_CARD_API, {cardId: cardId})
        .then(response => {
          if(response.data.status === ERR_CODE_SUCCESS) {
            window.open(URL_PAYMETHODS_PAGE, '_self');
          } else {
            let msg = response.data.result ? response.data.result : this.props.t('TV_SID_PAYMENT_MIX_UNABLE_REQUEST_COTACT');
            this.props.showPopupLongLine(msg);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  render() {
    const {paymethodsInfo, addablePaymethods, showRefreshCard} = this.state;
    const {url, userCountry, ssoAccountId, authenticated} = this.props;

      function isShowAddCard() {
          return (
              (addablePaymethods.length === 1 && addablePaymethods[0] !== PAYMETHOD_KCC)
              || addablePaymethods.length > 1
          );
      }

      return (
        <PageContainer
            className={`s-wrap s-is-navi`}
            showNav={true}
            url={url}
            ssoAccountId={ssoAccountId}
            authenticated={authenticated}>
          <SContainer>
            <PaymethodsSlider
                refreshCard={{show: showRefreshCard, onRefresh: this.handleRefresh}}
                addCard={{
                  //   Logic update per 11/2024
                    // If country is KR:
                        // if Inicis Card (KCC) is registered, only show add card for Toss
                        // If Inicis and Toss not registered, show both options
                        // Toss add card button will always shown regardless of how many Toss cards are registered

                    // If Not KR and does not have addable payment methods, dont show "addCard" slide (because of GCC multi-card, this is ALWAYS FALSE)
                    // Else, show "addCard" slide

                  show: isShowAddCard(),
                  isKR: Object.is(userCountry, KR_VALUE),

                  // Logic update per 11/2024
                    // urlOnClick is always redirecting to GCC registration page
                    // KCC registration is handled in a different way
                  urlOnClick: URL_PAYMETHODS_REGISTER_METHOD_PAGE
                }}
                paymethodsInfo={paymethodsInfo}
                masterSlider={{className: "s-pay-slider"}}
                subSlider={{
                  show: true,
                  onDeleteClick: this.onDeleteClick,
                  onDefaultClick: this.onDefaultClick,
                  showZipCode: Object.is(userCountry, US_VALUE), // If user country is not US, then, zipcode can't be modified.
                  showState: this.props.isCountryAdditionalTaxState, // If user country is not additional tax state, then
                  stateList: this.state.stateList // Only exist if isCountryAdditionalTaxState is true
                }}
                isSubscriptionChange={false} //for handling KR toss case
            />
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    if (this.props.isCountryAdditionalTaxState) {
      this.fetchStateList(() => this.getPaymethods());
    } else {
      this.getPaymethods();
    }
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(PaymethodsPage)));
