import React, {Component} from 'react';
import {autobind} from 'core-decorators';
import PageContainer from '../PageContainer';
import SContainer from '../SContainer';
import withPopupComponents from '../hoc/withPopupComponents';
import {withTranslation} from 'react-i18next';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {KR_VALUE} from "../../utils/globalConstants";
import {URL_AUTH_LOGOUT, URL_SIGNON_ADD_KCC_CARD_PAGE, URL_SIGNON_LIST_VAULT_CARD_PAGE} from "../../utils/urlConstant";

@autobind
class WillYouAddCardPage extends Component {

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={false}
            showSubHeader={true}
            subHeaderText={this.props.t('COM_SID_CREDIT_DEBIT_CARD')}>
          <SContainer>
            <div className={`container-fluid s-sc-inner`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                </div>
              </div>
            </div>
          </SContainer>
        </PageContainer>
    );
  }

  componentDidMount() {
    const addCardHref = (this.props.userCountry === KR_VALUE) ? URL_SIGNON_ADD_KCC_CARD_PAGE : URL_SIGNON_LIST_VAULT_CARD_PAGE;

    this.props.showPopupConfirm(
        this.props.t('TV_SID_BILLING_CCJUN_NO_CARD_REG_ACCOUNT_ADD'),
        () => window.location.assign(addCardHref),
        () => window.location.assign(URL_AUTH_LOGOUT),
        this.props.t('TV_SID_BILLING_CCJUN_ADD_CARD'),
        this.props.t('SID_CANCEL'));
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(WillYouAddCardPage)));