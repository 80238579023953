import {TYPE_HIDE_POPUP, TYPE_SHOW_POPUP} from './popupAction';

const popupDefaultState = {
  isOpen: false
};

export const popupReducer = (state = popupDefaultState, action) => {
  switch (action.type) {
    case TYPE_SHOW_POPUP:
      return {
        ...state,
        isOpen: true,
        ...action.payload
      };
    case TYPE_HIDE_POPUP:
      return popupDefaultState;
    default:
      return state;
  }
};
