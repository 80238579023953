import React, {Component} from 'react';
import withPopupComponents from "../hoc/withPopupComponents";
import {withTranslation} from "react-i18next";
import withPopupDispatch from "../hoc/withPopupDispatch";
import SContainer from "../SContainer";
import Footer from "../Footer";
import {US_VALUE} from "../../utils/globalConstants";
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from "../../utils/errorConstant";
import ZipCodeInput from "../ZipCodeInput";
import {URL_PAYMETHODS_CHANGE_ZIP_CODE_API, URL_PAYMETHODS_ZIP_CODE_CHECK_API} from "../../utils/urlConstant";
import {errorCode2Msg} from "../../translations/i18n-helper";
import Button from "../Button";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

@autobind
class ZipCodeChangePage extends Component {
  constructor(props) {
    super(props);

    if (!Object.is(this.props.userCountry, US_VALUE)) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    this.state = {
      zipCodeChecked: false,
      zipCode: "",
      zipCodeLoading: false,
      state: "",
      city: "",
    }
  }

  onButtonClick(e) {
    e.preventDefault();

    this.props.showProgressBar();

    httpClient
        .post(URL_PAYMETHODS_CHANGE_ZIP_CODE_API, {
          cardId: this.props.cardId,
          zipCode: this.state.zipCode
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          window.history.back(); // Go back if success
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  setZipCodeProperties(state, city, isValid) {
    this.setState({
      state: state,
      city: city,
      zipCodeChecked: isValid
    });
  }

  handleZipCodeChange(e, callbackToggleWarningStatus) {
    e.preventDefault();

    // filter
    let newValue = e.target.value.replace(/\D/g, "");
    this.setState({zipCode: newValue});

    // init
    callbackToggleWarningStatus(false);

    // greater or equal to 5 length zip code can call api
    if (newValue.length < 5) {
      return;
    }

    this.setState({zipCodeLoading: true});

    httpClient
        .post(URL_PAYMETHODS_ZIP_CODE_CHECK_API, {
          zpcd: newValue
        })
        .then(response => {
          this.setState({zipCodeLoading: false});
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.setZipCodeProperties("", "", false);
            callbackToggleWarningStatus(true);
            return;
          }

          let data = response.data;
          this.setZipCodeProperties(data.state, data.city, true);
          callbackToggleWarningStatus(false);
        })
        .catch(error => {
          this.setState({zipCodeLoading: false});
          this.setZipCodeProperties("", "", false);
          callbackToggleWarningStatus(true);
          defaultErrorHandler(this.props, error);
        });
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={true}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('TV_SID_BILLING_CAMAR_EDIT_ZIP_CODE')}>
          <SContainer>
            <div className={`container`}>
              <div className={'row'}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s-addmethod-section`}>
                    <div className={`form-group`}>
                      <h3 className={`s-form-title`}>
                        {this.props.t('COM_MAPP_SID_BILLING_CAFEB_ENTER_ZIP_THIS_CARD')}
                      </h3>
                      <ZipCodeInput
                          name={`zipCode`}
                          tabIndex={`5`}
                          hideName={true}
                          value={this.state.zipCode}
                          isValid={this.state.zipCodeChecked}
                          loading={this.state.zipCodeLoading}
                          onChange={this.handleZipCodeChange}/>
                      <Button
                          disabled={!this.state.zipCodeChecked}
                          className={`one_btn_wrapping`}
                          onClick={this.onButtonClick}
                          value={this.props.t('COM_SID_DONE')}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(ZipCodeChangePage)));
