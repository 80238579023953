import React, {Component} from 'react';

class MainHeader extends Component {
  render() {
    let className = this.props.hidden === true ? "s-header-gnb hidden-xs hidden-sm" : "s-header-gnb";

    return (
        <div className={className}>
          {this.props.children}
        </div>
    );
  }
}

export default MainHeader;
