import React, {Component} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import SContainer from '../SContainer';
import {SAMSUNG_CHECKOUT} from '../../utils/globalConstants';
import Footer from '../Footer';
import {autobind} from 'core-decorators';
import PageContainer from '../PageContainer';

@autobind
class SubscriptionCancelGuidePage extends Component {

  getCurrentYear() {
    return (new Date()).getFullYear();
  }

  render() {
    const t = this.props.t;

    return (
        <PageContainer
            hidden={true}
            showSubHeader={true}
            subHeaderText={SAMSUNG_CHECKOUT}>
          <SContainer>
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3`}>
                  <div className={`s-cancellation-section`}>
                    <h3 className={`s-cancellation-title s-type-left`}>{t('TV_SID_BILLING_HOW_CAN_I_CANCEL_SUBSCRIPTION')}</h3>
                    <div className={`s-topline-section-cc`}>
                      <div className={`list-group`}>
                        <h2 className={`s-list-title`}>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_FROM_THE', {A: '1', B: 'TV/Display'})}</h2>
                        <ol className={`s-list-item`}>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_GO_TO_YOUR_SAMSUNG_ACCOUNT', {A: '1'})}</li>
                          <li>
                            &nbsp;&nbsp;&nbsp;
                            <Trans i18nKey="TV_SID_BILLING_RECEIPT_MIX_CAAUG_IF_2017_MODEL_GO_TO_SETTINGS_SAMSUNG_ACCOUNT">
                              If your <span className={`fc_bold`}>{{A: "TV/Display"}}</span> is a <span className={`fc_green`}>{{B: "2017 - " + this.getCurrentYear()}}</span> model, go to <span className={`fc_bold`}>{{C: "Settings > General > System Manager > Samsung Account"}}</span>.
                            </Trans>
                          </li>
                          <li>
                            &nbsp;&nbsp;&nbsp;
                            <Trans i18nKey="TV_SID_BILLING_2015_TV_PATH">
                              If your <span className={`fc_bold`}>{{A: "TV/Display"}}</span> is a <span className={`fc_green`}>{{B: 2016}}</span> model, go to <span className={`fc_bold`}>{{C: "Settings > System > Samsung Account"}}</span>.
                            </Trans>
                          </li>
                          <li>
                            &nbsp;&nbsp;&nbsp;
                            <Trans i18nKey="TV_SID_BILLING_2015_TV_PATH">
                              If your <span className={`fc_bold`}>{{A: "TV/Display"}}</span> is a <span className={`fc_green`}>{{B: 2015}}</span> model, go to <span className={`fc_bold`}>{{C: "Menu > Smart Hub > Samsung Account"}}</span>.
                            </Trans>
                          </li>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_SIGNIN_TO_YOUR_SAMSUNG_ACCOUNT', {A: '2'})}</li>
                          <li>
                            <Trans i18nKey="TV_SID_BILLING_MIX_CASEP_NUM_YEAR_DEVICENAME">
                              {{A: "3"}}) If your <span className={`fc_bold`}>{{B: "TV/Display"}}</span> is a <span className={`fc_green`}>{{C: "2017 - " + this.getCurrentYear()}}</span> model, select <span className={`fc_bold`}>{{D: "My Account"}}</span>.
                            </Trans>
                          </li>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_SELECT_PAYMENT_INFO', {A: '4'})}</li>
                          <li>
                            <Trans i18nKey="TV_SID_BILLING_RECEIPT_MIX_CAAUG_IF_2019_MODEL_SELECT_SUBSCRIPTIONS">
                              {{A: "5"}}) If your <span className={`fc_bold`}>{{B: "TV/Display"}}</span> is <span className={`fc_green`}>{{C: "2019 - " + this.getCurrentYear()}}</span> model, select <span className={`fc_bold`}>{{D: "Subscriptions"}}</span>.
                            </Trans>
                          </li>
                          <li>
                            &nbsp;&nbsp;&nbsp;
                            <Trans i18nKey="TV_SID_BILLING_RECEIPT_MIX_CAAUG_IF_2015_TO_2018_MODEL_SELECT_PURCHASE_HISTORY">
                              If your <span className={`fc_bold`}>{{A: "TV/Display"}}</span> is <span className={`fc_green`}>{{B: "2015 - 2018"}}</span> model, select <span className={`fc_bold`}>{{C: "Purchase History"}}</span>.
                            </Trans>
                          </li>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_MOVE_THE_FOCUS_SUBSCRIPTION_WANT_CANCEL', {A: '6'})}</li>
                        </ol>
                        <p className={`s-list-info`}>
                          <Trans i18nKey="TV_SID_BILLING_RECEIPT_MIX_CAAUG_IF_CANNOT_FIND_PAYMENTINFO_SUBSCRIPTION_UPDATE_SOFTWARE">
                            &#042;&nbsp;If you cannot find the Payment Info or Subscriptions menu after signing in, update your <span>{{A: "TV/Display"}}</span> by going to <span>{{B: "Menu > Support > Software Update"}}</span>.
                          </Trans>
                        </p>
                      </div>
                      <div className={`list-group`}>
                        <h2 className={`s-list-title`}>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_FROM_THE_SAMSUNG_CHECKOUT_WEBSITE', {A: '2'})}</h2>
                        <ol className={`s-list-item`}>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_VISIT', {A: '1', B: 'www.samsungcheckout.com'})}</li>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_SIGNIN_WITH_YOUR_SAMSUNG_ACCOUNT', {A: '2'})}</li>
                          <li>3) {t('TV_SID_BILLING_SELELCT_SUBSCRIPTIONS_PURCHASE_HISTORY')}</li>
                          <li>{t('TV_SID_BILLING_RECEIPT_MIX_CAAUG_FIND_SUBSCRIPTION_SELECT_UNSUBSCRIBE', {A: '4'})}</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withTranslation()(SubscriptionCancelGuidePage);
