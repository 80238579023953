import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Input from './Input';
import {autobind} from 'core-decorators';

@autobind
class EmailInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarning: false
    };
  }

  setWarningOff(e) {
    e.preventDefault();
    this.setState({isWarning: false});
  }

  toggleWarningStatus(value) {
    this.setState({isWarning: value});
  }

  setIsValidToWarningStatus(e) {
    e.preventDefault();
    this.toggleWarningStatus(!this.props.isValid);
  }

  render() {
    return (
        <div className={`form-group`}>
          <h3 className={`s-form-title`}>
            {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_67')}
          </h3>
          <Input
              warning={this.state.isWarning}
              tabIndex={this.props.tabIndex}
              type={`email`}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
              onBlur={this.setIsValidToWarningStatus}
              onFocus={this.setWarningOff}/>
        </div>
    );
  }
}

export default withTranslation()(EmailInput);
