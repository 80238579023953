export const setCookie = (cookieName, cookieValue, expires) => {
  expires = expires || "expires=" + (new Date(new Date().getTime() + 120000)).toUTCString();
  document.cookie = cookieName + "=" + encodeURI(cookieValue) + ';path=/;' + expires;
};

export const getCookie = (cookieName) => {
  let cookieValue;
  if (document.cookie) {
    let array = document.cookie.split((encodeURI(cookieName) + '='));
    if (array.length >= 2) {
      let arraySub = array[1].split(';');
      cookieValue = decodeURI(arraySub[0]);
    }
  }
  return cookieValue;
};

export const delCookie = (cookieName) => {
  let cookieValue = getCookie(cookieName);
  if (cookieValue) {
    setCookie(cookieName, cookieValue, (new Date(1)));
  }
};