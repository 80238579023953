import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import ReactIScroll from 'react-iscroll';
import iScroll from 'iscroll';
import {URL_BENEFITS_PAGE, URL_CUSTOMERS_PAGE, URL_ORDERS_PAGE, URL_PAYMETHODS_PAGE} from '../utils/urlConstant';
import withPopupDispatch from './hoc/withPopupDispatch';
import {ISCROLL_MENU_SCROLL_DURATION} from "../utils/globalConstants";
import {autobind} from 'core-decorators';

// IE polly fill for "new Event("click")" code (https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work)
(function () {
  if (typeof window.CustomEvent === "function") {
    return false;
  }

  function CustomEvent(event, params) {
    params = params || {bubbles: false, cancelable: false, detail: null};
    let evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

@autobind
class NaviHeader extends Component {
  constructor(props) {
    super(props);

    //
    // iscroll
    //
    this.scrollRef = React.createRef();

    //
    // menus
    //
    this.menuProperties = [
      {url: URL_PAYMETHODS_PAGE, label: this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_8'), ref: React.createRef(), listener: null},
      {url: URL_ORDERS_PAGE, label: this.props.t('COM_SID_PURCHASE_HISTORY_KR_MOBILE'), ref: React.createRef(), listener: null},
      {url: URL_BENEFITS_PAGE, label: this.props.t('TV_SID_PAYMENT_PROMOTIONAL_CODES'), ref: React.createRef(), listener: null},
      {url: URL_CUSTOMERS_PAGE, label: this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_10'), ref: React.createRef(), listener: null},
    ];

    //
    // find current clicked menu
    //
    let clickedMenuProps = null;
    this.menuProperties.forEach(menuProps => {
      if (menuProps.url === this.props.url) {
        clickedMenuProps = menuProps;
      }
    });

    this.state = {
      listWidth: 0,
      clickedMenuProps: clickedMenuProps
    };
  }

  //
  // update the list width when resize, by sum each element width
  //
  updateListWidth() {
    let listWidth = 0;
    this.menuProperties.forEach(props => {
      listWidth += parseInt(props.ref.current.offsetWidth, 10);
    });

    if (listWidth !== this.state.listWidth) {
      this.setState({listWidth: listWidth + 20});
    }

    if (this.scrollRef.current) {
      this.scrollRef.current.withIScroll(true, iScroll => {
        iScroll.refresh();
      });
    }
  }

  //
  // Make a iScroll animation to that menu, and light up that menu.
  //
  handleMenuClick(e, clickedMenuProps) {
    if (this.scrollRef.current) {
      this.scrollRef.current.withIScroll(true, iScroll => {
        iScroll.scrollToElement(clickedMenuProps.ref.current, ISCROLL_MENU_SCROLL_DURATION);
      });
    }

    this.setState({
      clickedMenuProps: clickedMenuProps
    });
  }

  renderMenu(props) {
    //
    // light up only current or clicked menu
    //
    let liClassName;
    if (this.state.clickedMenuProps) {
      liClassName = this.state.clickedMenuProps.url === props.url ? "s-current" : "";
    }

    //
    // if dimAllButBenefit is true,
    // every url but URL_BENEFITS_PAGE
    // should be dimmed and show popup
    //
    if (this.props.dimAllButBenefit && props.url !== URL_BENEFITS_PAGE) {
      liClassName += " s-navi-dim";

      return (
          <li key={props.url} className={liClassName} ref={props.ref}>
            <a onClick={() => this.props.showPopup2Line(this.props.t('TV_SID_BILIING_MENU_AVAIL_PAYMENT_HISTORY'))}>
              {props.label}
            </a>
          </li>
      );
    }

    //
    // please see handleMenuClick for complicated behavior
    //
    return (
        <li key={props.url}
            ref={props.ref}
            className={liClassName}
            onClick={(e) => this.handleMenuClick(e, props)}>
          <a ref={props.ref} href={props.url}>
            {props.label}
          </a>
        </li>
    );
  }

  render() {
    return (
        <div className={this.props.hidden === true ? "s-navi hidden-xs hidden-sm" : "s-navi"}>
          <ReactIScroll iScroll={iScroll}
                        options={{scrollX: true, scrollY: false, tap: true}}
                        style={{touchAction: "none"}}
                        ref={this.scrollRef}>
            <ul style={this.state.listWidth ? {width: this.state.listWidth} : null}>
              {this.menuProperties.map(props => this.renderMenu(props))}
            </ul>
          </ReactIScroll>
        </div>
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateListWidth);

    // TODO iScroll bug workaround
    //       There is a bug in iScroll When using options.click.
    //       So menu listen to the tap, and change it to click.
    this.menuProperties.forEach(props => {
      const targetElement = props.ref.current;
      props.listener = () => {
        try {
          targetElement.dispatchEvent(new Event("click"));
        } catch (e) {
          // IE polly fill for "new Event("click")" code (https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work)
          window.CustomEvent("Click");
        }
      };
      targetElement.addEventListener('tap', props.listener);
    });

    // update on init once
    this.updateListWidth();

    // if clickedMenuExist, simulate click, to make scroll current menu
    if (this.state.clickedMenuProps) {
      this.handleMenuClick(null, this.state.clickedMenuProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateListWidth);

    // TODO iScroll bug workaround
    this.menuProperties.forEach(props => {
      const targetElement = props.ref.current;
      targetElement.removeEventListener('tap', props.listener);
    });
  }
}

export default withTranslation()(withPopupDispatch(NaviHeader));
