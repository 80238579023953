import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DimDropdown from "./core/DimDropdown";

class InfoMenu extends Component {
  render() {
    return (
        <DimDropdown
            id={`dropdownInfo`}
            alt={`info menu`}
            className={`s-benefitinfo`}
            toggleClassName={`s-btn-benefitinfo dropdown-toggle`}>
          <span className={`dropdown-menu`}>
            {this.props.children}
          </span>
        </DimDropdown>
    );
  }
}

export default withTranslation()(InfoMenu);
