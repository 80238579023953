import React, {Component, Fragment} from 'react';
import Popup, {TYPE_2LINE, TYPE_4LINE, TYPE_CONFIRM, TYPE_LONGLINE, TYPE_PROGRESS_BAR, TYPE_TOAST, TYPE_WITH_LIST_BOX} from '../Popup';

/**
 * Attach Popup nodes to Page.
 *
 * Note that, only xxxPage components should use this when using withPopupComponents.
 */
const withPopupComponents = (PageComponent) => {
  return class withPopupComponents extends Component {
    render() {
      return (
          <Fragment>
            <PageComponent {...this.props}/>
            <Popup type={TYPE_PROGRESS_BAR}/>
            <Popup type={TYPE_LONGLINE}/>
            <Popup type={TYPE_2LINE}/>
            <Popup type={TYPE_4LINE}/>
            <Popup type={TYPE_CONFIRM}/>
            <Popup type={TYPE_TOAST}/>
            <Popup type={TYPE_WITH_LIST_BOX}/>
          </Fragment>

      );
    }
  };
};

export default withPopupComponents;