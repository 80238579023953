import React, {Component} from 'react';
import {getProperty} from '../utils/propertiesByDomain';
import {withTranslation} from 'react-i18next';
import withPopupDispatch from './hoc/withPopupDispatch';
import {autobind} from 'core-decorators';

@autobind
class DdcHiddenIframeForm extends Component {

  handleDdcMessage(e) {
    if (!e.origin || e.origin.indexOf(getProperty("ddcHost")) < 0 || !e.data) {
      return;
    }

    let data = JSON.parse(e.data);
    if (!data || data.Status !== true) {
      return;
    }

    if (this.props.onComplete) {
      this.props.onComplete(e, data.SessionId);
    }
  }

  render() {
    return (
        <div>
          <iframe name="ddc" style={{display: 'none'}}/>
          <form ref={this.props.parentRef}
                action={getProperty("ddcHost") + getProperty("ddcUri")}
                method="post"
                target="ddc">
            <input name="Bin" type="hidden" value={this.props.ddcBin || ""}/>
            <input name="JWT" type="hidden" value={this.props.ddcJwt || ""}/>
          </form>
        </div>
    );
  }

  componentDidMount() {
    window.addEventListener("message", this.handleDdcMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleDdcMessage);
  }
}

export default withTranslation()(withPopupDispatch(DdcHiddenIframeForm));