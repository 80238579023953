import payment_item_01 from '../images/payment_item_01.png';
import payment_item_03 from '../images/payment_item_03.png';
import payment_item_04 from '../images/payment_item_04.png';
import payment_item_06 from '../images/payment_item_06.png';
import payment_item_11 from '../images/payment_item_11.png';
import payment_item_12 from '../images/payment_item_12.png';
import payment_item_13 from '../images/payment_item_13.png';
import payment_item_14 from '../images/payment_item_14.png';
import payment_item_15 from '../images/payment_item_15.png';
import payment_item_16 from '../images/payment_item_16.png';
import payment_item_17 from '../images/payment_item_17.png';
import payment_item_21 from '../images/payment_item_21.png';
import payment_item_22 from '../images/payment_item_22.png';
import payment_item_23 from '../images/payment_item_23.png';
import payment_item_24 from '../images/payment_item_24.png';
import payment_item_25 from '../images/payment_item_25.png';
import payment_item_26 from '../images/payment_item_26.png';
import payment_item_34 from '../images/payment_item_34.png';
import payment_item_80 from '../images/payment_item_80.png';
import payment_item_001 from '../images/payment_item_001.png';
import payment_item_002 from '../images/payment_item_002.png';
import payment_item_003 from '../images/payment_item_003.png';
import payment_item_004 from '../images/payment_item_004.png';
import payment_item_033 from '../images/payment_item_033.png';
import {IN_VALUE, RU_VALUE, TYPE_PAYMETHODS, TYPE_PAYON, TYPE_SIGNON} from './globalConstants';
import {MSG_SHOULD_NEVER_HAPPEN} from './errorConstant';

/*
 * Buyer portal only let visa & master & amex & discover & jcb & diners add card.
 * It uses regex, which come from below link.
 *
 * TODO This logic is duplicate because BGW also validates it.
 * TODO Buyer portal does not tell user that "your card is not supported".
 * TODO Card checksum algorithm (Luhn algorithm) is not implemented even though it is in SRT.
 *
 * See https://www.regular-expressions.info/creditcard.html
 */
const CARD_TYPE_VISA = "001";
const CARD_TYPE_MASTER = "002";
const CARD_TYPE_AMEX = "003";
const CARD_TYPE_DISCOVER = "004";
const CARD_TYPE_JCB = "005";
const CARD_TYPE_DINERS = "006";

export const getCardTypeForGcc = (num) => {
  if (!num) {
    return null;
  }

  const isVisa = num.match(/^4[0-9]{12}(?:[0-9]{3})?$/g) !== null;
  if (isVisa) {
    return CARD_TYPE_VISA;
  }

  const isMaster = num.match(/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/g) !== null;
  if (isMaster) {
    return CARD_TYPE_MASTER;
  }

  const isAmex = num.match(/^3[47][0-9]{13}$/g) !== null;
  if (isAmex) {
    return CARD_TYPE_AMEX;
  }

  const isDiscover = num.match(/^6(?:011|5[0-9]{2})[0-9]{12}$/g) !== null;
  if (isDiscover) {
    return CARD_TYPE_DISCOVER;
  }

  const isJcb = num.match(/^(?:2131|1800|35\d{3})\d{11}$/g) !== null;
  if (isJcb) {
    return CARD_TYPE_JCB;
  }

  const isDiners = num.match(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/g) !== null;
  if (isDiners) {
    return CARD_TYPE_DINERS;
  }

  return null; // else
};

export const validateCardDate = (monthStr, yearStr) => {
  let currentDate = new Date();
  let month = Number(monthStr);
  let year = Number(yearStr);

  // check format
  if (!(currentDate.getFullYear() <= year && year <= 2199)) {
    return false;
  }

  // check format
  if (!(1 <= month && month <= 12)) {
    return false;
  }

  // check if cardDate is future
  let cardDate = new Date();
  cardDate.setFullYear(year);
  cardDate.setMonth(month - 1);

  return cardDate.getTime() - currentDate.getTime() >= 0;
};

export const validateCvv = (value) => {
  let len = value.length;
  let isValid = false;
  if (3 <= len && len <= 4) {
    isValid = true;
  }

  return isValid;
};

// Order matters !!!
const cardList = [
  {key: "Visa", src: payment_item_001, type: CARD_TYPE_VISA},
  {key: "MasterCard", src: payment_item_002, type: CARD_TYPE_MASTER},
  {key: "American Express", src: payment_item_003, type: CARD_TYPE_AMEX},
  {key: "Discover", src: payment_item_004, type: CARD_TYPE_DISCOVER},
  {key: "JCB", src: payment_item_23, type: CARD_TYPE_JCB},
  {key: "DINERS CLUB", src: payment_item_25, type: CARD_TYPE_DINERS},
  {key: "Visa Electron", src: payment_item_033, type: "033"},
  {key: "KEB", src: payment_item_01, type: "01"},
  {key: "LOTTE", src: payment_item_03, type: "03"},
  {key: "Hyundai", src: payment_item_04, type: "04"},
  {key: "KB", src: payment_item_06, type: "06"},
  {key: "BC", src: payment_item_11, type: "11"},
  {key: "Samsung", src: payment_item_12, type: "12"},
  {key: "LG", src: payment_item_13, type: "13"},
  {key: "Shinhan", src: payment_item_14, type: "14"},
  {key: "INICIS", src: payment_item_15, type: "15"},
  {key: "NH", src: payment_item_16, type: "16"},
  {key: "Hana", src: payment_item_17, type: "17"},
  {key: "VISA", src: payment_item_21, type: "21"},
  {key: "MasterCard", src: payment_item_22, type: "22"},
  {key: "JCB", src: payment_item_23, type: "23"},
  {key: "AMEX", src: payment_item_24, type: "24"},
  {key: "Diners", src: payment_item_25, type: "25"},
  {key: "UnionPay", src: payment_item_26, type: "26"},
  {key: "Hana", src: payment_item_34, type: "34"},
  {key: "Samsung", src: payment_item_80, type: "80"}
];

export const getCardByType = (cardType) => {
  return cardList.find(card => card.type === cardType);
};

// Note that, if value of the key is same (ex. visa and VISA, or MasterCard), it will never be chosen
export const getCardByKey = (cardKey) => {
  return cardList.find(card => card.key.toUpperCase() === cardKey.toUpperCase());
}

export const isVaultAvailableCountry = (country) => {
  if (!country) {
    throw new Error(MSG_SHOULD_NEVER_HAPPEN);
  }

  return !(country === RU_VALUE || country === IN_VALUE)
}

export const valueByCardRegisterScenarioType = (scenarioType, paymethodsUrl, payonUrl, signonUrl) => {
  if (paymethodsUrl && scenarioType === TYPE_PAYMETHODS) {
    return paymethodsUrl;
  } else if (payonUrl && scenarioType === TYPE_PAYON) {
    return payonUrl;
  } else if (signonUrl && scenarioType === TYPE_SIGNON) {
    return signonUrl;
  } else {
    throw new Error(MSG_SHOULD_NEVER_HAPPEN);
  }
}