import React, {Component} from 'react';
import {Fade} from 'react-bootstrap';

class DimDropdownMenu extends Component {
  render() {
    let selected = this.props.selected;
    let style = selected ? {display: "block"} : {display: "none"};
    return (
        <Fade in={selected} style={style}>
          {this.props.children}
        </Fade>
    );
  }
}

export default DimDropdownMenu;
