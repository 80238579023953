import React, {Component} from 'react';
import logo from '../images/m_logo.png';
import {URL_AUTH_SIGN_IN_PAGE, URL_INDEX_PAGE} from '../utils/urlConstant';

/**
 * Show Samsung Checkout logo on left upper corner
 */
class Logo extends Component {
  render() {
    let href = this.props.authenticated ? URL_INDEX_PAGE : URL_AUTH_SIGN_IN_PAGE;

    return (
        <h1 className={`s-logo`}>
          <a href={href}>
            <img src={logo} alt={`Samsung Checkout on TV`}/>
          </a>
        </h1>
    );
  }
}

export default Logo;
