import React, {Component} from 'react';

class Button extends Component {
  render() {
    let btnClassName = this.props.disabled ? "s-btn-default disable" : "s-btn-default";
    let disabled = this.props.disabled ? "disabled" : "";

    return (
        <div className={this.props.className}>
          <button type={`button`}
                  className={btnClassName}
                  disabled={disabled}
                  onClick={this.props.onClick}>
            {this.props.value}
          </button>
        </div>);
  }
}

export default Button;
