import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'core-js/features/array/from'; // Arrays.from polly fill
import 'core-js/features/array/find'; // Arrays.from polly fill
import 'core-js/features/object/is'; // Object.is polly fill
import 'core-js/features/string/repeat'; // String.repeat polly fill
import './styles/slick.css';
import './styles/remodal.css';
import './styles/bootstrap.min.css';
import './styles/common.css';
import App from './components/App';
import {Provider} from 'react-redux';
import store from './ducks/store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div/>}>
          <App/>
        </Suspense>
      </BrowserRouter>
    </Provider>, document.getElementById('root'));
serviceWorker.unregister();