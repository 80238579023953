import React, {Component, Fragment} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import SContainer from '../SContainer';
import Footer from '../Footer';
import Button from '../Button';
import Legal from '../Legal';
import CardNumberInput from '../CardNumberInput';
import CardExpirationDateInput from '../CardExpirationDateInput';
import CardCvvInput from '../CardCvvInput';
import ZipCodeInput from '../ZipCodeInput';
import {
  URL_AUTH_SIGN_IN_PAGE,
  URL_PAYMETHODS_CHANGE_STATE_API,
  URL_PAYMETHODS_GCC_REGISTER_API,
  URL_PAYMETHODS_LIST_VAULT_CARD_API,
  URL_PAYMETHODS_SUCCESS_PAGE,
  URL_PAYMETHODS_ZIP_CODE_CHECK_API,
  URL_PAYON_CARD_REGISTER_API,
  URL_PAYON_CHANGE_STATE_API,
  URL_PAYON_LIST_VAULT_CARD_API,
  URL_PAYON_RESULT_SUCCESS_PAGE,
  URL_PAYON_ZIP_CODE_CHECK_API,
  URL_SIGNON_CHANGE_STATE_API,
  URL_SIGNON_GCC_REGISTER_API,
  URL_SIGNON_LIST_VAULT_CARD_API,
  URL_SIGNON_RESULT_SUCCESS_PAGE,
  URL_SIGNON_ZIP_CODE_CHECK_API
} from '../../utils/urlConstant';
import CardNickNameInput from '../CardNickNameInput';
import CardCpfInput from '../CardCpfInput';
import {
  getCardByKey,
  getCardTypeForGcc,
  isVaultAvailableCountry,
  validateCardDate,
  validateCvv,
  valueByCardRegisterScenarioType
} from '../../utils/cardUtils';
import {
  ERR_CODE_AUTH_CODE_EXPIRED,
  ERR_CODE_DO_SCA,
  ERR_CODE_DO_SCA_ADYEN,
  ERR_CODE_SUCCESS,
  MSG_SHOULD_NEVER_HAPPEN
} from '../../utils/errorConstant';
import {
  BR_VALUE,
  KR_VALUE,
  PAYMETHOD_GCC,
  PAYMETHOD_KCC,
  QS_AUTH_CODE,
  QS_COUNTRY,
  SAMSUNG_CHECKOUT,
  TYPE_PAYMETHODS,
  TYPE_PAYON,
  TYPE_SIGNON,
  US_VALUE
} from '../../utils/globalConstants';
import Form from '../Form';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from "../hoc/withPopupDispatch";
import {errorCode2Msg} from "../../translations/i18n-helper";
import {createReturnUrlParam, getParams} from '../../utils/queryUtil';
import DdcHiddenIframeForm from '../DdcHiddenIframeForm';
import {createJscSessionId} from '../../utils/jscUtils';
import ScaIframeForm from '../ScaIframeForm';
import {activateDim, deactivateDim} from '../../ducks/dimAction';
import {connect} from 'react-redux';
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';
import StateList from '../StateList';
import {getBrowserInfo, scaAdyenRequest} from '../../utils/adyenUtils';

const EXPIRE_MONTH_TAG_NAME = "cardExpirationMonth";
const EXPIRE_YEAR_TAG_NAME = "cardExpirationYear";

@autobind
class GccRegPage extends Component {
  constructor(props) {
    super(props);

    // Payon 시나리오에서는 PAYMETHOD_KCC 등록이 불가능하기에 방어 로직 추가
    if (Object.is(this.props.type, TYPE_PAYON) && Object.is(this.props.userCountry, KR_VALUE)) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    this.initialState = {
      vaultCardId: null,
      vaultCard: null,

      cardNumberInputChecked: false,
      cardAccountFirst6Number: "",
      cardAccountLast4Number: "",
      cardNumber: "",
      cardType: "",

      cardExpirationDateChecked: false,
      cardExpireMonth: "",
      cardExpireYear: "",

      cardCvvChecked: false,
      cardCvv: "",

      zipCodeChecked: !Object.is(this.props.userCountry, US_VALUE),
      zipCode: "",
      zipCodeLoading: false,
      state: "",
      city: "",
      additionalStateTaxChecked: !this.props.isCountryAdditionalTaxState,
      stateList: [],

      cardNickNameChecked: !Object.is(this.props.userCountry, BR_VALUE),
      cardNickName: "",

      cardCpfChecked: !Object.is(this.props.userCountry, BR_VALUE),
      cpf: "",

      ddcSessionId: null,
      cardNumberForDdc: null,
      jscSessionId: this.props.isSca ? createJscSessionId() : null,
      showScaIframe: false,
      scaJwt: "",

      isAdyen: false,
    };

    this.state = JSON.parse(JSON.stringify(this.initialState));

    this.formRef = React.createRef();
    this.cardTypeRef = React.createRef();
    this.lastCardNumRef = React.createRef();
    this.cardIdRef = React.createRef();
    this.cardNumberInputRef = null; // Get Ref from the child as callback style

    this.ddcFormRef = React.createRef();
    this.scaFormRef = React.createRef();
  }

  initState() {
    this.setState(JSON.parse(JSON.stringify(this.initialState)));
  }

  setPaymethod() {
    if (this.props.type === TYPE_PAYON || this.props.type === TYPE_PAYMETHODS || this.props.type === TYPE_SIGNON) { // Payon is only GCC
      return Object.is(this.props.userCountry, KR_VALUE) ? PAYMETHOD_KCC : PAYMETHOD_GCC;
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
  }

  setActionUrl() {
    return valueByCardRegisterScenarioType(this.props.type,
        this.props.returnUrl ? URL_PAYMETHODS_SUCCESS_PAGE + "?" + createReturnUrlParam(this.props.returnUrl) : URL_PAYMETHODS_SUCCESS_PAGE,
        URL_PAYON_RESULT_SUCCESS_PAGE,
        URL_SIGNON_RESULT_SUCCESS_PAGE);
  }

  handleScaComplete(responseJson) {
    this.setState({showScaIframe: false}, () => {
      this.props.deactivateDim();

      if (!Object.is(responseJson.status, ERR_CODE_SUCCESS)) {
        this.props.showPopup2Line(errorCode2Msg(this.props.t, responseJson.status), null, () => window.location.reload());
        return;
      }

      this.postToSuccessView(responseJson.cardType, responseJson.lastCardNum);
    });
  }

  exitSca() {
    this.props.deactivateDim();
    this.props.hideAnyPopup();
    this.setState({showScaIframe: false, isAdyen: false});
  }

  triggerSca(scaJwt) {
    this.props.activateDim(null, true);
    this.setState({showScaIframe: true, scaJwt: scaJwt}, () => this.scaFormRef.current.submit()); // will trigger handleScaComplete()
  }

  postToSuccessView(cardType, lastCardNum) {
    this.lastCardNumRef.current.value = lastCardNum;
    this.cardTypeRef.current.value = cardType;
    this.formRef.current.submit();
  }

  registerCard() {
    this.props.showProgressBar();

    this.initState();

    const registerCardApiUrl = valueByCardRegisterScenarioType(this.props.type,
        URL_PAYMETHODS_GCC_REGISTER_API,
        URL_PAYON_CARD_REGISTER_API,
        URL_SIGNON_GCC_REGISTER_API);

    httpClient
        .post(registerCardApiUrl, {
          authCode: this.props.authCode,
          cardType: this.state.cardType,
          state: this.state.state,
          city: this.state.city,
          cardNumber: this.state.cardNumber,
          cardAccountFirst6Number: this.state.cardAccountFirst6Number,
          cardAccountLast4Number: this.state.cardAccountLast4Number,
          vaultCardId: this.state.vaultCardId,
          cardExpirationMonth: this.state.cardExpireMonth,
          cardExpirationYear: this.state.cardExpireYear,
          cardCVV: this.state.cardCvv,
          brand: this.state.brand,
          zipCode: this.state.zipCode,
          cardNickName: this.state.cardNickName,
          CPF: this.state.cpf,
          ddcSessionId: this.state.ddcSessionId,
          jscSessionId: this.state.jscSessionId,
          browser: getBrowserInfo(),
          origin: window.location.origin
        })
        .then(response => {
          this.props.hideAnyPopup();

          const data = response.data;
          this.cardIdRef.current.value = data.cardId;

          if (data.status === ERR_CODE_DO_SCA_ADYEN) {
            this.setState({isAdyen: true, showScaIframe: true}, () => {
              this.triggerScaAdyen(data);
            })
          }
          else if (data.status === ERR_CODE_DO_SCA) {
            this.triggerSca(data.scaJwt);
            return;
          } else if (data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          if (data.status !== ERR_CODE_DO_SCA_ADYEN) {
            this.postToSuccessView(data.cardType, data.lastCardNum);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error))

  }

  triggerScaAdyen(data) {
    this.props.activateDim(null, true);
    const cardId = data.cardId;
    scaAdyenRequest(
        this.props,
        data.scaAdyen,
        cardId,
        () => {
          this.postToSuccessView(data.cardType, data.lastCardNum)
        },
        () => {
          this.exitSca()
        },
        null //not cashon
    );
  }

  handleDdcComplete(e, ddcSessionId) {
    this.setState({ddcSessionId: ddcSessionId}, () => {
      this.props.hideAnyPopup();
      if (this.cardNumberInputRef) {
        this.cardNumberInputRef.current.focus();
      }
    });
  }

  triggerDdc() {
    let cardNumberFirst6 = this.state.cardNumber.substring(0, 6);
    if (this.props.isSca && this.state.cardNumber.length >= 6 && (!this.state.cardNumberForDdc || this.state.cardNumberForDdc.indexOf(cardNumberFirst6) !== 0)) {
      this.props.showProgressBar();
      this.setState({cardNumberForDdc: cardNumberFirst6}, () => this.ddcFormRef.current.submit()); // will trigger above handleDdcComplete()
    }
  }

  handleRegisterButtonClick(e) {
    e.preventDefault();
    this.registerCard();
  }

  handleStateChange(state) {
    this.setState({
      additionalStateTaxChecked: !!state,
      state: state
    });
  }

  handleCardCpfChange(e) {
    e.preventDefault();

    let newValue = e.target.value.replace(/\D/g, "");
    let isValid = newValue.length >= 1;
    this.setState({
      cpf: newValue,
      cardCpfChecked: isValid
    });
  }

  handleCardNickNameChange(e) {
    e.preventDefault();

    let newValue = e.target.value.replace(/[0-9]/g, "");
    let isValid = newValue.length >= 1;
    this.setState({
      cardNickName: newValue,
      cardNickNameChecked: isValid
    });
  }

  setZipCodeProperties(state, city, isValid) {
    this.setState({
      state: state,
      city: city,
      zipCodeChecked: isValid
    });
  }

  handleZipCodeChange(e, callbackToggleWarningStatus) {
    e.preventDefault();

    // filter
    let newValue = e.target.value.replace(/\D/g, "");
    this.setState({zipCode: newValue});

    // init
    callbackToggleWarningStatus(false);

    // greater or equal to 5 length zip code can call api
    if (newValue.length < 5) {
      return;
    }

    this.setState({zipCodeLoading: true});

    const zipApiUrl = valueByCardRegisterScenarioType(this.props.type,
        URL_PAYMETHODS_ZIP_CODE_CHECK_API,
        URL_PAYON_ZIP_CODE_CHECK_API,
        URL_SIGNON_ZIP_CODE_CHECK_API);

    httpClient
        .post(zipApiUrl, {
          authCode: this.props.authCode,
          zpcd: newValue
        })
        .then(response => {
          this.setState({zipCodeLoading: false});
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.setZipCodeProperties("", "", false);
            callbackToggleWarningStatus(true);
            return;
          }

          let data = response.data;
          this.setZipCodeProperties(data.state, data.city, true);
          callbackToggleWarningStatus(false);
        })
        .catch(error => {
          this.setState({zipCodeLoading: false});
          this.setZipCodeProperties("", "", false);
          callbackToggleWarningStatus(true);
          defaultErrorHandler(this.props, error);
        });
  }

  handleCardCvvChange(e) {
    e.preventDefault();
    let newValue = e.target.value.replace(/\D/g, "");
    let isValid = validateCvv(newValue);
    this.setState({
      cardCvv: newValue,
      cardCvvChecked: isValid
    });
  }

  formatMonthValue(e) {
    e.preventDefault();
    if (e.target.name === EXPIRE_MONTH_TAG_NAME) {
      let value = e.target.value;
      if (value.length === 1 && value !== "0") {
        this.setState({
          cardExpireMonth: "0" + value
        });
      }
    }
  }

  handleCardExpirationDateChange(e) {
    e.preventDefault();

    let month;
    let year;
    if (e.target.name === EXPIRE_MONTH_TAG_NAME) {
      month = e.target.value.replace(/\D/g, "");
      year = this.state.cardExpireYear;
    } else if (e.target.name === EXPIRE_YEAR_TAG_NAME) {
      month = this.state.cardExpireMonth;
      year = e.target.value.replace(/\D/g, "");
    }

    let isValid = validateCardDate(month, year);
    this.setState({
      cardExpireMonth: month,
      cardExpireYear: year,
      cardExpirationDateChecked: isValid
    });
  }

  handleCardNumberChange(e) {
    e.preventDefault();

    let newValue = e.target.value.replace(/\D/g, "");
    this.setState({
      cardNumberInputChecked: getCardTypeForGcc(newValue) !== null,
      cardNumber: newValue,
      cardType: getCardTypeForGcc(newValue) || ""
    });
  }

  fetchStateList(successCallback) {
    const fetchStateUrl = valueByCardRegisterScenarioType(this.props.type,
        URL_PAYMETHODS_CHANGE_STATE_API + `?${QS_COUNTRY}=${this.props.userCountry}`,
        URL_PAYON_CHANGE_STATE_API + `?${QS_COUNTRY}=${this.props.userCountry}&${QS_AUTH_CODE}=${this.props.authCode}`,
        URL_SIGNON_CHANGE_STATE_API + `?${QS_COUNTRY}=${this.props.userCountry}`);

    httpClient
        .get(fetchStateUrl)
        .then(response => {
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            successCallback(false);
            return;
          }

          this.setState({stateList: response.data.stateList}, () => successCallback(true));
        })
        .catch((error) => {
          defaultErrorHandler(this.props, error);
          successCallback(false);
        });
  }

  fetchVaultCardList(vaultCardId, successCallback) {
    this.props.showProgressBar();

    const fetchVaultCardUrl = valueByCardRegisterScenarioType(this.props.type,
        URL_PAYMETHODS_LIST_VAULT_CARD_API,
        URL_PAYON_LIST_VAULT_CARD_API + `?${QS_AUTH_CODE}=${this.props.authCode}`,
        URL_SIGNON_LIST_VAULT_CARD_API);

    httpClient
        .get(fetchVaultCardUrl)
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            successCallback(false);
            return;
          }

          if (!response.data.vaultCardList || response.data.vaultCardList.length <= 0) {
            successCallback(true);
            return;
          }

          const vaultCard = response.data.vaultCardList.find(vaultCard => vaultCard.vaultCardId === vaultCardId);
          const {cardNoLength} = vaultCard;
          const {first6, last4, brand, month, year} = vaultCard.cardInfo;
          const card = getCardByKey(brand);

          this.setState({
            vaultCardId: vaultCardId,
            vaultCard: vaultCard,

            cardNumberInputChecked: true,
            cardNumber: first6 + "*".repeat(cardNoLength - first6.length - last4.length) + last4,
            cardAccountFirst6Number: first6,
            cardAccountLast4Number: last4,
            cardType: card ? card.type : "",
            brand: brand,

            cardExpirationDateChecked: true,
            cardExpireMonth: month,
            cardExpireYear: year,
          }, () => successCallback(true));
        })
        .catch((error) => {
          defaultErrorHandler(this.props, error);
          successCallback(false);
        });
  }

  showVaultWarningPopup(e) {
    e.stopPropagation();
    e.target.blur();

    this.props.showPopupConfirm(
        this.props.t('TV_SID_BILLING_CBAUG_CONFIRM_MSG_CONTINUE_PROCESS'),
        () => {
          this.initState();
          this.props.hideAnyPopup();
        });
  }

  renderVaultTerms() {
    return (<div className="form-group s-legal">
          <p>
            <a href="#"
               onClick={() => this.props.showPopupLongLineWithTitleAndScroll(
                   this.props.t('TV_SID_LEGAL_CBNOV_CHECKOUT_CREDIT_CARD_TITLE'),
                   this.props.t('TV_SID_LEGAL_CBNOV_CHECKOUT_CREDIT_CARD_LEGAL_TERM'))}>
              <Trans i18nKey="TV_SID_BILLING_MIX_CBAUG_HAVE_READ_AGREE_TO">
                I have read and agree to the <span className={`s-vaultcard-terms-link`}>{{A: this.props.t('TV_SID_LEGAL_CBNOV_CHECKOUT_CREDIT_CARD_TITLE')}}</span>.
              </Trans>
            </a>
          </p>
        </div>);
  }

  render() {
    let buttonClickDisabled = true;
    if (this.state.cardNumberInputChecked
        && this.state.cardExpirationDateChecked
        && this.state.cardCvvChecked
        && this.state.zipCodeChecked
        && this.state.cardNickNameChecked
        && this.state.cardCpfChecked
        && this.state.additionalStateTaxChecked) {
      buttonClickDisabled = false;
    }

    return (<>
      <PageContainer
          hidden={true}
          showNav={this.props.type === TYPE_PAYMETHODS}
          showSubHeader={true}
          url={this.props.url}
          isSubHeaderBackConfirm={this.props.type === TYPE_PAYMETHODS}
          ssoAccountId={this.props.ssoAccountId}
          authenticated={this.props.authenticated}
          subHeaderText={this.props.type === TYPE_PAYMETHODS ? this.props.t('COM_SID_CREDIT_DEBIT_CARD') : SAMSUNG_CHECKOUT}>
        <SContainer>
          <Form name={`cardForm`}>
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s-addmethod-section`}>
                    <CardNumberInput
                        onInit={(ref) => this.cardNumberInputRef = ref}
                        name={`cardNumber`}
                        tabIndex={`1`}
                        value={this.state.cardNumber}
                        isValid={this.state.cardNumberInputChecked}
                        onChange={this.handleCardNumberChange}
                        onFocus={this.state.vaultCardId ? this.showVaultWarningPopup : undefined}
                        onBlur={this.triggerDdc}/>
                    <CardExpirationDateInput
                        name={[EXPIRE_MONTH_TAG_NAME, EXPIRE_YEAR_TAG_NAME]}
                        tabIndex={[`2`, `3`]}
                        monthValue={this.state.cardExpireMonth}
                        yearValue={this.state.cardExpireYear}
                        isValid={this.state.cardExpirationDateChecked}
                        onChange={this.handleCardExpirationDateChange}
                        onFocus={this.state.vaultCardId ? this.showVaultWarningPopup : undefined}
                        onBlur={this.formatMonthValue}/>
                    <CardCvvInput
                        name={`cardCVV`}
                        tabIndex={`4`}
                        value={this.state.cardCvv}
                        isValid={this.state.cardCvvChecked}
                        onChange={this.handleCardCvvChange}/>
                    {Object.is(this.props.userCountry, US_VALUE) && <ZipCodeInput
                        name={`zipCode`}
                        tabIndex={`5`}
                        value={this.state.zipCode}
                        isValid={this.state.zipCodeChecked}
                        loading={this.state.zipCodeLoading}
                        onChange={this.handleZipCodeChange}/>}
                    {Object.is(this.props.userCountry, BR_VALUE) && <CardNickNameInput
                        name={`cardNickName`}
                        tabIndex={`5`}
                        value={this.state.cardNickName}
                        isValid={this.state.cardNickNameChecked}
                        onChange={this.handleCardNickNameChange}/>}
                    {Object.is(this.props.userCountry, BR_VALUE) && <CardCpfInput
                        name={`CPF`}
                        tabIndex={`6`}
                        value={this.state.cpf}
                        isValid={this.state.cardCpfChecked}
                        onChange={this.handleCardCpfChange}/>}
                    {this.props.isCountryAdditionalTaxState && <StateList
                        value={this.state.state}
                        onChange={this.handleStateChange}
                        stateList={this.state.stateList}
                        title={this.props.t('TV_SID_BILLING_CBAUG_PROVICE_TERRITORY')}
                        defaultLabel={this.props.t('TV_SID_BILLING_CBAUG_PROVICE_TERRITORY')}/>}
                    {isVaultAvailableCountry(this.props.userCountry) && this.renderVaultTerms()}
                    <Button
                        disabled={buttonClickDisabled}
                        className={`one_btn_wrapping`}
                        value={this.props.t('SID_REGISTER')}
                        onClick={this.handleRegisterButtonClick}/>
                    <Legal ssoAccountId={this.props.ssoAccountId}/>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <Form method={`GET`} action={this.setActionUrl()} ref={this.formRef}>
            <input type={`hidden`} name={`cardType`} ref={this.cardTypeRef}/>
            <input type={`hidden`} name={`lastCardNum`} ref={this.lastCardNumRef}/>
            <input type={`hidden`} name={`paymethod`} value={this.setPaymethod() || ""}/>
            <input type={`hidden`} name={`authCode`} value={this.props.authCode || ""}/>
            <input type={`hidden`} name={`cardId`} ref={this.cardIdRef}/>
          </Form>
          <DdcHiddenIframeForm
              parentRef={this.ddcFormRef}
              onComplete={this.handleDdcComplete}
              ddcBin={this.state.cardNumberForDdc}
              ddcJwt={this.props.ddcJwt}/>
          <ScaIframeForm
              parentRef={this.scaFormRef}
              scaJwt={this.state.scaJwt}
              isAdyen={this.state.isAdyen}
              show={this.state.showScaIframe}
              onClick={this.exitSca}
              onComplete={this.handleScaComplete}/>
        </SContainer>
        <Footer/>
      </PageContainer>

    </>);
  }

  componentDidMount() {
    this.props.showProgressBar(); // To prevent input until componentDidMount is finished

    new Promise((resolve, reject) => {
      if (this.props.isCountryAdditionalTaxState) {
        this.fetchStateList((returnValue) => returnValue ? resolve() : reject());
      } else {
        resolve();
      }
    }).then(() => {
      return new Promise((resolve, reject) => {
        const vaultCardId = getParams(window.location.search).vaultCardId;
        if (vaultCardId && isVaultAvailableCountry(this.props.userCountry)) {
          this.fetchVaultCardList(vaultCardId, (returnValue) => returnValue ? resolve() : reject());
        } else {
          resolve();
        }
      })
    }).then(() => {
      this.props.hideAnyPopup();
    }).catch((error) => console.error(MSG_SHOULD_NEVER_HAPPEN, error))

    if (this.props.type === TYPE_PAYON) {
      setTimeout(() => {
        this.props.showPopup2Line(errorCode2Msg(this.props.t, ERR_CODE_AUTH_CODE_EXPIRED), URL_AUTH_SIGN_IN_PAGE);
      }, this.props.authCodeExpireTime * 1000);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activateDim: (obj, disableTouch) => {
      dispatch(activateDim(obj, disableTouch));
    }, deactivateDim: () => {
      dispatch(deactivateDim());
    }
  };
}

export default connect(null, mapDispatchToProps)(withPopupComponents(withTranslation()(withPopupDispatch(GccRegPage))));
