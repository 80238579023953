import React, {Component} from 'react';
import {autobind} from 'core-decorators';

@autobind
class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showX: false,
      showOnX: false
    };

    this.ref = React.createRef();
  }

  clearAll(e) {
    if (this.state.showOnX !== true) {
      return;
    }

    this.setState({showX: false, showOnX: false});
    e.target.value = "";
    this.props.onChange(e);
  }

  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }

    this.clearAll(e);
  }

  handleTouchStart(e) {
    if (this.props.onTouchStart) {
      this.props.onTouchStart(e);
    }

    this.clearAll(e);
  }

  setShowOnX(e) {
    if (this.state.showX !== true) {
      return;
    }

    let ixOnX = false;
    let tag = this.ref.current;

    // if pointer is left enough
    if (tag.offsetWidth - 40 < e.clientX - tag.getBoundingClientRect().left) {
      ixOnX = true;
    }

    this.setState({showOnX: ixOnX});
  }

  setShowX() {
    this.setState({showX: !this.props.disabled ? this.isShowX(this.props.value) : false});
  }

  isShowX(value) {
    return value && value.length >= 1;
  }

  render() {
    let className = "s-cleanable";

    if (this.state.showX) {
      className += " x";
    }

    if (this.state.showOnX) {
      className += " onX";
    }

    let wrapperClassName = "s-input";

    if (this.props.warning) {
      wrapperClassName += " s_error_input";
    }

    return (
        <span className={wrapperClassName}>
          <input
              ref={this.ref}
              tabIndex={this.props.tabIndex}
              type={this.props.type}
              className={className}
              name={this.props.name}
              min={this.props.min}
              max={this.props.max}
              maxLength={this.props.maxLength}
              value={this.props.value}
              placeholder={this.props.placeholder}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              onChange={this.props.onChange}
              onInput={this.setShowX}
              onMouseMove={this.setShowOnX}
              onTouchStart={this.handleTouchStart}
              onClick={this.handleClick}/>
          {this.props.children}
        </span>
    );
  }

  componentDidMount() {
    if (this.props.onInit) {
      this.props.onInit(this.ref);
    }

    this.setShowX();
  }

  componentDidUpdate(prevProps, prevState, _) {
    if (this.props.value !== prevProps.value) {
      this.setShowX();
    }
  }

}

export default Input;
