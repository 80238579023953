import React, {Component, Fragment} from 'react';
import Slider from 'react-slick';
import {withTranslation} from 'react-i18next';
import payment_item_refresh from '../images/payment_item_refresh.png';
import payment_item_add from '../images/payment_item_add.png';
import payment_bg_card from '../images/payment_bg_card.png';
import inicis_logo from '../images/KG Inicis_logo_en.png';
import payment_item_paypal from '../images/payment_item_paypal.png';
import {
  URL_PAYMETHODS_CHANGE_STATE_PAGE,
  URL_PAYMETHODS_CHANGE_ZIP_CODE_PAGE,
  URL_PAYMETHODS_REGISTER_KCC_PAGE
} from '../utils/urlConstant';
import {getCardByType} from '../utils/cardUtils';
import {PAYMETHOD_GCC, PAYMETHOD_KCC, PAYMETHOD_PP, PAYMETHOD_TOSS} from '../utils/globalConstants';
import {MSG_SHOULD_NEVER_HAPPEN} from '../utils/errorConstant';
import withPopupDispatch from './hoc/withPopupDispatch';
import {autobind} from 'core-decorators';
import {tossAddPaymentMethod} from '../utils/tossPaymentUtils';
import Modal from 'react-modal';

@autobind
class PaymethodsSlider extends Component {
  constructor(props) {
    super(props);

    this.SubSliderRef = React.createRef();
  }

  renderCardSizeButton(clickHandler, buttonText) {
    return (
        <span className={`s-btn-item btn-long`}>
          <button type={`button`} className={`s-btn s-btn-default`} onClick={clickHandler}>
            {buttonText}
          </button>
        </span>
    );
  }

  renderCardHalfSizeButton(clickHandler, buttonText) {
    return (
        <span className={`s-btn-item`}>
          <button type={`button`} className={`s-btn s-btn-default`} onClick={clickHandler}>
            {buttonText}
          </button>
        </span>
    );
  }

  renderDefaultPaymentButton(cardInfo) {
    const onDefaultPaymethodCLick = () => {
      if (cardInfo.cardId && this.props.subSlider.onDefaultClick) {
        this.props.subSlider.onDefaultClick(cardInfo.cardId)
      }
    }

    const isDefaultCard = cardInfo.isDefaultCard;

    const isExpired = cardInfo.isExpired;
    return <>
      {
          !isExpired &&
          <div className={"s-checkbox"}>
            <input type={"checkbox"} onClick={onDefaultPaymethodCLick} disabled={isDefaultCard}
                   checked={isDefaultCard}/>
            <label
                onClick={isDefaultCard ? undefined : onDefaultPaymethodCLick}
                style={isDefaultCard ? {cursor: 'not-allowed'} : undefined}
            >
              {this.props.t('COM_SID_BILLING_WEB_CEAUG_DEFAULT_PAYMENT_METHOD')}
            </label>
          </div>
      }
    </>;
  }


  renderShowZipCodeOrStateOfCard(showZipCode, cardId, zipCode, state) {
    const jumpUrl = showZipCode
        ? URL_PAYMETHODS_CHANGE_ZIP_CODE_PAGE + "?cardId=" + cardId
        : URL_PAYMETHODS_CHANGE_STATE_PAGE + "?cardId=" + cardId;

    return (
        <div className="zipcode">
          {showZipCode ? (
              <span className="zipcode-font s-type-left">{this.props.t('COM_SID_ZIP_CODE')}: {zipCode}</span>
          ) : (
              <span className="zipcode-font s-type-left s-ellipsis-text-overflow">{state ? this.props.t('TV_SID_BILLING_MIX_CBAUG_PROVINCE_TERRITORY', {A: state}) : ""}</span>
          )}
          <span className="zipcode-font s-type-right">
            <a style={{color: "#555"}} onClick={() => window.location.assign(jumpUrl)}>
              {showZipCode ? (
                  <span>{this.props.t('TV_SID_BILLING_CAMAR_EDIT_ZIP_CODE')} &#x3009;</span>
              ) : (
                  <span>{this.props.t('TV_SID_BILLING_CBAUG_EDIT_PROVINCE_TERRITORY')} &#x3009;</span>
              )}
            </a>
          </span>
        </div>
    );
  }

  renderShowRelation(paymethodInfo) {
    const paymethod = paymethodInfo.paymethod;
    const outerDivClass = 's-recentorder-list s-des-linklist';
    const innerDivClass = 's-method-menu s-col-btnwrap';

    switch (paymethod) {
      case PAYMETHOD_GCC:
        if (paymethodInfo.creditCardInfo) {
          const isActive = !paymethodInfo.creditCardInfo.isExpired;
          const zipCode = paymethodInfo.creditCardInfo.zipCode;
          const cardId = paymethodInfo.creditCardInfo.cardId || "";
          const state = this.props.subSlider.stateList ? this.props.subSlider.stateList.find(state => state.code === paymethodInfo.creditCardInfo.state) : null;

          return (
            <div key={paymethod + cardId} className={outerDivClass}>
              {(isActive && zipCode && this.props.subSlider.showZipCode) &&
              this.renderShowZipCodeOrStateOfCard(true, cardId, zipCode, null)
              }
              {(isActive && this.props.subSlider.showState) &&
              this.renderShowZipCodeOrStateOfCard(false, cardId, null, state ? state.name : null)
              }
              <div className={innerDivClass}>
                {this.renderCardSizeButton(
                    () => this.props.subSlider.onDeleteClick(paymethod, cardId),
                    this.props.t('COM_SID_DELETE'))}
              </div>
            </div>
          )
        }
        break;
      case PAYMETHOD_KCC:
        if (paymethodInfo.creditCardInfo) {
          return (
              <div key={paymethod} className={outerDivClass}>
                <div className={innerDivClass}>
                  {this.renderCardHalfSizeButton(
                      //show popup before redirection
                      () => window.location.assign(URL_PAYMETHODS_REGISTER_KCC_PAGE),
                      this.props.t('SID_EDIT_KR_ED'))}
                  {this.renderCardHalfSizeButton(
                      () => this.props.subSlider.onDeleteClick(paymethod),
                      this.props.t('COM_SID_DELETE'))}
                </div>
              </div>
          )
        }
        break;
      case PAYMETHOD_TOSS:
        if (paymethodInfo.creditCardInfo) {
          const cardId = paymethodInfo.creditCardInfo.cardId || "";
          return (
            <div key={paymethod} className={outerDivClass}>
              <div className={innerDivClass}>
                {this.renderCardSizeButton(
                    () => this.props.subSlider.onDeleteClick(paymethod, cardId),
                    this.props.t('COM_SID_DELETE'))}
              </div>
            </div>
          )
        }
        break;
      case PAYMETHOD_PP:
        if (paymethodInfo.paypalInfo) {
          return (
              <div key={paymethod} className={outerDivClass}>
                <div className={innerDivClass}>
                  {this.renderCardSizeButton(
                      () => this.props.subSlider.onDeleteClick(paymethod),
                      this.props.t('COM_IDWS_MOIP_POPUP_EXIT_SIGN_OUT'))}
                </div>
              </div>
          )
        }
        break;
      default:
        throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
    return <></>;
  }

  //
  // show relational info (recent order) of the
  // current paymethod shown in master slider
  //
  renderShowRelations(paymethodsInfo) {
    return paymethodsInfo.map(paymethodInfo => this.renderShowRelation(paymethodInfo));
  }

  handleMainSliderChange(oldIndex, newIndex) {
    if (this.SubSliderRef.current) {
      this.SubSliderRef.current.slickGoTo(newIndex);
    }
  }

  showPaypal(paypalInfo) {
    return (
        <div key={`pm-paypal`} className={`s-pay-item`}>
          <div className={`s-card-cont`}>
            <img src={payment_item_paypal} alt={`paypal`}/>
            <span className={`s-card-text`}>{paypalInfo.paypalId}</span>
          </div>
        </div>
    );
  }

  renderProgressBar() {
    return (
        <Fragment>
          <div className={`s-loading-pop`}>
            <span className={`s-loading-spinner`}>LOADING</span>
          </div>
          <Modal isOpen={true} overlayClassName={`no-css`} className={`remodal-block remodal-wrapper`}/>
        </Fragment>
    );
  }

  confirmTossRegistration() {
    // Ask confirmation if not in subscription change flow and
    // If the user has registered Inicis card
    // Then ask to edit the existing card instead of registering new card
    if (!this.props.isSubscriptionChange && this.isInicisRegistered()){
      this.props.showPopupConfirm(
          this.props.t('COM_SID_BILLING_WEB_CENOV_TO_ADD_A_PAYMENT_METHOD_EDIT_THE_EXISTING_CARD'),
          this.navigateToInicisRegistrationPage.bind(this),
          this.startTossRegistration.bind(this),
          this.props.t('SID_EDIT_KR_ED'),
          this.props.t('DREAM_SPAY_BUTTON_IGNORE_64')
      );
    } else{
      this.startTossRegistration();
    }

  }

  isInicisRegistered(){
    const { paymethodsInfo } = this.props;
    const inicisData = paymethodsInfo.find(paymethodInfo => paymethodInfo.paymethod === PAYMETHOD_KCC);
    return !!inicisData?.creditCardInfo;
  }

  navigateToInicisRegistrationPage() {
    window.location.assign(URL_PAYMETHODS_REGISTER_KCC_PAGE);
  }

  startTossRegistration() {
    this.renderProgressBar();
    tossAddPaymentMethod(this.props)
  }

  showCreditCard(paymethodsInfo) {
    const cardInfo = paymethodsInfo.creditCardInfo;
    const isActive = !cardInfo.isExpired;
    const card = getCardByType(cardInfo.cardType);
    const cardLast4Number = cardInfo.cardAccountLast4Number ? cardInfo.cardAccountLast4Number : "xxxx";
    const cardlast4NumberStyle = cardLast4Number.replaceAll("*", "s"); //* in the last 4 digits are changed to "s" for styling

    const onCardClick = () => {
      if (isActive && cardInfo.cardId && this.props.onCreditCardClick) {
        this.props.onCreditCardClick(cardInfo.cardId)
      }
    };

    const isKCC = paymethodsInfo.paymethod === PAYMETHOD_KCC;
    const showDefaultButton = !this.props.isSubscriptionChange  &&
        (paymethodsInfo.paymethod === PAYMETHOD_GCC || paymethodsInfo.paymethod === PAYMETHOD_TOSS);

    return (
        <div key={`pm-credit-card`}
             className={`s-pay-item`}
             onClick={(e) => this.checkIsClick(e, onCardClick)}>
          <div className={`s-card-cont`} style={{opacity: isActive ? 1 : 0.3}}>
            <img src={payment_bg_card} alt={`card`}/>

            {isKCC &&
              <span className={`s-card-company-left`}>
                <img src={inicis_logo} alt="Inicis"/>
              </span>
            }

            {card &&
              <span className={`s-card-company`}>
                <img src={card.src} alt={card.key}/>
              </span>
            }

            <div className={`s-card-fullnumber`}>
              <span className={`s-card-number`}>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
              </span>
              <span className={`s-card-number`}>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
              </span>
              <span className={`s-card-number`}>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
                <span className={`s-cns`}>*</span>
              </span>
              <span className={`s-card-number`}>
                <span className={`s-cn` + cardlast4NumberStyle.charAt(0)}>{cardLast4Number.charAt(0)}</span>
                <span className={`s-cn` + cardlast4NumberStyle.charAt(1)}>{cardLast4Number.charAt(1)}</span>
                <span className={`s-cn` + cardlast4NumberStyle.charAt(2)}>{cardLast4Number.charAt(2)}</span>
                <span className={`s-cn` + cardlast4NumberStyle.charAt(3)}>{cardLast4Number.charAt(3)}</span>
              </span>
            </div>
          </div>
          {showDefaultButton && this.renderDefaultPaymentButton(cardInfo)}
        </div>
    );
  }

  showPaymethodCard(paymethodInfo) {
    switch (paymethodInfo.paymethod) {
      case PAYMETHOD_GCC:
      case PAYMETHOD_KCC:
        return this.showCreditCard(paymethodInfo)

      case PAYMETHOD_TOSS:
        if (paymethodInfo.creditCardInfo) {
          return this.showCreditCard(paymethodInfo);
        }
        break;

      case PAYMETHOD_PP:
        if (paymethodInfo.paypalInfo) {
          return this.showPaypal(paymethodInfo.paypalInfo);
        }
        break;

      default:
        throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
    return <></>;
  }

  showPaymethodCards(paymethodsInfo) {
    return paymethodsInfo.map(paymethodInfo => this.showPaymethodCard(paymethodInfo));
  }

  showAddCard() {
    return (
        <div className={`s-pay-item`}>
          <div className={`s-card-cont`} onClick={(e) =>
              this.checkIsClick(
                  e,
                  this.props.addCard.isKR
                      ? () => this.confirmTossRegistration()
                      : () => window.location.assign(this.props.addCard.urlOnClick))
          }>
            <img src={payment_item_add} alt={`add`}/>
            <span className={`s-card-text`}>
                {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_16')}
              </span>
          </div>
        </div>
    );
  }

  //for rendering add KCC(Inicis) card in main slider, if no KCC(Inicis) card is registered and KR user
  showAddInicisCard() {
    if(this.props.addCard.isKR && !this.isInicisRegistered()){
      return (
        <div key={`pm-inicis`} className={`s-pay-item`}>
          <div className={`s-card-cont`}>
            <img src={payment_bg_card} alt={`inicis`}/>
            <span className={`s-card-company-left`}>
              <img src={inicis_logo} alt="Inicis"/>
            </span>
          </div>
        </div>
      )
    } else {
      return null;
    } //intentional, returning html tag such as <></> will add  a new slider element
  }

  //for rendering "register button" below the add KCC(Inicis) card
  showAddInicisButton() {
    if(this.props.addCard.isKR && !this.isInicisRegistered()){
      return (
          <div key={"KCC_ADD_CARD"} className={"s-recentorder-list s-des-linklist"}>
            <div className={"s-method-menu s-col-btnwrap"}>
              {this.renderCardSizeButton(
                  () => window.location.assign(URL_PAYMETHODS_REGISTER_KCC_PAGE),
                  this.props.t('SID_REGISTER'))}
            </div>
          </div>
      )
    } else {
      return null;
    } //intentional, returning html tag such as <></> will add  a new slider element
  }

  showRefreshCard() {
    return (
        <div className={`s-pay-item`}>
          <div className={`s-card-cont`}>
            <a onClick={this.props.refreshCard.onRefresh}>
              <img src={payment_item_refresh} alt={`refresh`}/>
              <span className={`s-card-text-icon`}>Refresh</span>
              <span className={`s-card-text`}>
                {this.props.t('TV_SID_PAYMENT_UNABLE_LOAD')}<br/>
                {this.props.t('TV_SID_BILIING_SELELCT_TO_RELOAD')}
              </span>
            </a>
          </div>
        </div>
    );
  }

  renderSubSlider(paymethodSubSliderSettings) {
    return (
      <Slider {...paymethodSubSliderSettings} ref={this.SubSliderRef}>
        {!this.props.isSubscriptionChange && this.props.paymethodsInfo &&
          this.renderShowRelations(this.props.paymethodsInfo)
        }

        {/*add empty div for sub slider*/}
        {this.props.addCard.show &&
          <div/>
        }

        {!this.props.isSubscriptionChange &&
            this.showAddInicisButton()}
      </Slider>
    );
  }

  renderMasterSlider(paymethodSliderSettings) {
    return (
        <Slider {...paymethodSliderSettings}>
          {this.props.paymethodsInfo &&
            this.showPaymethodCards(this.props.paymethodsInfo)
          }
          {this.props.addCard.show &&
            this.showAddCard()
          }
          {!this.props.isSubscriptionChange &&
              this.showAddInicisCard(this.props.paymethodsInfo)
          }
        </Slider>
    );
  }

  //////////
  // These are to prevent false click event trigger when swiping
  //
  // This is needed because while swipe, img moves as mouse moves,
  // which browser think mouse has not moved, for only specific html element.
  checkMouseDown(e) {
    this.mouseDownX = e.screenX;
    this.mouseDownY = e.screenY;
  }

  checkMouseUp(e) {
    const distance = window.Math.pow(this.mouseDownY - e.screenY, 2) + window.Math.pow(this.mouseDownX - e.screenX, 2);
    this.approveMouseClick = distance <= 100;
  }

  checkIsClick(e, callback) {
    if (this.approveMouseClick) {
      callback();
    } else {
      this.approveMouseClick = true; // This is for, just in case code.
    }
  }

  ///////////

  render() {
    const paymethodSliderSettings = {
      speed: 500,
      arrows: true,
      swipe: false,
      centerMode: false,
      infinite: false,
      centerPadding: '60px',
      slidesToShow: 1,
      swipeToSlide: true,
      dots: true,
      beforeChange: this.handleMainSliderChange,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            swipe: true,
            centerMode: false,
            centerPadding: '27px',
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            swipe: true,
            centerMode: true,
            centerPadding: '47px',
          }
        },
        {
          settings: {
            arrows: false,
            breakpoint: 500,
            swipe: true,
            centerMode: true,
            centerPadding: '35px',
          }
        }
      ],
      className: this.props.masterSlider.className
    };

    // when get paymethods fail, show only refresh card
    if (this.props.refreshCard.show) {
      return <Slider {...paymethodSliderSettings}>{this.showRefreshCard()}</Slider>;
    }

    // or else
    const paymethodSubSliderSettings = {
      speed: 500,
      arrows: false,
      swipe: false,
      centerMode: false,
      infinite: false,
      slidesToShow: 1,
      swipeToSlide: false,
      dots: false,
      fade: true,
      className: "s-recentorder-list"
    };

    return (
        <div onMouseDown={this.checkMouseDown} onMouseUp={this.checkMouseUp}>
          {this.renderMasterSlider(paymethodSliderSettings)}
          {<div className={`container`}>
            <div className={`row`}>
              <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3`}>
                {this.renderSubSlider(paymethodSubSliderSettings)}
              </div>
            </div>
          </div>}
        </div>
    );
  }
}

export default withTranslation()(withPopupDispatch(PaymethodsSlider));
