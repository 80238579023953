import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {hidePopup} from '../ducks/popupAction';
import {MSG_SHOULD_NEVER_HAPPEN} from '../utils/errorConstant';
import {autobind} from 'core-decorators';

export const TYPE_PROGRESS_BAR = "progressBar";
export const TYPE_LONGLINE = "longLine";
export const TYPE_2LINE = "2line";
export const TYPE_4LINE = "4line";
export const TYPE_SCROLL = "scroll";
export const TYPE_CONFIRM = "confirmButton";
export const TYPE_CONFIRM_LONGLINE = "confirmLongline";

export const TYPE_TOAST = "toast";
export const TYPE_WITH_LIST_BOX = "listbox";

/**
 * Popup class
 *
 * Rule for this class is :
 * - Both this.props.url and this.props.yesCallback should not be truthy value
 * - If this.props.yesCallback exist, this.props.yesCallback function should call hideAnyPopup it self.
 * - this.props.noCallback is an optical callback for a "No" button of TYPE_CONFIRM.
 *
 * [Note] Don't use this directly !!! Use withPopupComponent.js HOC !!!
 */
@autobind
class Popup extends Component {
  constructor(props) {
    super(props);

    // init Modal
    Modal.setAppElement('#root');
  }

  renderProgressBar() {
    return (
        <Fragment>
          <div className={`s-loading-pop`}>
            <span className={`s-loading-spinner`}>LOADING</span>
          </div>
          <Modal isOpen={true} overlayClassName={`no-css`} className={`remodal-block remodal-wrapper`}/>
        </Fragment>
    );
  }

  renderButton() {
    if (this.props.yesCallback && this.props.url) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    if (this.props.type.indexOf(TYPE_CONFIRM) >= 0 || this.props.type.indexOf(TYPE_CONFIRM_LONGLINE) >= 0) {
      return (
          <div className={`remodal-btnarea s-col-btnwrap`}>
            <span className={`s-btn-item`}>
              <button type={`button`}
                      name={`remodal_btn`}
                      className={`s-btn s-btn-default remodal-confirm`}
                      onClick={(e) => {
                        if (!this.props.yesCallback) { // yesCallback should not be falsy value in confirm-popup
                          throw new Error(MSG_SHOULD_NEVER_HAPPEN);
                        }

                        e.preventDefault();
                        this.props.yesCallback();
                      }}>
                {this.props.yesText || this.props.t('COM_TV_SID_YES')}
              </button>
            </span>
            <span className={`s-btn-item`}>
              <button type={`button`}
                      name={`remodal_btn`}
                      className={`s-btn s-btn-default remodal-cancel`}
                      onClick={(e) => {
                        if (this.props.noCallback) {
                          e.preventDefault();
                          this.props.noCallback();
                          return;
                        }

                        e.preventDefault();
                        this.props.hideAnyPopup();
                      }}>
                {this.props.noText || this.props.t('COM_TV_SID_NO')}
              </button>
            </span>
          </div>
      );
    } else {
      return (
          <div className={`remodal-btnarea`}>
            <button type={`button`}
                    className={`s-btn s-btn-default`}
                    onClick={(e) => {
                      e.preventDefault();

                      if (this.props.yesCallback) {
                        this.props.yesCallback();
                        return;
                      }

                      if (this.props.url) {
                        window.location.assign(this.props.url); // end of react client
                      } else {
                        this.props.hideAnyPopup();
                      }
                    }}>
              {this.props.t('COM_SID_OK')}
            </button>
          </div>
      );
    }
  }

  render() {
    // when no popup or store popupType is not this popup
    if (this.props.type !== this.props.popupType || !this.props.isOpen) {
      return <span/>;
    }

    // when special for progressbar popup (no content with special overlay)
    if (this.props.type.indexOf(TYPE_PROGRESS_BAR) >= 0) {
      return this.renderProgressBar();
    }

    // when toast
    if (this.props.type.indexOf(TYPE_TOAST) >= 0) {
      setTimeout(() => this.props.hideAnyPopup(), 3000);

      return (
          <Fragment>
            <div className={`remodal-block remodal-overlay`}/>
            <Modal isOpen={true} overlayClassName={`no-css`} className={`remodal-block remodal-wrapper`}>
              <div className={`remodal remodal-is-initialized`} tabIndex={`-1`}>
                <div className={`remodal-type-toast`}>
                  <p className={`remodal-text`}>{this.props.text}</p>
                </div>
              </div>
            </Modal>
          </Fragment>
      );
    }

    // else
    let contentClassName = "remodal-contents";
    if (this.props.type.indexOf(TYPE_LONGLINE) >= 0 || this.props.type.indexOf(TYPE_CONFIRM_LONGLINE) >= 0) {
      contentClassName += " remodal-type-longline";
    } else if (this.props.type.indexOf(TYPE_2LINE) >= 0 || this.props.type.indexOf(TYPE_CONFIRM) >= 0) {
      contentClassName += " remodal-type-2line";
    } else if (this.props.type.indexOf(TYPE_4LINE) >= 0) {
      contentClassName += " remodal-type-4line";
    } else if (this.props.type.indexOf(TYPE_WITH_LIST_BOX) >= 0) {
      contentClassName += " remodal-type-withlistbox";
    } else if (this.props.type.indexOf(TYPE_SCROLL) >= 0) {
      contentClassName += " remodal-type-withlistbox";
    }

    const textAreaClassName = this.props.isScroll ? 'remodal-textarea remodal-textarea-t remodal-scroll' : 'remodal-textarea';

    return (
        <Fragment>
          <div className={`remodal-block remodal-overlay`}/>
          <Modal isOpen={true} overlayClassName={`no-css`} className={`remodal-block remodal-wrapper`}>
            <div className={`remodal remodal-is-initialized`} tabIndex={`-1`}>
              <div className={contentClassName}>
                <div className={textAreaClassName}>
                  <p className={`remodal-text`}>
                    {this.props.title && (<b>{this.props.title}<br/></b>)}
                    {this.props.text}
                  </p>
                </div>
                {this.props.type.indexOf(TYPE_WITH_LIST_BOX) >= 0 &&
                <div className={`remodal-contarea`}>
                  <ul className={`popup-with-listbox`}>
                    <li className={`remodal-text`}>{this.props.t('TV_SID_BILLING_SUBSCRIPTION_LIST')}</li>
                    {this.props.list && this.props.list.map(e => <li key={e} className={`remodal-text`}>{e}</li>)}
                  </ul>
                </div>
                }
                {this.renderButton()}
              </div>
            </div>
          </Modal>
        </Fragment>
    );
  }
}

function mapStoreStateToProps(store) {
  return {...store.popup};
}

function mapDispatchToProps(dispatch) {
  return {
    hideAnyPopup: () => {
      dispatch(hidePopup());
    }
  };
}

export default withTranslation()(connect(mapStoreStateToProps, mapDispatchToProps)(Popup));
