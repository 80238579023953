import React, {Component} from 'react';
import SContainer from '../SContainer';
import Footer from '../Footer';
import {withTranslation} from 'react-i18next';
import {URL_ORDERS_GET_INVOICES_API, URL_PAYMETHODS_GET_PAYMETHODS_API, URL_PAYMETHODS_REGISTER_GCC_PAGE, URL_PAYMETHODS_SUBSCRIPTION_UPDATE_API} from '../../utils/urlConstant';
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import {errorCode2Msg} from '../../translations/i18n-helper';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {PAYMETHOD_GCC} from '../../utils/globalConstants';
import PaymethodsSlider from '../PaymethodsSlider';
import {createReturnUrlParam} from '../../utils/queryUtil';
import {VIEW_TYPE_ACTIVE_SUBSCRIPTION} from './OrdersHistoryPage';
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

@autobind
class ChangeSubscriptionPaymethodPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRefreshCard: false,
      paymethodsInfo: [],
      cardIdForThisSubscription: null
    };
  }

  changeSubscriptionPaymethod(cardId) {
    this.props.showProgressBar();
    const subscriptionId = this.props.subscriptionId;
    const url = URL_PAYMETHODS_SUBSCRIPTION_UPDATE_API.replace(":id", subscriptionId);

    httpClient
        .post(url, {cardId: cardId})
        .then(response => {
          this.props.hideAnyPopup();

          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          window.location.assign(this.props.returnUrl);
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  showPopupIfNotSameCard(cardId) {
    if (Object.is(this.state.cardIdForThisSubscription, cardId)) { // If same card, no more process
      window.location.assign(this.props.returnUrl);
      return;
    }

    this.props.showPopupConfirm(this.props.t('TV_SID_BILLING_CAMAR_DO_WANT_CHANGE_PAY_METHOD_CARD'), () => this.changeSubscriptionPaymethod(cardId))
  }

  fetchSubscription() {
    this.props.showProgressBar();

    httpClient
        .post(URL_ORDERS_GET_INVOICES_API, {
          invoiceId: this.props.subscriptionId,
          viewType: VIEW_TYPE_ACTIVE_SUBSCRIPTION
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          const subscription = response.data.subscriptionInfo;
          if (subscription.length > 0 && subscription[0].creditCardInfo) {
            this.setState({cardIdForThisSubscription: subscription[0].creditCardInfo.cardId});
          } else {
            throw new Error(MSG_SHOULD_NEVER_HAPPEN);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  getGccPaymethods(successCallback) {
    this.setState({showRefreshCard: false});
    this.props.showProgressBar();

    httpClient
        .get(URL_PAYMETHODS_GET_PAYMETHODS_API)
        .then(response => {
          this.props.hideAnyPopup();

          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.setState({showRefreshCard: true});
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          let list = response.data.paymethodResVOList;
          this.setState({
            paymethodsInfo: list.filter(paymethod => Object.is(paymethod.paymethod, PAYMETHOD_GCC))
          });

          successCallback();
        })
        .catch(error => {
          this.setState({showRefreshCard: true});
          defaultErrorHandler(this.props, error);
        });
  }

  handleRefresh(e) {
    e.preventDefault();
    this.getGccPaymethods();
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={true}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('TV_SID_BILLING_CAMAR_CHANGE_PAY_CARD')}>
          <SContainer>
            <div className={`container`}>
              <div className={`s-section`}>
                <div className={`s-info-group`}>
                  <h3 className={`s-info-big-title s-type-center`}>
                    {this.props.t('TV_SID_BILLING_CAMAR_CHOOSE_CARD_SUBSCRIPTION')}
                  </h3>
                </div>
              </div>
              <PaymethodsSlider
                  refreshCard={{
                    show: this.state.showRefreshCard,
                    onRefresh: this.handleRefresh
                  }}
                  addCard={{
                    show: true, // Because This page will redirect to only GCC
                    urlOnClick: URL_PAYMETHODS_REGISTER_GCC_PAGE + "?" + createReturnUrlParam(window.location.pathname + window.location.search)
                  }}
                  paymethodsInfo={this.state.paymethodsInfo}
                  onCreditCardClick={(cardId) => this.showPopupIfNotSameCard(cardId)}
                  masterSlider={{className: "s-pay-slider padding-top-20"}}
                  subSlider={{show: false}}/>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.getGccPaymethods(this.fetchSubscription);
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(ChangeSubscriptionPaymethodPage)));