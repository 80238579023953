import React, {Component} from 'react';

class SHeader extends Component {
  render() {
    return (
        <header className={`s-header`}>
          {this.props.children}
        </header>
    );
  }
}

export default SHeader;
