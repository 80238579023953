import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DimDropdown from './core/DimDropdown';
import {logout} from '../../utils/authUtils';

/**
 * Show User Id and Logout button
 */
class UserMenu extends Component {
  render() {
    if (!this.props.authenticated) {
      return (<div className={`dropdown s-log`}/>);
    }

    return (
        <DimDropdown
            id={`dropdownLog`}
            alt={`USER MENU`}
            className={`s-log`}
            toggleClassName={`s-btn s-btn-log dropdown-toggle`}>
          <div className={`dropdown-menu dropdown-menu-right`}>
            <span className={`s-log-account`}>{this.props.ssoAccountId}</span>
            <button className={`s-btn s-btn-default`} onClick={logout.bind(this, null)}>
              {this.props.t('COM_IDWS_MOIP_POPUP_EXIT_SIGN_OUT')}
            </button>
          </div>
        </DimDropdown>
    );
  }
}

export default withTranslation()(UserMenu);
