import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DimMenuDropdown from './core/DimMenuDropdown';

class SubscriptionStatusFilterMenu extends Component {
  render() {
    return (
        <DimMenuDropdown
            id={`dropdownSubscriptionStatusFilter`}
            className={`dropdown pull-left s-sorting s-sorting-subscribe`}
            toggleClassName={`btn btn-default dropdown-toggle`}
            onClick={this.props.onClick}
            value={this.props.value}
            isCaret={true}
            menus={this.props.menus}/>
    );
  }
}

export default withTranslation()(SubscriptionStatusFilterMenu);
