import {MSG_SHOULD_NEVER_HAPPEN} from './errorConstant';

export const formatDpiDate = (dateObj, dateSeparator, format, addGmt) => {
  if (!dateObj) {
    return "";
  }

  let year = dateObj.substring(0, 4);
  let month = dateObj.substring(4, 6);
  let date = dateObj.substring(6, 8);

  let ret;

  if (format === "MMDDYY") {
    ret = month + dateSeparator + date + dateSeparator + year;
  } else if (format === "MMDD") {
    ret = month + dateSeparator + date;
  } else {
    throw new Error(MSG_SHOULD_NEVER_HAPPEN);
  }

  if (addGmt) {
    ret += " [GMT]";
  }

  return ret;
};


//timestamp input format: YYYYMMDDhhmmss (ex: 20250228035532)
export const getYearFromTimestamp = (timestamp) => {
    if(!timestamp) return timestamp;

    return timestamp.substring(0, 4);
}

export const getMonthFromTimestamp = (timestamp) => {
  if(!timestamp) return timestamp;

  return timestamp.substring(4, 6);
}

export const getDayFromTimestamp = (timestamp) => {
  if(!timestamp) return timestamp;

  return timestamp.substring(6, 8);
}
