import React, {Component} from 'react';
import {Dropdown} from 'react-bootstrap';
import {connect} from 'react-redux';
import DimDropdownToggle from './DimDropdownToggle';
import DimDropdownMenu from './DimDropdownMenu';
import {activateDim, deactivateDim} from '../../../ducks/dimAction';
import {withTranslation} from 'react-i18next';
import {autobind} from 'core-decorators';

@autobind
class DimDropdown extends Component {

  toggleDim(e) {
    e.preventDefault();
    if (this.props.isDim) {
      this.props.deactivateDim();
    } else {
      this.props.activateDim(this);
    }
  }

  render() {
    let selected = this.props.excludeDimObj === this;
    let arrowStyle = selected ? {display: "block"} : {display: "none"};

    return (
        <Dropdown id={this.props.id}
                  componentClass={`div`}
                  className={this.props.className}>
          <DimDropdownToggle id={this.props.id}
                             alt={this.props.alt}
                             bsRole={`toggle`}
                             onToggle={this.toggleDim}
                             isCaret={this.props.isCaret}
                             className={this.props.toggleClassName}/>
          <DimDropdownMenu bsRole={`menu`}
                           selected={selected}>
            {this.props.children}
          </DimDropdownMenu>
          <span className={`s-dropdown-arrow`} style={arrowStyle}/>
        </Dropdown>
    );
  }
}

function mapStoreStateToProps(store) {
  return {
    isDim: store.dim.isDim,
    excludeDimObj: store.dim.excludeDimObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activateDim: (obj) => {
      dispatch(activateDim(obj));
    },
    deactivateDim: () => {
      dispatch(deactivateDim());
    }
  };
}

export default withTranslation()(connect(mapStoreStateToProps, mapDispatchToProps)(DimDropdown));
