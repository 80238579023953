import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import Footer from '../Footer';
import {URL_AUTH_LOGOUT, URL_AUTH_SIGN_IN_PAGE, URL_INDEX_PAGE} from '../../utils/urlConstant';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {BUYER_PORTAL_SERVICE_ID} from "../../utils/globalConstants";
import {errorCode2Msg} from "../../translations/i18n-helper";
import {getProperty} from '../../utils/propertiesByDomain';
import {autobind} from 'core-decorators';
import PageContainer from '../PageContainer';

@autobind
class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.aRef = React.createRef();

    let href;
    if (this.props.logout) {
      href = getProperty("samsungAccountSignOutGateUrl")
          + "?state=" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
          + "&client_id=" + BUYER_PORTAL_SERVICE_ID
          + "&signOutURL=" + getProperty("urlHome") + URL_AUTH_LOGOUT;
    } else {
      href = this.props.authenticated === true ? URL_INDEX_PAGE : URL_AUTH_SIGN_IN_PAGE;
    }

    this.href = href;
  }

  renderButton(href) {
    return (
        <div className={`one_btn_wrapping`}>
          <a href={href} style={{display: "none"}} ref={this.aRef}/>
          {this.props.isSignon ? (
              <Fragment>
                <button
                    type={`button`}
                    className={`s-btn-default`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.assign(getProperty("samsungAccountHome"));
                    }}>
                  {this.props.t('COM_BDP_SID_FUNCTION_GO_TO_MENU_A_TEXT', {link: "Samsung Account"})}
                </button>
                {this.props.status !== 404 &&
                <Fragment>
                  <br/>
                  <br/>
                  <button
                      type={`button`}
                      className={`s-btn-default`}
                      onClick={(e) => {
                        e.preventDefault();
                        document.location.reload();
                      }}>
                    {this.props.t('COM_SID_RETRY_KR_FLIP')}
                  </button>
                </Fragment>
                }
              </Fragment>
          ) : (
              <button type={`button`}
                      className={`s-btn-default`}
                      onClick={(e) => {
                        e.preventDefault();
                        this.aRef.current.click();
                      }}>
                {this.props.t('TV_SID_PAYMENT_GO_TO_SAMSUNG_CHECKOUT')}
              </button>
          )}
        </div>
    );
  }

  renderContainer(title, message, href) {
    return (
        <div className={`s-container`}>
          <div className={`container-fluid`}>
            <div className={`row`}>
              <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3`}>
                <div className={`s-error-section`}>
                  <p className={`s-error-message`}>
                    {title}<br/>
                    {message}
                  </p>
                  {this.renderButton(href)}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

  renderBackground(containerContent) {
    return (
        <PageContainer
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}>
          <div className={`s-container`}>{containerContent}</div>
          <Footer/>
        </PageContainer>
    );
  }

  render() {
    // show popup error
    if (this.props.popup) {
      // popup event will be invoked in componentDidMount
      return this.renderBackground();
    }

    // or show page error
    let title;

    switch (this.props.status) {
      case "403":
        title = "[" + this.props.status + "] Forbidden";
        break;
      case "404":
        title = "[" + this.props.status + "] Not Found";
        break;
      case "405":
        title = "[" + this.props.status + "] Method Not Allowed";
        break;
      case "415":
        title = "[" + this.props.status + "] Unsupported Media Type";
        break;
      case "500":
      default:
        title = "[" + this.props.status + "] Internal Server Error";
        break;
    }

    let message = errorCode2Msg(this.props.t, this.props.code);
    return this.renderBackground(this.renderContainer(title, message, this.href));
  }

  componentDidMount() {
    if (this.props.popup) {
      let message = errorCode2Msg(this.props.t, this.props.code);
      this.props.showPopup2Line(message, this.href);
    }
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(ErrorPage)));
