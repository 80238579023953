import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT} from './globalConstants';
import {ERR_CODE_GENERAL, MSG_SHOULD_NEVER_HAPPEN} from './errorConstant';
import {errorCode2Msg} from '../translations/i18n-helper';

export const defaultErrorHandler = (props, error) => {
  if (!props.t || !props.hideAnyPopup || !props.showPopup2Line) {
    throw new Error(MSG_SHOULD_NEVER_HAPPEN);
  }

  props.hideAnyPopup();
  if (error.response) {
    let response = error.response;
    let status = response.data.status || ERR_CODE_GENERAL;
    props.showPopup2Line(errorCode2Msg(props.t, status));
  } else if (error.request) {
    props.showPopup2Line(errorCode2Msg(props.t, ERR_CODE_GENERAL));
  }
}

export default axios.create({timeout: AXIOS_CLIENT_TIMEOUT});