import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Input from './Input';
import ErrorMsg from './ErrorMsg';
import {autobind} from 'core-decorators';

@autobind
class CardNumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarning: false
    };

    this.ref = React.createRef();
  }

  setWarningOff(e) {
    this.toggleWarningStatus(false);
  }

  toggleWarningStatus(value) {
    this.setState({isWarning: value});
  }

  formatWithHyphen(value) {
    let newValue = "";
    for (let i = 1; i <= value.length; ++i) {
      newValue += value[i - 1];
      if (i !== 1 && i % 4 === 0 && i + 1 <= value.length) {
        newValue += "-";
      }
    }

    return newValue;
  }

  setIsValidToWarningStatus(e) {
    e.preventDefault();
    this.toggleWarningStatus(!this.props.isValid);
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.setWarningOff(e);
  }

  render() {
    return (
        <div className={`form-group`}>
          <h3 className={`s-form-title`}>
            {this.props.t('COM_CARD_NUMBER_KR_CARD')}
          </h3>
          <Input
              onInit={(ref) => this.ref = ref}
              warning={this.state.isWarning}
              tabIndex={this.props.tabIndex}
              type={`tel`}
              name={this.props.name}
              maxLength={19}
              value={this.formatWithHyphen(this.props.value)}
              placeholder={this.props.t('TV_SID_PAYMENT_ONLY_MUSIC_CALUES_ALLOWS')}
              onChange={this.props.onChange}
              onBlur={this.setIsValidToWarningStatus}
              onFocus={this.handleFocus}/>
          <ErrorMsg
              warning={this.state.isWarning}
              message={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_35')}/>
        </div>
    );
  }

  componentDidMount() {
    if (this.props.onInit) {
      this.props.onInit(this.ref);
    }
  }
}

export default withTranslation()(CardNumberInput);
