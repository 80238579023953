import React, {Component} from 'react';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {ERR_CODE_DO_SCA, ERR_CODE_SUCCESS} from '../../utils/errorConstant';
import {errorCode2Msg} from '../../translations/i18n-helper';
import {withTranslation} from 'react-i18next';
import Footer from '../Footer';
import SContainer from '../SContainer';
import {getCardByType} from '../../utils/cardUtils';
import {SAMSUNG_CHECKOUT} from '../../utils/globalConstants';
import {URL_CASHON_PAY_API, URL_CASHON_SUCCESS_PAGE, URL_INDEX_PAGE} from '../../utils/urlConstant';
import DdcHiddenIframeForm from '../DdcHiddenIframeForm';
import {createJscSessionId} from '../../utils/jscUtils';
import ScaIframeForm from '../ScaIframeForm';
import {activateDim, deactivateDim} from '../../ducks/dimAction';
import {connect} from 'react-redux';
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

@autobind
class MobilePayPage extends Component {
  constructor(props) {
    super(props);

    this.ddcFormRef = React.createRef();
    this.successFormRef = React.createRef();
    this.scaFormRef = React.createRef();

    this.state = {
      ddcSessionId: null,
      jscSessionId: createJscSessionId(),
      showScaIframe: false,
      scaJwt: ""
    };
  }

  handleScaComplete(responseJson) {
    this.setState({showScaIframe: false}, () => {
      this.props.deactivateDim();

      if (!Object.is(responseJson.status, ERR_CODE_SUCCESS)) {
        this.props.showPopup2Line(errorCode2Msg(this.props.t, responseJson.status), URL_INDEX_PAGE);
        return;
      }

      this.successFormRef.current.submit();
    });
  }

  exitSca() {
    this.props.deactivateDim();
    this.setState({showScaIframe: false});
  }

  triggerSca(scaJwt) {
    this.props.activateDim(null, true);
    this.setState({showScaIframe: true, scaJwt: scaJwt}, () => this.scaFormRef.current.submit()); // will trigger handleScaComplete()
  }

  handlePayClick() {
    this.props.showProgressBar();

    httpClient
        .post(URL_CASHON_PAY_API, {
          authCode: this.props.authCode,
          ddcSessionId: this.state.ddcSessionId,
          jscSessionId: this.state.jscSessionId,
          browserLanguage: navigator.language,
          browserUserAgentHeader: navigator.userAgent
        })
        .then(response => {
          this.props.hideAnyPopup();

          const data = response.data;
          if (data.status === ERR_CODE_DO_SCA) {
            this.triggerSca(data.scaJwt);
            return;
          } else if (data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status), URL_INDEX_PAGE);
            return;
          }

          this.successFormRef.current.submit();
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  handleDdcComplete(e, ddcSessionId) {
    this.setState({ddcSessionId: ddcSessionId}, () => this.props.hideAnyPopup());
  }

  triggerDdc() {
    this.props.showProgressBar();
    this.ddcFormRef.current.submit();
  }

  renderButtonName() {
    if (this.props.isProductSubscription || this.props.isUserCountryEu) {
      return this.props.t('COM_SID_AGREE_AND_PAY');
    } else if (parseFloat(this.props.paymentTotalAmount) === 0) {
      return this.props.t("TV_SID_GET_IT_FREEN");
    } else {
      return this.props.t("TV_SID_PAY_NOW");
    }
  }

  renderDisclaimer() {
    if (this.props.isProductSubscription) {
      return this.props.t('COM_SID_AGREE_AUTHORIZE_ACCOUNT_PURCHASE_HISTORY');
    } else if (this.props.isUserCountryEu) {
      return this.props.t('COM_SID_PAYMENT_AGREE_PURCHASE_DOWN_MSG');
    }
  }

  render() {
    const card = getCardByType(this.props.cardType);
    const cardName = card ? this.props.t(card.key) : "";
    const lastCardNum = this.props.lastCardNum ? this.props.lastCardNum : "xxxx";

    return (
        <PageContainer
            hidden={true}
            showSubHeader={true}
            subHeaderText={SAMSUNG_CHECKOUT}>
          <SContainer>
            <ScaIframeForm
                parentRef={this.scaFormRef}
                scaJwt={this.state.scaJwt}
                show={this.state.showScaIframe}
                onClick={this.exitSca}
                onComplete={this.handleScaComplete}/>
            <DdcHiddenIframeForm
                parentRef={this.ddcFormRef}
                onComplete={this.handleDdcComplete}
                ddcBin={this.state.ddcBin}
                ddcJwt={this.props.ddcJwt}/>
            <div className={`container`}>
              <div className={`row s-pay-page-row-padding-margin`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s-section s-info-underline`}>
                    <div className={`s-info-group`}>
                      <h3 className={`s-info-title`}>
                        <div className={`s-pay-page-info-title-color`}>
                          {this.props.t('TV_SID_HOMESHOPPING_PRODUCT_NAME')}
                        </div>
                      </h3>
                      <p className={`s-pay-page-info-cont`}>
                        {this.props.paymentProductName}
                      </p>
                    </div>
                  </div>
                  <div className={`s-section s-info-underline`}>
                    <div className={`s-info-group`}>
                      <h3 className={`s-info-title`}>
                        <div className={`s-pay-page-info-title-color`}>
                          {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_8')}
                        </div>
                      </h3>
                      <p className={`s-pay-page-info-cont s-info-card`}>
                        {cardName + " X-" + lastCardNum}
                      </p>
                    </div>
                  </div>
                  <div className={`s-section`}>
                    <div className={`s-info-group`}>
                      <h3 className={`s-info-title`}>
                        <div className={`s-pay-page-info-title-color`}>
                          {this.props.t('TV_SID_BILLING_CAOCT_TOTAL_AMOUNT')}
                        </div>
                      </h3>
                      <p className={`s-pay-page-info-cont`}>
                        {this.props.paymentCurrencySymbol + " " + this.props.paymentTotalAmount}{this.props.paymentTotalAmountAdditionalText}
                      </p>
                    </div>
                  </div>
                  <div className={`s-section padding-top-3em`}>
                    <div className={`s-info-group`}>
                      <p className={`s-info-cont`}>
                        {this.renderDisclaimer()}
                      </p>
                    </div>
                  </div>
                  <div className={`s-btn-item btn-long padding-top-20`}>
                    <button
                        type="button"
                        className="s-btn s-btn-default"
                        disabled={!this.state.ddcSessionId || !this.state.jscSessionId}
                        onClick={this.handlePayClick}>
                      {this.renderButtonName()}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <form method={`GET`} action={URL_CASHON_SUCCESS_PAGE} ref={this.successFormRef}/>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.triggerDdc();
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activateDim: (obj, disableTouch) => {
      dispatch(activateDim(obj, disableTouch));
    },
    deactivateDim: () => {
      dispatch(deactivateDim());
    }
  };
}

export default connect(null, mapDispatchToProps)(withPopupComponents(withTranslation()(withPopupDispatch(MobilePayPage))));