import React, {Component} from 'react';
import MainHeader from './MainHeader';
import DimLayer from './dimdropdown/core/DimLayer';
import Logo from './Logo';
import Util from './Util';
import UserMenu from './dimdropdown/UserMenu';
import QuickMenu from './dimdropdown/QuickMenu';
import SHeader from './SHeader';
import NaviHeader from './NaviHeader';
import SubHeader from './SubHeader';

class Header extends Component {
  render() {
    return (
        <SHeader>
          <DimLayer/>
          <MainHeader hidden={this.props.hidden}>
            <Logo authenticated={this.props.authenticated}/>
            <Util>
              <UserMenu
                  ssoAccountId={this.props.ssoAccountId}
                  authenticated={this.props.authenticated}/>
              <QuickMenu/>
            </Util>
          </MainHeader>
          {this.props.showNav &&
          <NaviHeader
              url={this.props.url}
              hidden={this.props.hidden}
              dimAllButBenefit={this.props.dimAllNavMenusButBenefit}/>
          }
          {this.props.showSubHeader &&
          <SubHeader
              text={this.props.subHeaderText}
              isConfirm={this.props.isSubHeaderBackConfirm}/>
          }
        </SHeader>
    );
  }
}

export default Header;
