export const EMAIL_CHECKOUT_CS = "cs.checkout@partner.sec.co.kr";
export const BR_VALUE = "BR"; // Format is same as TBL_CNTR_CD (2-letter, capital)
export const KR_VALUE = "KR"; // Format is same as TBL_CNTR_CD (2-letter, capital)
export const US_VALUE = "US"; // Format is same as TBL_CNTR_CD (2-letter, capital)
export const IN_VALUE = "IN"; // Format is same as TBL_CNTR_CD (2-letter, capital)
export const RU_VALUE = "RU"; // Format is same as TBL_CNTR_CD (2-letter, capital)
export const PAYMETHOD_GCC = "GCC";
export const PAYMETHOD_KCC = "KCC";
export const PAYMETHOD_PP = "PP";
export const PAYMETHOD_KSP = "KSP";
export const PAYMETHOD_WSP = "WSP";
export const PAYMETHOD_IWP= "IWP";
export const AUTH = "AUTH";
export const TYPE_PAYON = "TYPE_PAYON";
export const TYPE_SIGNON = "TYPE_SIGNON";
export const TYPE_PAYPALON = "TYPE_PAYPALON";
export const TYPE_PAYMETHODS = "TYPE_PAYMETHODS";
export const TYPE_PAYMENT = "TYPE_PAYMENT";
export const AXIOS_CLIENT_TIMEOUT = 1000 * 70; // Because Buyer portal's RestTemplate timeout is 60 sec
export const ISCROLL_MENU_SCROLL_DURATION = 300;
export const BUYER_PORTAL_SERVICE_ID = "thkuye4tvi";
export const TYPE_PRODUCT_SUBSCRIPTION = "4";
export const TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION = "6";
export const SAMSUNG_CHECKOUT = "Samsung Checkout";
export const QS_VAULT_CARD_ID = "vaultCardId";
export const QS_AUTH_CODE = "authCode";
export const QS_COUNTRY = "country";