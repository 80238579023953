import {combineReducers} from 'redux';
import {dimReducer} from './dimReducer';
import {popupReducer} from './popupReducer';

const reducer = combineReducers({
  dim: dimReducer,
  popup: popupReducer
});

export default reducer;
