import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import SContainer from '../SContainer';
import Footer from '../Footer';
import Input from '../Input';
import Button from '../Button';
import {URL_PAYMETHODS_KCC_CHECK_API, URL_PAYMETHODS_KCC_REGISTER_API, URL_PAYMETHODS_PAGE, URL_SIGNON_KCC_CHECK_API, URL_SIGNON_KCC_REGISTER_API, URL_SIGNON_RESULT_SUCCESS_PAGE} from '../../utils/urlConstant';
import {ERR_CODE_GENERAL, ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import {PAYMETHOD_KCC, SAMSUNG_CHECKOUT} from '../../utils/globalConstants';
import Timer from '../Timer';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {errorCode2Msg} from "../../translations/i18n-helper";
import Form from '../Form';
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

@autobind
class KccRegPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshCounter: 0,
      mobileNum: "",
      bgwTid: "",
      authCode: "0000",
      showMobileInput: true,
      showTimer: false,
      isTimerDone: false
    };

    this.formRef = React.createRef();
    this.cardTypeRef = React.createRef();
    this.lastCardNumRef = React.createRef();
  }

  cardRegisterTimesUp() {
    this.setState({isTimerDone: true});
  }

  isRegisterKccDone(e) {
    e.preventDefault();

    if (this.state.isTimerDone === true) {
      this.sendKccRegisterUrlToMobile(e);
    } else {
      this.props.showProgressBar();

      httpClient
          .post(this.props.isSignon ? URL_SIGNON_KCC_CHECK_API : URL_PAYMETHODS_KCC_CHECK_API, {
            authCode: this.state.authCode,
            bgwTid: this.state.bgwTid,
            payMethod: PAYMETHOD_KCC
          })
          .then(response => {
            this.props.hideAnyPopup();
            if (response.data.status !== ERR_CODE_SUCCESS) {
              this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
              return;
            }

            let data = response.data;
            if (data.processingStatus === "003" || data.processingStatus === "004") {
              this.props.showPopup2Line(errorCode2Msg(this.props.t, ERR_CODE_GENERAL));
              this.setState({
                showMobileInput: true,
                showTimer: false
              });
            } else if (data.processingStatus === "002") {
              this.props.showPopup2Line(this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_33'));
              setTimeout(() => this.props.hideAnyPopup(), 5000);
            } else if (data.processingStatus === "001" && this.props.isSignon) { // KCC도, signon 일때만, 카드등록 성공으로
              this.postToSuccessView(data.cardType, data.lastCardNum);
            } else if (data.processingStatus === "001" && !this.props.isSignon) { // 그 외에는 바이어포탈 로그인때만 KCC 카드 등록이 가능하니 결제수단 화면으로
              window.location.assign(URL_PAYMETHODS_PAGE);
            } else {
              throw new Error(MSG_SHOULD_NEVER_HAPPEN);
            }
          })
          .catch((error) => defaultErrorHandler(this.props, error));
    }
  }

  postToSuccessView(cardType, lastCardNum) {
    this.lastCardNumRef.current.value = lastCardNum;
    this.cardTypeRef.current.value = cardType;
    this.formRef.current.submit();
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({mobileNum: e.target.value});
  }

  sendKccRegisterUrlToMobile(e) {
    e.preventDefault();
    this.props.showProgressBar();
    httpClient
        .post(this.props.isSignon ? URL_SIGNON_KCC_REGISTER_API : URL_PAYMETHODS_KCC_REGISTER_API, {
          mobileNum: this.state.mobileNum,
          payMethod: PAYMETHOD_KCC
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          let data = response.data;
          this.setState({
            authCode: data.authCode,
            bgwTid: data.bgwTid,
            showMobileInput: false,
            showTimer: true,
            isTimerDone: false,
            refreshCounter: this.state.refreshCounter + 1
          });

        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={!this.props.isSignon}
            showSubHeader={true}
            url={this.props.url}
            isSubHeaderBackConfirm={!this.props.isSignon}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={!this.props.isSignon ? this.props.t('COM_SID_CREDIT_DEBIT_CARD') : SAMSUNG_CHECKOUT}>
          <SContainer>
            {this.state.showMobileInput &&
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s-addmethod-section`}>
                    <div className={`form-group`}>
                      <h3 className={`s-form-title`}>
                        {this.props.t('TV_SID_PAYMENT_ENTER_MOBILE_NUMBER_RECEIVE_MESG_KR_WEBPORTAL')}
                      </h3>
                      <Input
                          tabIndex={this.props.tabIndex}
                          type={`tel`}
                          name={this.props.name}
                          min={1}
                          max={9999}
                          maxLength={15}
                          value={this.props.value}
                          onChange={this.handleInputChange}/>
                      <Button
                          disabled={this.state.mobileNum.length < 10}
                          className={`one_btn_wrapping`}
                          onClick={this.sendKccRegisterUrlToMobile}
                          value={this.props.t('COM_SID_SEND_KR_TRANSFER')}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
            {this.state.showTimer &&
            <div className={`container`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s_tel_code`}>
                    <dl className={`authentication_code`}>
                      <dt>{this.props.t('COM_SID_AUTHENTICATION_CODE')}</dt>
                      <dd className={`code_n`}>
                        <span>{this.state.authCode}</span>
                      </dd>
                      <dd className={`expires`}>
                        {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_32')}
                        <Timer timeRemainingInSeconds={600}
                               onCompletion={this.cardRegisterTimesUp}
                               refreshCounter={this.state.refreshCounter}/>
                      </dd>
                    </dl>
                    <p className={`info`}>
                      {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_31')}
                    </p>
                    <Button
                        className={`one_btn_wrapping`}
                        value={this.state.isTimerDone ? this.props.t('COM_SID_GET_A_NEW_CODE') : this.props.t('SID_REGISTER')}
                        onClick={this.isRegisterKccDone}/>
                  </div>
                </div>
              </div>
              <Form method={`post`} action={URL_SIGNON_RESULT_SUCCESS_PAGE} ref={this.formRef}>
                <input type={`hidden`} name={`cardType`} ref={this.cardTypeRef}/>
                <input type={`hidden`} name={`lastCardNum`} ref={this.lastCardNumRef}/>
                <input type={`hidden`} name={`paymethod`} value={PAYMETHOD_KCC}/>
              </Form>
            </div>
            }
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(KccRegPage)));
