import React, {Component} from 'react';
import SContainer from '../SContainer';
import AuthCode from '../AuthCode';
import {withTranslation} from 'react-i18next';
import {URL_AUTH_LOGOUT, URL_PAYMETHODS_AUTH_CODE_CHECK_API, URL_PAYMETHODS_LIST_VAULT_CARD_PAGE} from '../../utils/urlConstant';
import {ERR_CODE_NOT_SAME_GUID_CNTR, ERR_CODE_SUCCESS} from '../../utils/errorConstant';
import Footer from '../Footer';
import Form from '../Form';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {errorCode2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

@autobind
class GccAuthCodePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ""
    };

    this.formRef = React.createRef();
  }

  navToCardRegisterPage(e) {
    e.preventDefault();

    this.props.showProgressBar();

    httpClient
        .post(URL_PAYMETHODS_AUTH_CODE_CHECK_API, {
          authCode: this.state.inputValue
        })
        .then(response => {
          this.props.hideAnyPopup();

          if (response.data.status === ERR_CODE_NOT_SAME_GUID_CNTR) {
            this.props.showPopupConfirm(
                errorCode2Msg(this.props.t, response.data.status),
                () => window.location.assign(URL_AUTH_LOGOUT),
                null,
                this.props.t('COM_IDWS_MOIP_POPUP_EXIT_SIGN_OUT'),
                this.props.t('SID_CANCEL'));
            return;
          }

          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.formRef.current.submit();
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  filterNumber(e) {
    e.preventDefault();
    let newValue = e.target.value.replace(/\D/g, "");
    this.setState({inputValue: newValue});
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={true}
            showSubHeader={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('COM_SID_CREDIT_DEBIT_CARD')}>
          <SContainer>
            <Form method={`GET`}
                  ref={this.formRef}
                  action={URL_PAYMETHODS_LIST_VAULT_CARD_PAGE}>
              <div className={`container`}>
                <div className={`row`}>
                  <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                    <div className={`s-addmethod-section`}>
                      <h3 className={`s-container-title s-type-center`}>
                        {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_44')}
                      </h3>
                      <AuthCode
                          inputValue={this.state.inputValue}
                          onInputChange={this.filterNumber}
                          buttonClassName={`one_btn_wrapping`}
                          onButtonClick={this.navToCardRegisterPage}/>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(GccAuthCodePage)));
