export const getProperty = (key) => {
  switch (window.location.host) {
    case host.dev:
      return propertiesByDomain.dev[key];
    case host.stag:
      return propertiesByDomain.stag[key];
    case host.prod:
      return propertiesByDomain.prod[key];
    default: // If not match above, its all local
      return propertiesByDomain.local[key];
  }
}

const host = {
  local: "buyer-portal.local.com",
  dev: "dev.samsungcheckout.com",
  stag: "sbox.samsungcheckout.com",
  prod: "www.samsungcheckout.com",
}

const propertiesByDomain = {
  local: {
    urlHome: "http://" + host.local + ":8080",
    samsungAccountAuthUrl: "https://account.samsung.com/iam/oauth2/authorize",
    samsungAccountHome: "https://account.samsung.com",
    ddcHost: "https://centinelapi.cardinalcommerce.com",
    ddcUri: "/V1/Cruise/Collect",
    jscProfilingDomain: "ddc.worldpay.com",
    jscOrigId: "dzppsd1h",
    scaUri: "/V2/Cruise/StepUp"
  },
  dev: {
    urlHome: "https://" + host.dev,
    samsungAccountAuthUrl: "https://account.samsung.com/iam/oauth2/authorize",
    samsungAccountHome: "https://account.samsung.com",
    ddcHost: "https://centinelapi.cardinalcommerce.com",
    ddcUri: "/V1/Cruise/Collect",
    jscProfilingDomain: "ddc.worldpay.com",
    jscOrigId: "dzppsd1h",
    scaUri: "/V2/Cruise/StepUp"
  },
  stag: {
    urlHome: "https://" + host.stag,
    samsungAccountAuthUrl: "https://account.samsung.com/iam/oauth2/authorize",
    samsungAccountHome: "https://account.samsung.com",
    ddcHost: "https://centinelapi.cardinalcommerce.com",
    ddcUri: "/V1/Cruise/Collect",
    jscProfilingDomain: "ddc.worldpay.com",
    jscOrigId: "dzppsd1h",
    scaUri: "/V2/Cruise/StepUp"
  },
  prod: {
    urlHome: "https://" + host.prod,
    samsungAccountAuthUrl: "https://account.samsung.com/iam/oauth2/authorize",
    samsungAccountHome: "https://account.samsung.com",
    ddcHost: "https://centinelapi.cardinalcommerce.com",
    ddcUri: "/V1/Cruise/Collect",
    jscProfilingDomain: "ddc.worldpay.com",
    jscOrigId: "dzppsd1h",
    scaUri: "/V2/Cruise/StepUp"
  },
}