import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {autobind} from 'core-decorators';

@autobind
class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labelText: this.props.defaultLabel,
      isActive: false
    };
  }

  handleLabelClick(e) {
    e.preventDefault();
    this.toggleActive();
  }

  toggleActive() {
    this.setState({isActive: !this.state.isActive});
  }

  changeSelect(newValue, newLabelText) {
    this.setState({labelText: newLabelText});

    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  handleButtonClick(e) {
    e.preventDefault();

    let newValue = e.target.parentNode.getAttribute("value");
    let newLabelText = e.target.value;
    this.changeSelect(newValue, newLabelText);
    this.toggleActive();
  }

  handleChange(e) {
    e.preventDefault();

    let newValue = e.target.value;
    let newLabelText = e.target[e.target.selectedIndex].text;
    this.changeSelect(newValue, newLabelText);
  }

  render() {
    let divClassName = "s-select";
    if (this.state.isActive) {
      divClassName += " s-active";
    }

    const lists = this.props.children.map((child) => {
      const value = child.props.value;
      const text = child.props.children;
      return (
          <li key={value} value={value}>
            <button type='button' onClick={this.handleButtonClick} value={text}>{text}</button>
          </li>
      );
    });

    return (
        <div className={divClassName}>
          <label htmlFor={this.props.name} onClick={this.handleLabelClick}>{this.state.labelText}<span>OPEN</span></label>
          <select name={this.props.name} value={this.props.value} onChange={this.handleChange}>
            {this.props.children}
          </select>
          <ul>{lists}</ul>
        </div>
    );
  }
}

export default withTranslation()(Select);
