import React, {Component} from 'react';
import {connect} from 'react-redux';
import DimDropdown from './DimDropdown';
import {MSG_SHOULD_NEVER_HAPPEN} from '../../../utils/errorConstant';
import {MenuItem} from 'react-bootstrap';
import {deactivateDim} from '../../../ducks/dimAction';
import {autobind} from 'core-decorators';

@autobind
class DimMenuDropdown extends Component {

  handleMenuClick(e, clickedMenu) {
    if (this.props.onClick) {
      this.props.onClick(e, clickedMenu);
    }

    this.props.deactivateDim();
  }

  renderMenuItem(menuId, menuText) {
    return (
        <MenuItem
            key={menuId}
            onClick={(e) => this.handleMenuClick(e, menuId)}
            className={this.props.value === menuId ? `s-current` : null}>
          {menuText}
        </MenuItem>
    );
  }

  renderAlt() {
    let alt = null;

    this.props.menus.forEach(menu => {
      if (this.props.value === menu.id) {
        alt = menu.text;
      }
    });

    if (!alt) {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    return alt;
  }

  render() {
    return (
        <DimDropdown
            id={this.props.id}
            alt={this.renderAlt()}
            isCaret={this.props.isCaret}
            className={this.props.className}
            toggleClassName={this.props.toggleClassName}>
          <ul className={`dropdown-menu`}>
            {this.props.menus.map(menu => this.renderMenuItem(menu.id, menu.text))}
          </ul>
        </DimDropdown>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deactivateDim: () => {
      dispatch(deactivateDim());
    }
  };
}

export default connect(0, mapDispatchToProps)(DimMenuDropdown);
