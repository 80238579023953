export const TYPE_SHOW_POPUP = "TYPE_SHOW_POPUP";
export const TYPE_HIDE_POPUP = "TYPE_HIDE_POPUP";

export const showPopup = (type, text, url, yesCallback, noCallback, yesText, noText, list, title, isScroll) => {
  return {
    type: TYPE_SHOW_POPUP,
    payload: {
      popupType: type,
      text: text,
      url: url,
      yesCallback: yesCallback,
      noCallback: noCallback,
      yesText: yesText,
      noText: noText,
      list: list,
      title: title,
      isScroll: isScroll
    }
  };
};

export const hidePopup = () => {
  return {
    type: TYPE_HIDE_POPUP
  };
};
