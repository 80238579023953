import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hidePopup, showPopup} from '../../ducks/popupAction';
import {TYPE_2LINE, TYPE_CONFIRM, TYPE_CONFIRM_LONGLINE, TYPE_LONGLINE, TYPE_PROGRESS_BAR, TYPE_TOAST, TYPE_WITH_LIST_BOX} from '../Popup';
import {activateDim, deactivateDim} from "../../ducks/dimAction";

function mapDispatchToProps(dispatch) {
  return {
    showProgressBar: () => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_PROGRESS_BAR));
    },
    showPopupLongLine: (text) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_LONGLINE, text));
    },
    showPopupLongLineWithTitleAndScroll: (title, text) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_LONGLINE, text, null, null, null, null, null, null, title, true));
    },
    showPopup2Line: (text, url, yesCallback) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_2LINE, text, url, yesCallback));
    },
    showPopup4Line: (text, url) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_2LINE, text, url));
    },
    showPopupConfirm: (text, yesCallback, noCallback, yesText, noText) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_CONFIRM, text, null, yesCallback, noCallback, yesText, noText));
    },
    showPopupConfirmLongLine: (text, yesCallback, noCallback, yesText, noText) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_CONFIRM_LONGLINE, text, null, yesCallback, noCallback, yesText, noText));
    },
    showToastPopup: (text) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_TOAST, text));
    },
    showPopupWithListBox: (text, list) => {
      dispatch(hidePopup());
      dispatch(showPopup(TYPE_WITH_LIST_BOX, text, null, null, null, null, null, list));
    },
    hideAnyPopup: () => {
      dispatch(hidePopup());
    },
    activateDim: () => {
      dispatch(activateDim(null, true));
    },
    deactivateDim: () => {
      dispatch(deactivateDim());
    }
  };
}

const withPopupDispatch = (WrappedComponent) => {
  const withPopupDispatchWrapper = () => {
    return class withPopupDispatch extends Component {
      render() {
        return <WrappedComponent {...this.props}/>;
      }
    }
  };

  return connect(0, mapDispatchToProps)(withPopupDispatchWrapper());
};

export default withPopupDispatch;
