import httpClient, {defaultErrorHandler} from './httpClient';
import {
    URL_PAYMETHODS_SUCCESS_PAGE,
    URL_TOSS_ACCESS_TOKEN,
    URL_TOSS_GET_CONFIG,
    URL_TOSS_REGISTER_CARD
} from './urlConstant';
import {ERR_CODE_SUCCESS} from './errorConstant';
import {errorCode2Msg} from '../translations/i18n-helper';
import {loadBrandPay} from '@tosspayments/brandpay-sdk';
import {PAYMETHOD_TOSS} from "./globalConstants";

const getTossConfig = async (props) => {
  return (
      httpClient
      .get(URL_TOSS_GET_CONFIG)
      .then(response => {
        if (response.data.status !== ERR_CODE_SUCCESS) {
          this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
        }
        return response.data;
      })
      .catch(
          (error) => {
            console.error("Error fetching toss config:", error);
            defaultErrorHandler(props, error);
          }
      )
  )
}


const initBrandPay = async (props, clientKey, customerKey) => {
  try {
    return await loadBrandPay(clientKey, customerKey, {
      redirectUrl: window.location.origin + URL_TOSS_ACCESS_TOKEN ,
      windowTarget: "iframe"
    });

  } catch (error) {
    console.error("Error fetching brandpay:", error);
    defaultErrorHandler(props, error);
    return new Promise("Error fetching brandpay");
  }
}

const registerToss = async (props, customerKey, card) => {
  let payload = {
    customerKey: customerKey,
    cardId: card.id,
    cardName: card.cardName,
    cardNumber: card.cardNumber
  }
  return  (
      httpClient
          .post(URL_TOSS_REGISTER_CARD, payload)
          .then(response => {
            if (response.data.status !== ERR_CODE_SUCCESS) {
              props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            }else{
                navigateToSuccessPage(PAYMETHOD_TOSS, response.data.cardId, payload.cardNumber);
            }
            return response.data;

          })
          .catch(
              (error) => {
                  console.error("Error while registering toss card information:", error);
                  defaultErrorHandler(props, error);
              }
          )
  )
}

const navigateToSuccessPage = (cardType, cardId, cardNumber) => {
    const lastFourDigitsCard = cardNumber.slice(-4);
    const successUrl =
        `${URL_PAYMETHODS_SUCCESS_PAGE}?cardId=${cardId}&paymethod=${cardType}&lastCardNum=${lastFourDigitsCard}`;
    window.location.assign(successUrl);

}

export const tossAddPaymentMethod = async (props) => {
  props.activateDim();
  props.showProgressBar();

  const tossConfig = await getTossConfig(props)

  const customerKey = tossConfig.checkoutUserId;
  const clientKey = tossConfig.clientKey;


  const brandpay = await initBrandPay(props, clientKey, customerKey);
  if(await brandpay) props.hideAnyPopup();

  await brandpay
      .addPaymentMethod("카드")
      .then(async (result) => {
        let card = getNewlyRegisteredCard(result.cards, result.selectedMethodId)

        await registerToss(
            props,
            customerKey,
            card);
      }).catch((error) => {
          console.error("Error while adding payment method to toss.", error);
      }).finally(()=>{
          props.deactivateDim();
          props.hideAnyPopup();
      })
}

function getNewlyRegisteredCard(cardList, cardId) {
  return cardList.find((card) => card.id === cardId);
}
