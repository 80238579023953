import React, {Component} from 'react';

class DimDropdownToggle extends Component {
  render() {
    return (
        <button
            id={this.props.id}
            type={`button`}
            className={this.props.className}
            data-toggle={`dropdown`}
            onClick={this.props.onToggle}>
          <span>{this.props.alt}</span>{this.props.isCaret && <span className={`caret`}/>}
        </button>
    );
  }
}

export default DimDropdownToggle;
