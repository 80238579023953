// Refer to ErrorConstant.java in portal-server
import {
  PAYTYPE_STATUS_ALL,
  PAYTYPE_STATUS_CC,
  PAYTYPE_STATUS_CP,
  PAYTYPE_STATUS_ETC,
  PAYTYPE_STATUS_FT,
  PAYTYPE_STATUS_IWP,
  PAYTYPE_STATUS_PP,
  PAYTYPE_STATUS_SP, PAYTYPE_STATUS_TOSS
} from '../components/page/OrdersHistoryPage';
import {ERR_CODE_AUTH_CODE_EXPIRED, ERR_CODE_GENERAL, ERR_CODE_NOT_SAME_GUID_CNTR} from '../utils/errorConstant';

const translateNoSid = (sid) => {
  switch (sid) {
    case "NO_SID_403_404_ERROR":
      return "Unable to find the page you requested.";
    case "NO_SID_405_415_500_ERROR":
      return "Unable to complete temporarily. Please try again later.";
    default:
      return "Invalid SID error.";
  }
}

export const errorCode2Msg = (t, errorCode) => {
  switch (errorCode) {
    case "0410499":
      return t("TV_SID_BILLING_ACCOUNT_NOT_USED_CHECKOUT_DATA_PROTECTION");
    case ERR_CODE_GENERAL:
      return t("TV_SID_PAYMENT_MIX_UNABLE_REQUEST_COTACT", {errorCode: errorCode});
    case "140100":
    case "140102":
    case "140103":
      return t("TV_SID_AUTHENTICATION_CODE_INCORRECT_TRY");
    case ERR_CODE_AUTH_CODE_EXPIRED:
      return t("TV_SID_BILLING_AUTHENTICATION_CODE_EXPIRED_TRY");
    case "140104":
    case "140109":
    case "140204":
      return t("TV_SID_MIX_TEMPORARILY_UNABLE_COMPLETE_TRANSACTION", {errorCode: errorCode});
    case "140106":
      return t("TV_SID_MIX_CHECK_ZIP_CODE_AGAIN", {errorCode: errorCode});
    case ERR_CODE_NOT_SAME_GUID_CNTR:
      return t("TV_SID_PYMENT_WEBPORTAL_42704_STRING_45");
    case "COM_HTTP_STATUS_MESSAGE_CASE_403_404":
      return translateNoSid("NO_SID_403_404_ERROR");
    case "COM_HTTP_STATUS_MESSAGE_CASE_405_415_500":
      return translateNoSid("NO_SID_405_415_500_ERROR");
    default :
      return errorCode; // This means it is not errorCode. Not a bug, intended.
  }
};

export const payType2Msg = (t, payType) => {
  switch (payType) {
    case PAYTYPE_STATUS_ALL:
      return t("TV_SID_PYMENT_WEBPORTAL_42704_STRING_54")
    case PAYTYPE_STATUS_PP:
      return t("TV_SID_WEBSITE_PAYPAL");
    case PAYTYPE_STATUS_CC:
      return t("COM_SID_CREDIT_DEBIT_CARD");
    case PAYTYPE_STATUS_SP:
      return t("COM_TV_SID_BILLING_SAMSUNG_PAY");
    case PAYTYPE_STATUS_CP:
      return t("TV_SID_PYMENT_WEBPORTAL_42704_STRING_77");
    case PAYTYPE_STATUS_FT:
      return t("TV_SID_BILLING_FREE_TRIAL");
    case PAYTYPE_STATUS_ETC:
      return t("TV_SID_PYMENT_WEBPORTAL_42704_STRING_55");
    case PAYTYPE_STATUS_IWP :
      return "PayTM";
    default:
      return payType;
  }
};