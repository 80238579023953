import React, {Component} from 'react';
import {autobind} from 'core-decorators';

@autobind
class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRemainingInSeconds: 0,
      remainingMinutes: 0,
      remainingSeconds: 0
    };
  }

  updateRemainMinutesAndSeconds(timeRemainingInSeconds) {
    let remainingMinutes = Math.floor(timeRemainingInSeconds / 60);
    let remainingSeconds = timeRemainingInSeconds % 60;
    this.setState({
      remainingMinutes,
      remainingSeconds
    });
  }

  countDown(timeRemainingInSeconds) {
    this.setState({
      timeRemainingInSeconds: timeRemainingInSeconds
    });
    if (timeRemainingInSeconds % 60 === 0) {
      if (this.props.onEveryMinute) {
        this.props.onEveryMinute();
      }
    }
    if (timeRemainingInSeconds === 0) {
      if (this.props.onCompletion) {
        this.props.onCompletion();
      }
    }
    if (timeRemainingInSeconds >= 0) {
      this.updateRemainMinutesAndSeconds(timeRemainingInSeconds);
      timeRemainingInSeconds = timeRemainingInSeconds - 1;
      this.setTimeoutId = setTimeout(this.countDown.bind(this, timeRemainingInSeconds, false), 1000);
    }
  }

  stopTimer() {
    clearTimeout(this.setTimeoutId);
  }

  triggerTimer(prevProps) {
    if (this.props.refreshCounter !== prevProps.refreshCounter) {
      this.stopTimer();
      this.countDown(this.props.timeRemainingInSeconds);
    }
  }

  render() {
    let vMin = this.state.remainingMinutes > 9 ? this.state.remainingMinutes : '0' + this.state.remainingMinutes;
    let vSec = this.state.remainingSeconds > 9 ? this.state.remainingSeconds : '0' + this.state.remainingSeconds;
    return <span>{vMin}:{vSec}</span>;
  }

  componentDidMount() {
    this.triggerTimer(0);
  }

  componentDidUpdate(prevProps) {
    this.triggerTimer(prevProps);
  }

  componentWillUnmount() {
    this.stopTimer();
  }
}

export default Timer;
