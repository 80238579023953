import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class Legal extends Component {
  render() {
    return (
        <div className={`s-legal`}>
          <p>{this.props.t('TV_SID_PAYMENT_MIX_METHOD_LINKED_SAMSUNG_ACCOUNT', {account: this.props.ssoAccountId})}</p>
        </div>
    );
  }
}

export default withTranslation()(Legal);
