import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withPopupDispatch from './hoc/withPopupDispatch';
import {autobind} from 'core-decorators';

/**
 * Show Back button with Menu Name
 */
@autobind
class SubHeader extends Component {

  handleClick() {
    if (this.props.isConfirm) {
      this.props.showPopupConfirm(
          this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_25'),
          () => {
            this.props.hideAnyPopup();
            window.history.back();
          });
    } else {
      window.history.back();
    }
  }

  render() {
    return (
        <div className={`s-sub-header hidden-md hidden-lg`}>
          <a className={`s-btn-back`} onClick={this.handleClick}>
            <span>Back</span>
          </a>
          <h2 className={`s-sub-title`}>{this.props.text}</h2>
        </div>
    );
  }
}

export default withTranslation()(withPopupDispatch(SubHeader));
