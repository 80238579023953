/*
 * Create return url param with given value.
 */
import {MSG_SHOULD_NEVER_HAPPEN} from './errorConstant';

export const createReturnUrlParam = (value) => {
  if (!value) {
    throw new Error(MSG_SHOULD_NEVER_HAPPEN);
  }

  return "&returnUrl=" + encodeURIComponent(value);
}

export const getParams = (url) => {
  let params = {};
  let parser = document.createElement('a');
  parser.href = url;
  let query = parser.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  return params;
};

export const removeParam = (url, parameter) => {
  let fragment = url.split('#');
  let urlParts = fragment[0].split('?');

  if (urlParts.length < 2) {
    return url;
  }

  let urlBase = urlParts.shift(); //get first part, and remove from array
  let queryString = urlParts.join("?"); //join it back up

  let prefix = encodeURIComponent(parameter) + '=';
  let pars = queryString.split(/[&;]/g);
  let i = pars.length;
  for (i; i-- > 0;) { //reverse iteration as may be destructive
    if (pars[i].lastIndexOf(prefix, 0) !== -1) { //idiom for string.startsWith
      pars.splice(i, 1);
    }
  }

  let newUrl = urlBase + (pars.length > 0 ? '?' + pars.join('&') : '');
  if (fragment[1]) {
    newUrl += "#" + fragment[1];
  }

  return newUrl;
};
