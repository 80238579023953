import {getProperty} from './propertiesByDomain';
import {threatmetrix} from './fp-clientlib-v3';

function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const createJscSessionId = () => {
  const jscSessionId = createUUID();
  // eslint-disable-next-line
  threatmetrix.profile(getProperty("jscProfilingDomain"), getProperty("jscOrigId"), jscSessionId, '1');
  return jscSessionId;
}