import React, {Component} from 'react';
import SContainer from '../SContainer';
import WelcomeWrapper from '../WelcomeWrapper';
import AuthCodeWrapper from '../AuthCodeWrapper';
import Footer from '../Footer';
import withPopupComponents from '../hoc/withPopupComponents';
import PageContainer from '../PageContainer';

class SignInPage extends Component {
  render() {
    return (
        <PageContainer className={`s-wrap s-is-gnb`}>
          <SContainer login={true}>
            <WelcomeWrapper csrfToken={this.props.csrfToken}/>
            <AuthCodeWrapper/>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withPopupComponents(SignInPage);
