import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import Input from './Input';
import Button from './Button';

class AuthCode extends Component {
  render() {
    return (
        <Fragment>
          <div className={`form-group`}>
            <Input
                wrapperClassName={`s-input`}
                type={`tel`}
                name={`authCode`}
                min={1}
                max={999999}
                maxLength={6}
                value={this.props.inputValue}
                placeholder={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_3')}
                onChange={this.props.onInputChange}/>
          </div>
          <Button
              disabled={this.props.inputValue.length < 6}
              className={this.props.buttonClassName}
              onClick={this.props.onButtonClick}
              value={this.props.t('COM_MIS_BUTTON_CONTINUE_P')}/>
        </Fragment>
    );
  }
}

export default withTranslation()(AuthCode);
