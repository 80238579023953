import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Input from './Input';
import ErrorMsg from './ErrorMsg';
import {autobind} from 'core-decorators';

@autobind
class CardCvvInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarning: false
    };
  }

  setWarningOff(e) {
    e.preventDefault();
    this.toggleWarningStatus(false);
  }

  toggleWarningStatus(value) {
    this.setState({isWarning: value});
  }

  setIsValidToWarningStatus(e) {
    e.preventDefault();
    this.toggleWarningStatus(!this.props.isValid);
  }

  render() {
    return (
        <div className={`form-group`}>
          <h3 className={`s-form-title`}>
            {this.props.t('COM_SID_CVV_SECURITY_CODE')}
          </h3>
          <Input
              warning={this.state.isWarning}
              tabIndex={this.props.tabIndex}
              type={`tel`}
              name={this.props.name}
              min={1}
              max={9999}
              maxLength={4}
              value={this.props.value}
              placeholder={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_28')}
              onChange={this.props.onChange}
              onBlur={this.setIsValidToWarningStatus}
              onFocus={this.setWarningOff}/>
          <ErrorMsg
              warning={this.state.isWarning}
              message={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_37')}/>
        </div>
    );
  }
}

export default withTranslation()(CardCvvInput);
