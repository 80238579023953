import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {URL_AUTH_REGISTER_SSO, URL_INDEX_PAGE} from '../utils/urlConstant';
import Form from './Form';
import {BUYER_PORTAL_SERVICE_ID} from "../utils/globalConstants";
import {getProperty} from '../utils/propertiesByDomain';
import {autobind} from 'core-decorators';

@autobind
class WelcomeWrapper extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  submitForm(e) {
    e.preventDefault();
    this.formRef.current.submit();
  }

  render() {
    let actionUrl = getProperty("samsungAccountSignInGateUrl");
    let clientId = BUYER_PORTAL_SERVICE_ID;
    let redirectUrI = getProperty("urlHome") + URL_AUTH_REGISTER_SSO;
    let goBackURL = getProperty("urlHome") + URL_INDEX_PAGE;

    return (
        <Fragment>
          <Form method={`get`} action={actionUrl} ref={this.formRef}>
            <input type={`hidden`} name={`client_id`} value={clientId}/>
            <input type={`hidden`} name={`redirect_uri`} value={redirectUrI}/>
            <input type={`hidden`} name={`goBackURL`} value={goBackURL}/>
            <input type={`hidden`} name={`state`} value={this.props.csrfToken}/>
            <input type={`hidden`} name={`response_type`} value={`code`}/>
          </Form>
          <div className={`container-fluid s-sc-inner`}>
            <div className={`row welcome_wrapping`}>
              <div className={`w_img`}/>
              <div className={`welcome`}>
                <p className={`sign_info`}>
                  <strong>{this.props.t('COM_BDP_SID_INIT_SETTINGS_SMART_HUB_WELCOME_TITLE')}</strong>
                  <span>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_1')}</span>
                </p>
                <div className={`btn_wrapping`}>
                  <a className={`s-btn-default`} onClick={this.submitForm}>{this.props.t('TV_SID_SIGN_IN')}</a>
                  {/*<a className={`s-btn-default`} onClick={brandPayAddPaymentMethod}>{this.props.t('Add Payment Method')}</a>*/}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
    );
  }
}

export default withTranslation()(WelcomeWrapper);
