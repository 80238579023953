import React, {Component} from 'react';

class ErrorMsg extends Component {
  render() {
    let spanStyle = this.props.warning === true ? {display: "block"} : {display: "none"};

    return (
        <span className={`s_error_msg`} style={spanStyle}>{this.props.message}</span>
    );
  }
}

export default ErrorMsg;
