import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Input from './Input';
import ErrorMsg from './ErrorMsg';
import {autobind} from 'core-decorators';

@autobind
class ZipCodeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarning: false
    };
  }

  toggleWarningStatus(value) {
    this.setState({isWarning: value});
  }

  setWarningOff(e) {
    e.preventDefault();
    this.toggleWarningStatus(false);
  }

  setIsValidToWarningStatus(e) {
    e.preventDefault();
    this.toggleWarningStatus(!this.props.isValid);
  }

  handleChange(e) {
    e.preventDefault();
    this.props.onChange(e, this.toggleWarningStatus);
  }

  render() {
    let loadingSpanStyle = {display: "none"};

    if (this.props.loading) {
      loadingSpanStyle = {display: "block"};
    }

    return (
        <div className={`form-group`}>
          {!this.props.hideName &&
          <h3 className={`s-form-title`}>
            {this.props.t('COM_SID_ZIP_CODE')}
          </h3>
          }
          <Input
              warning={this.state.isWarning}
              tabIndex={this.props.tabIndex}
              type={`tel`}
              name={`zipCode`}
              min={1}
              max={99999}
              maxLength={5}
              value={this.props.value}
              placeholder={`#####`}
              onChange={this.handleChange}
              onBlur={this.setIsValidToWarningStatus}
              onFocus={this.setWarningOff}>
            <span className={`form_spinner`} style={loadingSpanStyle}>loading</span>
          </Input>
          <ErrorMsg
              warning={this.state.isWarning}
              message={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_36')}/>
        </div>
    );
  }
}

export default withTranslation()(ZipCodeInput);
