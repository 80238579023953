import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import SignInPage from './page/SignInPage';
import GccRegPage from './page/GccRegPage';
import serialize from 'serialize-javascript';
import ErrorPage from './page/ErrorPage';
import {
  URL_AUTH_SIGN_IN_PAGE,
  URL_BENEFITS_PAGE,
  URL_CASHON_PAY_PAGE,
  URL_CASHON_SUCCESS_PAGE,
  URL_CUSTOMERS_PAGE,
  URL_ORDERS_ORDER_DETAILS_PAGE,
  URL_ORDERS_PAGE,
  URL_PAYMETHODS_AUTHCODE_PAGE,
  URL_PAYMETHODS_CHANGE_STATE_PAGE,
  URL_PAYMETHODS_CHANGE_ZIP_CODE_PAGE,
  URL_PAYMETHODS_LIST_VAULT_CARD_PAGE,
  URL_PAYMETHODS_PAGE,
  URL_PAYMETHODS_REGISTER_GCC_PAGE,
  URL_PAYMETHODS_REGISTER_KCC_PAGE,
  URL_PAYMETHODS_REGISTER_METHOD_PAGE,
  URL_PAYMETHODS_SUBSCRIPTION_UPDATE_PAGE,
  URL_PAYMETHODS_SUCCESS_PAGE,
  URL_PAYON_CARD_REG_PAGE,
  URL_PAYON_LIST_VAULT_CARD_PAGE,
  URL_PAYON_RESULT_SUCCESS_PAGE,
  URL_PAYPALON_RESULT_SUCCESS_PAGE,
  URL_SIGNON_ADD_GCC_CARD_PAGE,
  URL_SIGNON_ADD_KCC_CARD_PAGE,
  URL_SIGNON_LIST_VAULT_CARD_PAGE,
  URL_SIGNON_RESULT_SUCCESS_PAGE,
  URL_SIGNON_WELCOME_WILL_YOU_ADD_CARD_PAGE,
  URL_SUPPORT_HELP_EMAIL_PAGE,
  URL_SUPPORT_SUBSCRIPTION_CANCEL_PAGE
} from '../utils/urlConstant';
import SuccessPage from './page/SuccessPage';
import SubscriptionCancelGuidePage from './page/SubscriptionCancelGuidePage';
import HelpEmailPage from './page/HelpEmailPage';
import PaymethodsPage from './page/PaymethodsPage';
import RegisterPaymethodSelectPage from './page/RegisterPaymethodSelectPage';
import {TYPE_PAYMENT, TYPE_PAYMETHODS, TYPE_PAYON, TYPE_PAYPALON, TYPE_SIGNON} from '../utils/globalConstants';
import GccAuthCodePage from './page/GccAuthCodePage';
import KccRegPage from './page/KccRegPage';
import OrdersHistoryPage from './page/OrdersHistoryPage';
import OrderDetailsPage from './page/OrderDetailsPage';
import CustomerPage from './page/CustomerPage';
import BenefitAddPage from './page/BenefitAddPage';
import ZipCodeChangePage from "./page/ZipCodeChangePage";
import {getParams} from '../utils/queryUtil';
import ChangeSubscriptionPaymethodPage from './page/ChangeSubscriptionPaymethodPage';
import MobilePayPage from './page/MobilePayPage';
import StateChangePage from './page/StateChangePage';
import ListVaultCardsPage from './page/ListVaultCardsPage';
import WillYouAddCardPage from "./page/WillYouAddCardPage";

class App extends Component {
  render() {
    const requestUrl = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
    const serverData = JSON.parse(serialize(window.SERVER_DATA, {isJSON: true}));

    // server variables --

    const status = serverData.REACT_APP_httpStatus;
    const code = serverData.REACT_APP_errorCode;
    const authenticated = serverData.REACT_APP_isSession === true; // Always exist when authenticated
    const ssoAccountId = serverData.REACT_APP_ssoAccountId; // Always exist when authenticated
    const popup = serverData.REACT_APP_doPopUp === true;
    const userCountry = serverData.REACT_APP_userCountry; // Always exist when authenticated
    const authCode = serverData.REACT_APP_authCode;
    const authCodeExpireTime = Number(serverData.REACT_APP_expires);
    const cardType = serverData.REACT_APP_cardType;
    const lastCardNum = serverData.REACT_APP_lastCardNum;
    const isOrdered = serverData.REACT_APP_isOrdered === true; // Always exist when authenticated
    const buyerEmailAddr = serverData.REACT_APP_buyerEmailAddr; // Always exist when authenticated
    const addablePaymethods = serverData.REACT_APP_addablePaymethods;
    const paymethod = serverData.REACT_APP_paymethod;
    const csrfToken = serverData.REACT_APP_csrfToken;
    const logout = serverData.REACT_APP_doLogout === true;
    const cardId = serverData.REACT_APP_cardId;
    const ddcJwt = serverData.REACT_APP_ddcJwt;
    const ddcBin = serverData.REACT_APP_bin;
    const isSca = serverData.REACT_APP_isScaProcess === true;
    const paymentCurrencySymbol = serverData.REACT_APP_paymentCurrencySymbol;
    const paymentTotalAmount = serverData.REACT_APP_totalAmount;
    const paymentTotalAmountAdditionalText = serverData.REACT_APP_totalAmountAdditionalText;
    const paymentProductName = serverData.REACT_APP_productName;
    const isUserCountryEu = serverData.REACT_APP_isUserCountryEu;
    const isProductSubscription = serverData.REACT_APP_isProductSubscription;
    const paypalId = serverData.REACT_APP_paypalId;
    const isCountryAdditionalTaxState = serverData.REACT_APP_isCountryAdditionalTaxState; // Always exist when authenticated

    // query string or path variables --

    const subscriptionId = requestUrl.match(/subscriptions\/([a-zA-Z0-9]+)\//g) !== null ? RegExp.$1 : null;
    const returnUrl = getParams(window.location.search).returnUrl;
    const isSignon = requestUrl.match(/portal\/signon\//g) !== null || (getParams(window.location.search).menu && Object.is(getParams(window.location.search).menu, "SIGNON"));

    // show error page if code exist
    if (code) {
      return <ErrorPage status={status}
                        code={code}
                        authenticated={authenticated}
                        ssoAccountId={ssoAccountId}
                        popup={popup}
                        logout={logout}
                        isSignon={isSignon}/>;
    }

    // or else
    return (
        <Switch>
          {/* sign-in pages */}
          <Route exact path={URL_AUTH_SIGN_IN_PAGE}
                 component={() =>
                     <SignInPage
                         authenticated={authenticated}
                         csrfToken={csrfToken}/>}/>
          {/* payon pages */}
          <Route exact path={URL_PAYON_CARD_REG_PAGE}
                 component={() =>
                     <GccRegPage
                         type={TYPE_PAYON}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}
                         authCode={authCode}
                         authCodeExpireTime={authCodeExpireTime}
                         ddcJwt={ddcJwt}
                         isSca={isSca}
                         isCountryAdditionalTaxState={isCountryAdditionalTaxState}/>}/>
          <Route exact path={URL_PAYON_LIST_VAULT_CARD_PAGE}
                 component={() =>
                     <ListVaultCardsPage
                         type={TYPE_PAYON}
                         userCountry={userCountry}/>}/>
          <Route exact path={URL_PAYON_RESULT_SUCCESS_PAGE}
                 component={() =>
                     <SuccessPage
                         type={TYPE_PAYON}
                         cardType={cardType}
                         lastCardNum={lastCardNum}
                         paymethod={paymethod}/>}/>
          {/* cashon pages */}
          <Route exact path={URL_CASHON_PAY_PAGE}
                 component={() =>
                     <MobilePayPage
                         authCode={authCode}
                         cardType={cardType}
                         lastCardNum={lastCardNum}
                         ddcJwt={ddcJwt}
                         ddcBin={ddcBin}
                         isSca={isSca}
                         paymentCurrencySymbol={paymentCurrencySymbol}
                         paymentTotalAmount={paymentTotalAmount}
                         paymentTotalAmountAdditionalText={paymentTotalAmountAdditionalText}
                         paymentProductName={paymentProductName}
                         isUserCountryEu={isUserCountryEu}
                         isProductSubscription={isProductSubscription}/>}/>
          <Route exact path={URL_CASHON_SUCCESS_PAGE}
                 component={() => <SuccessPage type={TYPE_PAYMENT}/>}/>
          {/* sign-on pages */}
          <Route exact path={URL_SIGNON_ADD_GCC_CARD_PAGE}
                 component={() =>
                     <GccRegPage
                         type={TYPE_SIGNON}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}
                         ddcJwt={ddcJwt}
                         isSca={isSca}
                         isCountryAdditionalTaxState={isCountryAdditionalTaxState}/>}/>
          <Route exact path={URL_SIGNON_LIST_VAULT_CARD_PAGE}
                 component={() =>
                     <ListVaultCardsPage
                         type={TYPE_SIGNON}
                         userCountry={userCountry}/>}/>
          <Route exact path={URL_SIGNON_ADD_KCC_CARD_PAGE}
                 component={() => <KccRegPage isSignon={true}/>}/>
          <Route exact path={URL_SIGNON_RESULT_SUCCESS_PAGE}
                 component={() =>
                     <SuccessPage
                         type={TYPE_SIGNON}
                         cardType={cardType}
                         lastCardNum={lastCardNum}
                         paymethod={paymethod}/>}/>
          <Route exact path={URL_SIGNON_WELCOME_WILL_YOU_ADD_CARD_PAGE}
                 component={() =>
                     <WillYouAddCardPage
                         userCountry={userCountry}/>}/>
          {/* paypal-on pages */}
          <Route exact path={URL_PAYPALON_RESULT_SUCCESS_PAGE}
                 component={() =>
                     <SuccessPage
                         type={TYPE_PAYPALON}
                         paypalId={paypalId}/>}/>
          {/* support pages */}
          <Route exact path={URL_SUPPORT_HELP_EMAIL_PAGE}
                 component={() =>
                     <HelpEmailPage
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         dimAllNavMenusButBenefit={!isOrdered}
                         buyerEmailAddr={buyerEmailAddr}/>}/>
          <Route exact path={URL_SUPPORT_SUBSCRIPTION_CANCEL_PAGE}
                 component={() => <SubscriptionCancelGuidePage/>}/>
          {/* paymethods pages */}
          <Route exact path={URL_PAYMETHODS_PAGE}
                 component={() =>
                     <PaymethodsPage
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}
                         isCountryAdditionalTaxState={isCountryAdditionalTaxState}/>}/>
          <Route exact path={URL_PAYMETHODS_REGISTER_METHOD_PAGE}
                 component={() =>
                     <RegisterPaymethodSelectPage
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         addablePaymethods={addablePaymethods}/>}/>
          <Route exact path={URL_PAYMETHODS_LIST_VAULT_CARD_PAGE}
                 component={() =>
                     <ListVaultCardsPage
                         url={URL_PAYMETHODS_PAGE}
                         type={TYPE_PAYMETHODS}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}/>}/>
          <Route exact path={URL_PAYMETHODS_REGISTER_GCC_PAGE}
                 component={() =>
                     <GccRegPage
                         type={TYPE_PAYMETHODS}
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}
                         authCode={authCode}
                         authCodeExpireTime={authCodeExpireTime}
                         ddcJwt={ddcJwt}
                         isSca={isSca}
                         returnUrl={returnUrl}
                         isCountryAdditionalTaxState={isCountryAdditionalTaxState}/>}/>
          <Route exact path={URL_PAYMETHODS_SUCCESS_PAGE}
                 component={() =>
                     <SuccessPage
                         type={TYPE_PAYMETHODS}
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         authCode={authCode}
                         cardType={cardType}
                         lastCardNum={lastCardNum}
                         paymethod={paymethod}
                         returnUrl={returnUrl}/>}/>
          <Route exact path={URL_PAYMETHODS_AUTHCODE_PAGE}
                 component={() =>
                     <GccAuthCodePage
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}/>}/>
          <Route exact path={URL_PAYMETHODS_REGISTER_KCC_PAGE}
                 component={() =>
                     <KccRegPage
                         isSignon={false}
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}/>}/>
          <Route exact path={URL_PAYMETHODS_SUBSCRIPTION_UPDATE_PAGE}
                 component={() =>
                     <ChangeSubscriptionPaymethodPage
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         subscriptionId={subscriptionId}
                         returnUrl={returnUrl}/>}/>
          <Route exact path={URL_PAYMETHODS_CHANGE_ZIP_CODE_PAGE}
                 component={() =>
                     <ZipCodeChangePage
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}
                         cardId={cardId}/>}/>
          <Route exact path={URL_PAYMETHODS_CHANGE_STATE_PAGE}
                 component={() =>
                     <StateChangePage
                         url={URL_PAYMETHODS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         userCountry={userCountry}
                         isCountryAdditionalTaxState={isCountryAdditionalTaxState}
                         cardId={cardId}/>}/>
          {/* orders pages */}
          <Route exact path={URL_ORDERS_PAGE}
                 component={() =>
                     <OrdersHistoryPage
                         url={URL_ORDERS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}/>}/>
          <Route exact path={URL_ORDERS_ORDER_DETAILS_PAGE}
                 component={() =>
                     <OrderDetailsPage
                         url={URL_ORDERS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}/>}/>
          {/* benefit pages */}
          <Route exact path={URL_BENEFITS_PAGE}
                 component={() =>
                     <BenefitAddPage
                         url={URL_BENEFITS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}
                         isOrdered={isOrdered}/>}/>
          {/* customer pages */}
          <Route exact path={URL_CUSTOMERS_PAGE}
                 component={() =>
                     <CustomerPage
                         url={URL_CUSTOMERS_PAGE}
                         authenticated={authenticated}
                         ssoAccountId={ssoAccountId}/>}/>
        </Switch>
    );
  }
}

export default App;
