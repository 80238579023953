import React, {Component} from 'react';
import {getProperty} from '../utils/propertiesByDomain';
import {ERR_CODE_GENERAL} from '../utils/errorConstant';

class ScaIframeFormImpl extends Component {
  constructor(props) {
    super(props);

    this.scaIframeRef = React.createRef();
  }

  render() {
    return (
        <iframe ref={this.scaIframeRef} name="sca" className={`s-sca-iframe`} title="sca"/>
    );
  }

  loadEventHandler = () => {
    try {
      const innerText = this.scaIframeRef.current.contentWindow.document.body.innerText; // error if not same origin
      const responseJson = JSON.parse(innerText);
      if (!responseJson || !responseJson.status) {
        return {status: ERR_CODE_GENERAL}; // Will this happen ???
      }

      if (this.props.onComplete) {
        this.props.onComplete(responseJson);
      }
    } catch (e) {
      // Error for same origin.
      // Note that buyer portal can't do anything when Error occur while Sca processing.
      // It came to an agreement with Billing PM that we won't deal with this Error.
    }
  }

  componentDidMount() {
    this.scaIframeRef.current.addEventListener('load', this.loadEventHandler);
  }

  componentWillUnmount() {
    this.scaIframeRef.current.removeEventListener('load', this.loadEventHandler);
  }
}

/**
 * Show Iframe to process 3DS Challenge
 *
 * It takes below properties :
 *  - parentRef : for parent to submit the iframe form
 *  - scaJwt : iframe form parameter
 *  - show : to show or hide iframe
 *  - onComplete : json callback function after iframe submit success
 *
 * [Note1] Our iframe size is always 390x400.
 * [Note2] This has no error handling if iframe submit fails.
 *
 * See "Request Challenge Page" in https://developer.worldpay.com/docs/wpg/directintegration/3ds2 for more details.
 */
class ScaIframeForm extends Component {
  render() {
    if (!this.props.show) {
      return <div/>;
    }

    return (
        <div className={`s-sca-popup-outer`}>
          <div className={`s-sca-popup-inner`}
              // Even though 390x400 is right, we extend WxH for safety
               style={{width: "440px", height: "450px"}}>
            <div className={`s-sca-popup-inner-title-bar`}><span className={`s-sca-popup-inner-title-bar-close`} onClick={this.props.onClick}>&times;</span></div>
            <ScaIframeFormImpl onComplete={this.props.onComplete}/>
            <form ref={this.props.parentRef}
                  action={getProperty("ddcHost") + getProperty("scaUri")}
                  method="post"
                  target="sca">
              <input name="JWT" type="hidden" value={this.props.scaJwt}/>
            </form>
          </div>
        </div>
    );
  }
}

export default ScaIframeForm;