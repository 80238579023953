import React, {Component} from 'react';

class Util extends Component {
  render() {
    return (
        <div className={`s-util`}>
          {this.props.children}
        </div>
    );
  }
}

export default Util;
